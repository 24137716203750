import { Grid } from '@mui/material';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { withStyles } from '@mui/styles';
import styles from './styles';

const DragDropFile = ({
  classes,
  variant = 1,
  inputRef,
  handleFiles,
  children,
  flag = 'normal',
  setFlag,
  formats = [],
}) => {
  const [dragging, setDragging] = useState(false);

  const handleDrag = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!dragging) {
      setFlag('neutral');
      setDragging(true);
    }
  };

  const handleDragLeave = event => {
    event.preventDefault();
    event.stopPropagation();
    if (dragging) {
      setFlag('normal');
      setDragging(false);
    }
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    if (!isEmpty(formats)) {
      const files = [...event.dataTransfer.files];
      if (!files.some(file => formats.includes(file.type))) {
        changeFlag('reject', 300);
        return;
      }
    }

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      changeFlag('approve', 300);
      handleFiles(event.dataTransfer.files);
    }
  };

  const handleChange = event => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      handleFiles(event.target.files);
    }
  };

  const changeFlag = (type, timeout) => {
    setFlag(type);
    setTimeout(() => setFlag('normal'), timeout);
  };

  return (
    <Grid
      id='form-file-upload'
      className={`${classes.root}_variant${variant} ${classes.root}_variant${variant}-${flag}`}
      onDragEnter={handleDrag}
      onSubmit={event => event.preventDefault()}
      container
    >
      <input id='input-file-upload' ref={inputRef} type='file' multiple={true} onChange={handleChange} hidden />
      {children}
      {dragging && (
        <Grid
          id='drag-file-element'
          className={`${classes.root}_drag`}
          onDragOver={handleDrag}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          container
        />
      )}
    </Grid>
  );
};

export default withStyles(styles)(DragDropFile);
