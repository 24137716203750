/**
 *
 * Auth saga file
 * Any action made for an authentication should go here
 *
 */
import { call, put, takeEvery, all } from "redux-saga/effects";
import { authConstants } from "../constants/auth-constants";
import { commonConstants } from '../constants/common-constants';
import { AuthService } from "../../services/auth.service";
import { notify } from "../../components/toast-message";
import UserService from "../../services/user.service";
import { authActions } from "../actions/auth-actions";
import Storage from "../../utils/storage";
/**
 * Workers
 */
export function* resetPassword(action) {
  try {
    const result = yield call(AuthService.resetPassword, action.payload);
    if (!!result && result.status === 200) {
      notify('Next step was sent, please check your email', 'success');
      yield put({
        type: authConstants.RESET_PASSWORD_SUCCESS,
        payload: result,
      });
    } else {
      notify('Something was wrong, please try again later', 'error');
      yield put({ type: authConstants.RESET_PASSWORD_FAILURE });
    }
  } catch (error) {
    notify('Something was wrong, please try again later', 'error');
    yield put({ type: authConstants.RESET_PASSWORD_FAILURE });
  }
}
export function* loginUser(action) {
  try {
    const result = yield call(AuthService.login, action.payload);
    if (!!result.status && result.status > 201) {
      yield put({ type: authConstants.LOGIN_FAILURE });
    } else {
      yield put({ type: authConstants.LOGIN_SUCCESS, payload: result });
    }
  } catch (error) {
    yield put({ type: authConstants.LOGIN_FAILURE });
  }
}
export function* logoutUser(action) {
  yield put({ type: commonConstants.RESET });
  yield call(AuthService.logout);
}
export function* setNewPassword(action) {
  try {
    const result = yield call(AuthService.setNewPassword, action.payload);
    if (!!result && result.status === 200) {
      notify('Password updated', 'success');
      yield put({
        type: authConstants.SET_NEW_PASSWORD_SUCCESS,
        payload: result,
      });
    } else {
      notify('Something was wrong, please try again later', 'error');
      yield put({ type: authConstants.SET_NEW_PASSWORD_FAILURE });
    }
  } catch (error) {
    notify('Something was wrong, please try again later', 'error');
    yield put({ type: authConstants.SET_NEW_PASSWORD_FAILURE });
  }
}

export function* changeUserLocale ({ payload }) {
    const resp = yield call(UserService.updateUser, payload);

    if (!!resp && resp.status === 200) {
      Storage.setData('local_lang', payload.user.settings_locale);
      window.location.reload();
    } else {
      yield put(authActions.changeLocaleFail());
    }
  };

/**
 * Watchers
 */
export function* setPasswordRequest() {
  yield takeEvery(authConstants.SET_NEW_PASSWORD, setNewPassword);
}

export function* resetPasswordRequest() {
  yield takeEvery(authConstants.RESET_PASSWORD_REQUEST, resetPassword);
}

export function* loginUserRequest() {
  yield takeEvery(authConstants.LOGIN_REQUEST, loginUser);
}

export function* logoutUserRequest() {
  yield takeEvery(authConstants.LOGOUT, logoutUser);
}

export function* changeUserLocaleRequest() {
  yield takeEvery(authConstants.USER_LOCALE_CHANGE_REQUEST, changeUserLocale);
}

/**
 * Export function
 */
function* authSaga() {
  // Watchers request
  yield all([loginUserRequest(), logoutUserRequest(), resetPasswordRequest(), setPasswordRequest(), changeUserLocaleRequest()]);
}
export default authSaga;
