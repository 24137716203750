import { commonConstants } from '../constants/common-constants';
import { orgGroupConstants } from '../constants/org-group-constants';

// The initial state for orgGroups
const orgGroupsInitialState = {
  orgGroups: {},
  orgGroupTypes: [],
  loading: false,
  fail: false
};

export default function orgGroups(state = orgGroupsInitialState, action) {
  let newState;
  switch (action.type) {
    case orgGroupConstants.GET_ORG_TYPES:
      newState = {
        ...state,
        loading: true,
        fail: false
      };
      return newState;
    case orgGroupConstants.GET_ORG_TYPES_SUCCESS:
      newState = {
        ...state,
        loading: false,
        orgGroupTypes: action.payload.data
      };
      return newState;
    case orgGroupConstants.GET_ORG_TYPES_FAIL:
        newState = {
          ...orgGroupsInitialState,
          fail: true
        };
        return newState;
    case orgGroupConstants.GET_ORG_GROUP:
      newState = {
        ...state,
        loading: true,
        fail: false
      };
      return newState;
    case orgGroupConstants.GET_ORG_GROUP_SUCCESS:
      const newGroup = {};
      newGroup[action.payload.id] = action.payload.data;
      newState = {
        ...state,
        loading: false,
        orgGroups: {...state.orgGroups, ...newGroup}
      };
      return newState;
    case orgGroupConstants.GET_ORG_GROUP_FAIL:
        newState = {
          ...state,
          loading: false,
          fail: true
        };
        return newState;
    case commonConstants.RESET:
      return orgGroupsInitialState;
    default:
      return state;
  }
}
