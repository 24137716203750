import API from './api.service';

export class ContractModificationService {
  static async createContractModification(data = {}) {
    try {
      const response = await API(false, false).post(`contracts/${data.contractId}/contract_modifications`, {
        ...data,
      });
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getContractModifications(data = {}) {
    try {
      const response = await API(false, false).get(`contracts/${data.contractId}/contract_modifications`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getContractModificationJustifications(data = {}) {
    try {
      const response = await API(false, false).get(`clients/${data.clientId}/contract_modification_justifications`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }
}

export default ContractModificationService;
