import { alpha } from '@mui/material/styles';

const styles = theme => ({
  /* dropdown: {
    '&_autocomplete': {
      '& .MuiAutocomplete-endAdornment': {
        marginTop: 3,
      },
    },
  }, */
  simpleText: {
    color: theme.palette.gray.dark,
  },
  story: {
    '&_container': {
      backgroundColor: `${theme.palette.gray.main} !important`,
      color: `${theme.palette.gray.dark} !important`,
    },
    '&_group': {
      maxHeight: 570,
      overflowY: 'scroll',
    },
    '&_avatar': {
      '& .MuiAvatar-root': {
        height: 24,
        width: 24,
        fontSize: 10,
        marginRight: 7,
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
    },
  },
  card: {
    minHeight: 165,
    padding: 20,
    '&_input': {
      padding: 10,
      margin: '10px 0px 10px 0px',
      minHeight: 58,
      width: '100%',
      '& .MuiInputLabel-asterisk': {
        color: theme.palette.statusLevel.CANCEL,
      },
      '& .MuiOutlinedInput-input::-webkit-outer-spin-button, & .MuiOutlinedInput-input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        MozAppearance: 'textfield',
        margin: 0,
      },
    },
    '&_story': {
      minHeight: 48,
      width: '100%',
      padding: 0,
      '&-control': {
        padding: 20,
        '&:hover': {
          background: alpha(theme.palette.primary.background, 0.1),
          cursor: 'pointer',
        },
      },
      '& .MuiGrid-item': {
        fontWeight: 400,
        fontSize: 16,
        color: theme.palette.secondary.main,
      },
    },
    '&_contract': {
      height: 465,
      width: 390,
      padding: 20,
      '&_object-text': {
        minHeight: '0px !important',
        padding: 0,
        height: 80,
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
      '& .MuiGrid-container': {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.gray.dark,
      },
    },
    '&_spent-days': {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      fontSize: 20,
      '&_container': {
        position: 'absolute',
        top: 40,
      },
      '&_amount': {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.gray.dark,
        border: ` 2px solid ${theme.palette.gray.dark}`,
        padding: '0px 5px',
        borderRadius: 10,
      },
    },
    '&_separator': {
      height: '4px !important',
      width: '65px !important',
      margin: '5px 0px  5px',
      '& .MuiLinearProgress-bar': {
        backgroundColor: `${alpha(theme.palette.secondary.main, 0.6)} !important`,
      },
    },
    '&_text': {
      color: theme.palette.gray.dark,
      fontWeight: 400,
      fontSize: 14,
      marginTop: 10,
    },
    '&_linear-progress': {
      borderRadius: '0px !important',
      height: '4px !important',
      marginTop: 10,
    },
    '&_amount': {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      fontSize: 20,
      marginTop: 10,
    },
    '&_container': {
      alignItems: 'center',
    },
    '&_list': {
      '&-item': {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.gray.dark,
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        paddingTop: '0px !important',
        '&_last': {
          '& .MuiListItemIcon-root': {
            color: alpha(theme.palette.gray.dark, 0.5),
          },
        },
        '& .MuiListItemIcon-root': {
          minWidth: 20,
          fontSize: 8,
          color: theme.palette.secondary.main,
        },
      },
      '&-title': {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 1,
        color: theme.palette.primary.dark,
        paddingTop: 5,
      },
    },
    '&_big-numbers': {
      marginTop: 40,
      fontWeight: 500,
      fontSize: 50,
      lineHeight: 1,
      color: theme.palette.secondary.main,
      '&_container': {
        padding: '20px',
      },
    },
    '&_big-days': {
      fontWeight: 500,
      fontSize: 20,
      color: theme.palette.gray.dark,
      marginTop: '35px !important',
    },
  },
  circularProgressBG: {
    transform: 'rotate(90deg) !important',
    color: `${alpha(theme.palette.gray.dark, 0.2)} !important`,
  },
  circularProgressFill: {
    transform: 'rotate(90deg) !important',
    position: 'absolute',
    zIndex: 1,
  },
  header: {
    padding: '15px 0px 15px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&_title': {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1,
      color: theme.palette.primary.dark,
    },
  },
  button: {
    lineHeight: '24px !important',
    margin: '0px 5px !important',
    fontWeight: 500,
    fontSize: 14,
    textTransform: 'uppercase !important',
    '&_link': {
      border: ` 1px solid ${theme.palette.primary.main}`,
      padding: '10px 15px',
      borderRadius: 4,
    },
  },
  table: {
    marginTop: 20,
    '& .MuiTableCell-root': {
      minWidth: 230,
      fontWeight: 400,
      fontSize: 14,
      color: theme.palette.gray.dark,
      borderBottom: 'none',
      padding: 0,
    },
  },
  divider: {
    width: '100%',
    borderColor: `${theme.palette.secondary.light} !important`,
    '&_modal': {
      width: '100%',
      borderColor: `${theme.palette.secondary.light} !important`,
      margin: '10px 0px 10px 0px !important',
    },
  },
  modal: {
    '&_container': {
      display: 'flex',
      padding: 15,
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&_body': {
      width: '500px !important',
      maxHeight: '94vh !important',
      backgroundColor: theme.palette.background.paper,
      padding: '20px 40px 20px',
      overflowY: 'auto',
      color: theme.palette.gray.dark,
      position: 'relative',
      '&_button': {
        '&_container': {
          backgroundColor: theme.palette.background.paper,
          width: '80% !important',
          padding: '10px 0 17px 0',
        },
      },
      '&_part': {
        width: '100%',
        marginTop: '5px !important',
        display: 'flex',
        flexDirection: 'column !important',
        overflow: 'hidden',
        '&_left': {
          overflowY: 'auto',
          height: '95%',
        },
        '&_title': {
          fontWeight: 700,
          fontSize: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
          width: '433px !important',
          height: 40,
        },

        '&_sub-title': {
          fontWeight: 700,
          fontSize: 16,
          margin: '10px 0px',
        },
      },
    },
    '&_input': {
      '&_radio': {
        marginLeft: 15,
        '& .MuiRadio-root': {
          padding: '0px !important',
          margin: 5,
        },
      },
      '&_container': {
        margin: '10px 0px',
        '& .MuiOutlinedInput-input::-webkit-outer-spin-button, & .MuiOutlinedInput-input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          MozAppearance: 'textfield',
          margin: 0,
        },
        '& .MuiInputAdornment-root': {
          marginRight: 5,
          '& .MuiSvgIcon-root': {
            color: theme.palette.gray.dark,
          },
        },
        '& .MuiGrid-root, & .MuiFormControl-root, & .MuiAutocomplete-root': {
          width: '100%',
          '& .MuiInputLabel-asterisk': {
            color: theme.palette.statusLevel.CANCEL,
          },
        },
        '& .MuiChip-deleteIcon, & .MuiChip-label': {
          color: `${alpha(theme.palette.gray.dark, 0.9)} !important`,
        },
        '& .MuiButtonBase-root': {
          height: 28,
        },
      },
    },
  },
  object: {
    height: 80,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '5',
    WebkitBoxOrient: 'vertical',
  },
});

export default styles;
