const styles = theme => ({
  root: {
    flexDirection: 'column !important',
    padding: 10,
    gap: 10,
    fontSize: 14,
    minWidth: 400,

    '&_head': {
      minWidth: 600,
      rowGap: 10,

      '&-link': {
        flexBasis: '100%',
        textDecorationLine: 'underline',
        color: theme.palette.primary.main,
      },

      '&-name': {
        flexBasis: '100%',
        fontSize: 20,
        textTransform: 'uppercase',
        color: theme.palette.primary.dark,
      },
    },

    '&_body': {
      display: 'flex !important',
      flexWrap: 'wrap !important',
      gap: 10,
      minWidth: 600,

      '&_details': {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'calc(40% - 10px)',
        minWidth: 200,
        marginBottom: '35px !important',

        '&-state': {
          display: 'flex',
          flexDirection: 'column !important',
          padding: 10,
          justifyContent: 'space-between',
          marginBottom: 10,
          minHeight: 'calc(40% - 20px)',
        },

        '&_values': {
          padding: 10,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          rowGap: 5,
          borderRadius: 4,
          backgroundColor: theme.palette.secondary.background,
          minHeight: '60%',
          height: 425,
          overflowY: 'auto',

          '&-title': {
            fontWeight: 'bolder',
            fontSize: 20,
          },

          '&-name': {},

          '&-value': {
            fontWeight: 'bold',
          },

          '&-button': {
            marginTop: '10px !important',
            width: '100%',
            backgroundColor: `${theme.palette.primary.dark} !important`,
          },
        },
      },

      '&_media': {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'calc(60% - 20px)',
        minWidth: '600px',

        display: 'flex',
        flexWrap: 'wrap',
        gap: 10,

        '&_map': {
          width: '100%',
          height: 'calc(70% - 20px)',
        },

        '&-img': {
          width: '100%'
        },
      },
    },

    '&_google-maps': {
      display: 'grid !important',
    },
  },

  card: {
    '&-status': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 150,
      minHeight: 25,
      borderRadius: 40,
      color: theme.palette.primary.contrastText,
      fontSize: 14,
    },
  },

  progressbar: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    fontSize: 14,

    '&__title': {
      width: '100%',
      fontWeight: 'bold',
    },

    '&__linear': {
      width: '88%',
    },

    '&-value': {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '12%',
    },

    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.gray.main,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.secondary.main,
    },
  },

  amount: {
    '&__title': {
      fontWeight: 'bold',
    },

    '&-value': {
      fontWeight: 'bold',
      fontSize: 16,
    },
  },
});

export default styles;
