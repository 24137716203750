/**
 *
 * Auth saga file
 * Any action made for an projects should go here
 *
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import ContractModificationService from '../../services/contract.modification.services';
import { contractModificationConstants } from '../constants/contract-modification-constants';

/**
 * Workers
 */

export function* createContractModification(action) {
  const result = yield call(ContractModificationService.createContractModification, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractModificationConstants.CREATE_CONTRACT_MODIFICATION_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractModificationConstants.CREATE_CONTRACT_MODIFICATION_FAIL,
    });
  }
}

export function* getContractModifications(action) {
  const result = yield call(ContractModificationService.getContractModifications, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractModificationConstants.GET_CONTRACT_MODIFICATIONS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractModificationConstants.GET_CONTRACT_MODIFICATIONS_FAIL,
    });
  }
}

export function* getContractModificationJustifications(action) {
  const result = yield call(ContractModificationService.getContractModificationJustifications, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractModificationConstants.GET_CONTRACT_MODIFICATION_JUSTIFICATIONS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractModificationConstants.GET_CONTRACT_MODIFICATION_JUSTIFICATIONS_FAIL,
    });
  }
}

/**
 * Watchers
 */

export function* createContractModificationRequest() {
  yield takeEvery(contractModificationConstants.CREATE_CONTRACT_MODIFICATION, createContractModification);
}

export function* getContractModificationsRequest() {
  yield takeEvery(contractModificationConstants.GET_CONTRACT_MODIFICATIONS, getContractModifications);
}

export function* getContractModificationJustificationsRequest() {
  yield takeEvery(contractModificationConstants.GET_CONTRACT_MODIFICATION_JUSTIFICATIONS, getContractModificationJustifications);
}

/**
 * Export function
 */
function* contractModificationSaga() {
  // Watchers request
  yield all([
    createContractModificationRequest(),
    getContractModificationsRequest(),
    getContractModificationJustificationsRequest()
  ]);
}

export default contractModificationSaga;
