import { set } from 'lodash';
import { commonConstants } from '../constants/common-constants';
import { contractModificationConstants } from '../constants/contract-modification-constants';

const contractModificationInitialState = {
  contractModification: {},
  contractModifications: [],
  justifications: [],
  loading: false,
  fail: false,
};

export default function contractModifications(state = contractModificationInitialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case contractModificationConstants.CREATE_CONTRACT_MODIFICATION:
    case contractModificationConstants.GET_CONTRACT_MODIFICATIONS:
      set(newState, 'loading', true);
      return newState;

    case contractModificationConstants.CREATE_CONTRACT_MODIFICATION_FAIL:
    case contractModificationConstants.GET_CONTRACT_MODIFICATIONS_FAIL:
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;

    case contractModificationConstants.CREATE_CONTRACT_MODIFICATION_SUCCESS:
      set(newState, 'contractModification', action.payload.data);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    case contractModificationConstants.GET_CONTRACT_MODIFICATIONS_SUCCESS:
      set(newState, 'contractModifications', action.payload.data);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    // Justifications

    case contractModificationConstants.GET_CONTRACT_MODIFICATION_JUSTIFICATIONS:
      set(newState, 'loading', true);
      return newState;

    case contractModificationConstants.GET_CONTRACT_MODIFICATION_JUSTIFICATIONS_FAIL:
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;

    case contractModificationConstants.GET_CONTRACT_MODIFICATION_JUSTIFICATIONS_SUCCESS:
      set(newState, 'justifications', action.payload.data);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    case commonConstants.RESET:
      return contractModificationInitialState;
    default:
      return state;
  }
}
