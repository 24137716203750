import { taskConstants } from '../constants/task-constants';
import { set } from 'lodash';
import { commonConstants } from '../constants/common-constants';

const taskInitialState = {
  task: {},
  taskList: [],
  parentTaskList: [],
  taskLevels: [],
  taskLog: {},
  taskLogList: [],
  productStatusLogList: [],
  loading: false,
  fail: false,
  importanceFactorsUpdated: false,
  projectTaskLevel: {},
};

export default function tasks(state = taskInitialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case taskConstants.SET_NEW_PROJECT_TASK:
    case taskConstants.GET_PROJECT_TASK_LIST:
    case taskConstants.GET_PROJECT_PARENT_TASK_LIST:
    case taskConstants.GET_PROJECT_TASK_LEVELS:
    case taskConstants.UPDATE_PROJECT_TASK:
    case taskConstants.UPDATE_PROJECT_TASK_STATUS:
    case taskConstants.SET_NEW_TASK_LOG:
    case taskConstants.GET_TASK_LOGS:
    case taskConstants.GET_PRODUCT_STATUS_LOGS:
    case taskConstants.SET_PRODUCT_STATUS_LOG:
    case taskConstants.DOWNLOAD_TASK_LOGS_EVIDENCES:
    case taskConstants.CREATE_PROJECT_TASK_LEVEL:
      set(newState, 'loading', true);
      return newState;
    case taskConstants.UPDATE_TASK_WEIGHTS:
      set(newState, 'importanceFactorsUpdated', false);
      set(newState, 'loading', true);
      return newState;
    case taskConstants.SET_NEW_PROJECT_TASK_FAIL:
    case taskConstants.GET_PROJECT_TASK_LIST_FAIL:
    case taskConstants.GET_PROJECT_PARENT_TASK_LIST_FAIL:
    case taskConstants.GET_PROJECT_TASK_LEVELS_FAIL:
    case taskConstants.UPDATE_PROJECT_TASK_FAIL:
    case taskConstants.UPDATE_PROJECT_TASK_STATUS_FAIL:
    case taskConstants.SET_NEW_TASK_LOG_FAIL:
    case taskConstants.GET_TASK_LOGS_FAIL:
    case taskConstants.GET_PRODUCT_STATUS_LOGS_FAIL:
    case taskConstants.SET_PRODUCT_STATUS_LOG_FAIL:
    case taskConstants.DOWNLOAD_TASK_LOGS_EVIDENCES_FAIL:
    case taskConstants.UPDATE_TASK_WEIGHTS_FAIL:
    case taskConstants.CREATE_PROJECT_TASK_LEVEL_FAIL:
      set(newState, 'importanceFactorsUpdated', false);
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;
    case taskConstants.UPDATE_TASK_WEIGHTS_SUCCESS:
      set(newState, 'importanceFactorsUpdated', true);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;
    case taskConstants.DOWNLOAD_TASK_LOGS_EVIDENCES_SUCCESS:
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;
    case taskConstants.SET_NEW_PROJECT_TASK_SUCCESS:
    case taskConstants.UPDATE_PROJECT_TASK_SUCCESS:
      set(newState, 'task', action.payload.data);
      set(newState, 'loading', false);
      return newState;
    case taskConstants.UPDATE_PROJECT_TASK_STATUS_SUCCESS:
      set(newState, 'task', action.payload.data);
      set(newState, 'loading', false);
      return newState;

    case taskConstants.GET_PROJECT_TASK_LIST_SUCCESS:
      set(newState, 'taskList', action.payload.data);
      set(newState, 'loading', false);
      return newState;

    case taskConstants.GET_PROJECT_PARENT_TASK_LIST_SUCCESS:
      set(newState, 'parentTaskList', action.payload.data);
      set(newState, 'loading', false);
      return newState;

    case taskConstants.GET_PROJECT_TASK_LEVELS_SUCCESS:
      set(newState, 'taskLevels', action.payload.data);
      set(newState, 'loading', false);
      return newState;

    case taskConstants.SET_NEW_TASK_LOG_SUCCESS:
    case taskConstants.SET_PRODUCT_STATUS_LOG_SUCCESS:
      set(newState, 'taskLog', action.payload.data);
      set(newState, 'loading', false);
      return newState;

    case taskConstants.GET_TASK_LOGS_SUCCESS:
      set(newState, 'taskLogList', action.payload.data);
      set(newState, 'loading', false);
      return newState;

    case taskConstants.GET_PRODUCT_STATUS_LOGS_SUCCESS:
      set(newState, 'productStatusLogList', action.payload.data);
      set(newState, 'loading', false);
      return newState;
    case taskConstants.CREATE_PROJECT_TASK_LEVEL_SUCCESS:
      set(newState, 'projectTaskLevel', action.payload.data);
      set(newState, 'loading', false);
      return newState;
    case commonConstants.RESET:
      return taskInitialState;
    default:
      return state;
  }
}
