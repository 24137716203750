import React, { Fragment, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Pagination, Stack } from '@mui/material';
import { withStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from '../../redux/actions/project-actions';
import { filterActions } from '../../redux/actions/filters-actions';

import styles from './styles';
import ProjectCard from './projectCard';
import CfFilters from '../../components/filters/cfFilters';
import PrincipalFilters from '../../components/filters/principalFilters';

const images = require.context('../../assets/images');

const Projects = ({ classes }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.authentication.user);
  const projects = useSelector(state => state.projects.projects);
  const page = useSelector(state => state.projects.page);
  const pageSize = useSelector(state => state.projects.pageSize);
  const totalProjects = useSelector(state => state.projects.total);
  const totalPages = useSelector(state => state.projects.totalPages);
  const projectsFail = useSelector(state => state.projects.fail);
  const loading = useSelector(state => state.projects.loading);
  const cfFilters = useSelector(state => state.filters.cfFilters.filters);
  const loadingCfFilters = useSelector(state => state.filters.cfFilters.loading);

  const [country, setCountry] = useState('');
  const [selectedCfFilters, setSelectedCfFilters] = useState('');
  const [orgGroupSelected, setOrgGroupSelected] = useState('');
  const [leafGroups, setLeafGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (currentUser) {
      dispatch(filterActions.getCfFilters(currentUser.client_id));
    }
  }, [currentUser]);

  useEffect(() => {
    setLeafGroups(orgGroupSelected ? [orgGroupSelected.id] : []);
    if (!!currentUser) {
      dispatch(
        projectActions.getFilteredProjects(currentUser.client_id, {
          ...getFilters(),
          leafGroups: !!orgGroupSelected ? [orgGroupSelected.id] : [],
        })
      );
    }
  }, [orgGroupSelected]);

  useEffect(() => {
    if (currentUser) {
      dispatch(
        projectActions.getFilteredProjects(currentUser.client_id, { ...getFilters(), cfFilters: selectedCfFilters })
      );
    }
  }, [selectedCfFilters]);

  const handlePageChange = (event, value) => {
    dispatch(projectActions.getFilteredProjects(currentUser.client_id, { ...getFilters(), page: value }));
  };

  const getFilters = () => {
    return {
      searchTerm,
      countryId: country?.id,
      leafGroups,
      cfFilters: selectedCfFilters,
      page,
      pageSize,
    };
  };

  return (
    <Fragment>
      <Grid className={`${classes.root}_header`}>
        <img className={`${classes.root}_header-img`} src={images('./place_holder.jpg')} alt='Logo' />
        <Grid className={`${classes.root}_header-button`}>
          <Button variant='contained' onClick={() => window.open('https://www.colombiasostenible.gov.co/', '_blank')}>
            Leer más sobre la iniciativa
          </Button>
        </Grid>
      </Grid>
      <PrincipalFilters
        currentUser={currentUser}
        country={country}
        setCountry={setCountry}
        orgGroupSelected={orgGroupSelected}
        setOrgGroupSelected={setOrgGroupSelected}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        getFilters={getFilters}
      >
        <CfFilters cfFilters={cfFilters} setSelectedCfFilters={setSelectedCfFilters} loading={loadingCfFilters} />
      </PrincipalFilters>
      {loading && (
        <Stack alignItems='center' justifyContent='center' width='100%' height='50%'>
          <CircularProgress size={100} />
        </Stack>
      )}
      <Grid className={`${classes.cards}`}>
        {!loading && projects.map((project, index) => <ProjectCard key={`projectCard_${index}`} project={project} />)}
      </Grid>
      {!loading && (
        <Pagination
          className={`${classes.pagination}`}
          size='small'
          count={totalPages || 0}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Fragment>
  );
};

export default withStyles(styles)(Projects);
