import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { Chip, Avatar, TableCell, TableRow, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';
import { projectUtil } from '../../../utils/project.utils';

const ProgressRow = ({ createdAt, effectiveDate, formattedValue, comment, responsible, color, full, classes }) => {
  const { email } = responsible || {};
  const userInitials = projectUtil.renderAvatarInitials(responsible);
  return (
    <TableRow className={`${classes.progress}_row ${classes.progress}_row_color_${color}`}>
      {full && (
        <TableCell>
          <FormattedDate value={createdAt} year='numeric' month='short' day='2-digit' />
        </TableCell>
      )}
      <TableCell>
        <FormattedDate value={effectiveDate} year='numeric' month='short' day='2-digit' />
      </TableCell>
      <TableCell>
        <Chip label={formattedValue} size="small"/>
      </TableCell>
      {full && (
        <>
          <TableCell>{comment}</TableCell>
          <TableCell>
            <Avatar sx={{ bgcolor: projectUtil.getRandomColorByString(email) }}>
              <Typography variant='button'>{userInitials}</Typography>
            </Avatar>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

ProgressRow.propTypes = {
  createdAt: PropTypes.string,
  effectiveDate: PropTypes.string,
  formattedValue: PropTypes.string,
  comment: PropTypes.string,
  responsible: PropTypes.object,
  color: PropTypes.string,
  full: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(styles)(ProgressRow);
