import { useState, useEffect } from 'react';
import { Button, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import { contractStatuses } from '..';
import PropTypes from 'prop-types';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../dropdown';
import { contractModificationActions } from '../../../redux/actions/contract-modification-actions';
import { HeaderInfo } from '../../../pages/project-info/contractualInfo';
import styles from '../../../pages/project-info/contractualInfo/styles';

const ChangeStatusModal = ({ open, classes, onClose, selectedStatus }) => {
  const [effectiveDate, setEffectiveDate] = useState(Date.now());
  const [reason, setReason] = useState({ name: '', value: '' });
  const [comment, setComment] = useState('');
  const intl = useIntl();
  const formatDate = intl.messages['projectInfo.product.dateFormat'];
  const dispatch = useDispatch();
  const project = useSelector(state => state.projects.project);
  const reasons = useSelector(state => state.contractModifications.justifications);
  const mainContract = project?.main_contract;
  const currentUser = useSelector(state => state.authentication.user);
  const clientId = currentUser.client_id;

  useEffect(() => {
    if (clientId) {
      dispatch(contractModificationActions.getContractModificationJustifications(clientId));
    }
  }, [clientId]);

  const closeUI = (
    <Grid container justifyContent='space-between' marginBottom={1}>
      <Grid item marginLeft='auto'>
        <IconButton color='secondary' aria-label='close_modal' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const headerUI = (
    <>
      <Grid container marginBottom={1}>
        <Typography variant='h4'>
          <FormattedMessage id='mainContractLabel.modifyStatus' defaultMessage=' Modificar estado' />
        </Typography>
        <HeaderInfo classes={classes} />
      </Grid>
    </>
  );

  const currentStatusUI = (
    <>
      <Grid container padding='0px 10px 10px'>
        <Grid container>
          <Typography variant='body2'>
            <FormattedMessage id='mainContractStory.contractStatus' defaultMessage='Estado del contrato' />
          </Typography>
        </Grid>
        <Grid container>
          <Typography variant='subtitle1'>{contractStatuses[selectedStatus]?.label}</Typography>
        </Grid>
      </Grid>
    </>
  );

  const dateInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={<FormattedMessage id='mainContractLabel.effectiveDate' defaultMessage='Fecha efectiva' />}
          value={effectiveDate}
          className={classes.datePicker}
          inputFormat={formatDate}
          onChange={setEffectiveDate}
          renderInput={params => <TextField {...params} size='small' required />}
          components={{
            OpenPickerIcon: CalendarTodayIcon,
          }}
        />
      </LocalizationProvider>
      <Grid container margin='10px 10px 0px'>
        <Typography variant='body2'>
          <FormattedMessage
            id='mainContractLabel.effectiveDateIndication'
            defaultMessage='Indique la fecha en que se acordó el cambio de estado del contrato'
          />
        </Typography>
      </Grid>
    </Grid>
  );

  const causeInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <Dropdown
        required
        labelField={'name'}
        idName='mainContractLabel.reason'
        label={<FormattedMessage id='mainContractLabel.reason' defaultMessage='Justificación/Causa' />}
        listItems={reasons}
        handleOnChange={setReason}
        hookValue={reason}
      />
    </Grid>
  );

  const commentInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <TextField
        autoFocus
        value={comment}
        onChange={e => setComment(e.target.value)}
        label={<FormattedMessage id='projectInfo.approve.comments' defaultMessage='Comentarios' />}
        size='small'
        variant='outlined'
        multiline
        rows={5}
      />
    </Grid>
  );

  const inputsUI = (
    <>
      {dateInputUI}
      {causeInputUI}
      {commentInputUI}
    </>
  );

  const handleSubmit = () => {
    const contractModificationParams = {
      status: selectedStatus,
      effective_date: new Date(effectiveDate).toISOString(),
      responsible_id: currentUser.id,
      reason_id: reason.id,
      comment,
    };

    dispatch(contractModificationActions.createContractModification(mainContract.id, contractModificationParams));
  };

  const areInputsValid = () => {
    return !!reason.id && !!effectiveDate;
  };

  const buttonsUI = (
    <Grid container marginTop={1}>
      <Button variant='contained' color='info' disabled={!areInputsValid()} onClick={handleSubmit}>
        <Typography variant='button'>
          <FormattedMessage id='mainContractLabel.modifyStatus' defaultMessage='modificar estado' />
        </Typography>
      </Button>
      <Button variant='outlined' color='info' className={classes.button} onClick={onClose}>
        <FormattedMessage id='projectInfo.product.cancel' defaultMessage='Cancelar' />
      </Button>
    </Grid>
  );

  return (
    <Modal open={open} onClose={onClose} className={`${classes.modal}_container`}>
      <Grid
        container
        className={`${classes.modal}_body`}
        justifyContent='space-between'
        maxWidth='500px'
        padding='20px 40px 40px'
      >
        {closeUI}
        {headerUI}
        {currentStatusUI}
        {inputsUI}
        {buttonsUI}
      </Grid>
    </Modal>
  );
};

ChangeStatusModal.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.object,
  selectedStatus: PropTypes.object,
  onClose: PropTypes.func,
};

export default withStyles(styles)(ChangeStatusModal);
