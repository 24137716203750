import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import ClickCard from '../../../components/clickCard';
import LabelState from '../../../components/labelState';
import placeHolderImage from '../../../assets/images/place_holder.jpg';

// Styles
import styles from './styles';
import { projectUtil } from '../../../utils/project.utils';
import MainContractInfo from './mainContractInfo';

const ProjectCard = ({ classes, project }) => {
  const [name, setName] = useState('');
  const [selectedCfValues, setSelectedCfValues] = useState([]);
  const { main_contract: mainContract } = project;

  useEffect(() => {
    setName(projectUtil.firstCharUpperCase(projectUtil.textTruncate(project.name, 150)));
    setSelectedCfValues(projectUtil.cfValuesState(project));
  }, []);

  const CfValues = () => {
    return (
      <>
        {!!selectedCfValues.length &&
          selectedCfValues.map((cfValue, index) => {
            return <LabelState key={`cfValue_${index}`} cfValue={cfValue} />;
          })}
      </>
    );
  };

  return (
    <ClickCard
      classes={classes}
      img={placeHolderImage}
      subtitle={<FormattedMessage id='projects.projectCard.project' defaultMessage='Project' />}
      title={name}
      projectId={project.id}
    >
      {!isEmpty(mainContract) ? <MainContractInfo mainContract={mainContract} /> : <CfValues />}
    </ClickCard>
  );
};

export default withStyles(styles)(ProjectCard);
