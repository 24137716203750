import { TableBody } from '@mui/material';
import { groupBy } from 'lodash';
import { DeliverableRow } from './DeliverableRow';
import { useIntl } from 'react-intl';

export const DeliverableTableBody = ({
  classes,
  currency,
  responsible,
  defaultTask,
  projectId,
  type,
  parentLevel,
  level,
  products,
}) => {
  const intl = useIntl();

  const getLocalizedLevelName = levelName => {
    if (!levelName) {
      return '';
    }

    const [name, nestedNumber] = levelName?.split(' ') || [];

    return `${intl.messages[`projectInfo.${name}`]} ${nestedNumber}`;
  };

  const tasksGroupedByResult = Object.entries(groupBy(products, 'parent.parent.id')).map(([key, value]) => ({
    id: key,
    name: getLocalizedLevelName(value[0]?.parent?.parent?.name),
    importance_factor: value[0]?.parent?.parent?.importance_factor,
    children_progress: value[0]?.parent?.parent?.children_progress,
    description: value[0]?.parent?.parent?.description,
    project_task_level_id: value[0]?.parent?.parent?.project_task_level_id,
    children: Object.entries(groupBy(value, 'parent.id')).map(([childrenKey, childrenValue]) => ({
      id: childrenKey,
      name: getLocalizedLevelName(childrenValue[0]?.parent?.name),
      description: childrenValue[0]?.parent?.description,
      project_task_level_id: childrenValue[0]?.parent?.project_task_level_id,
      importance_factor: childrenValue[0]?.parent?.importance_factor,
      children_progress: childrenValue[0]?.parent?.children_progress,
      contracts: Object.entries(groupBy(childrenValue, 'contract_id')).map(([contractKey, contractValue]) => ({
        id: contractKey,
        description: contractValue[0]?.contract?.contract_object,
        progress: contractValue[0]?.contract?.tasks_progress,
        importance_factor: contractValue[0]?.contract?.importance_factor,
        children_progress: contractValue[0]?.contract?.children_progress,
        deliverables: contractValue,
      })),
    })),
  }));

  return (
    <>
      {tasksGroupedByResult.map(result => {
        return (
          <TableBody key={result.id}>
            <DeliverableRow
              classes={classes}
              result={result}
              products={products}
              currency={currency}
              responsible={responsible}
              defaultTask={defaultTask}
              progress={defaultTask}
              projectId={projectId}
              type={type}
              siblings={tasksGroupedByResult}
              parentLevel={parentLevel}
              level={level}
            />
          </TableBody>
        );
      })}
    </>
  );
};
