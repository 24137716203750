import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const CurrencyInput = ({ label, value, onChange, suffix, prefix }) => {
  const materialUITextFieldProps = {
    fullWidth: true,
    label: label,
    size: 'small',
  };

  return (
    <NumericFormat
      value={value}
      required
      name='currency'
      allowLeadingZeros
      thousandSeparator=','
      customInput={TextField}
      onChange={onChange}
      suffix={` ${!!suffix ? suffix : ''}`}
      prefix={`${!!prefix ? prefix : ''} `}
      {...materialUITextFieldProps}
    />
  );
};

CurrencyInput.propType = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
};

export default CurrencyInput;
