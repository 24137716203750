const styles = theme => ({
  root: {
    gap: 10,
    '&_head': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    '&_body': {
      display: 'flex',
      flexDirection: 'column !important',
      width: '100%',
      justifyContent: 'space-between',
      gap: 10,
      maxHeight: '80px',
      overflowY: 'auto',
    },
    '&_column': {
      display: 'flex',
      justifyContent: 'space-between',
    }
  }
});

export default styles;
