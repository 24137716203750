import { Tooltip, Zoom } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import styles from './styles';

const TooltipZoom = ({ classes, title, children, style = 1 }) => {
  return (
    <Tooltip
      classes={{ tooltip: `${classes.root}__style_${style}` }}
      title={title}
      arrow
      TransitionComponent={Zoom}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
};

TooltipZoom.propTypes = {
  classes: PropTypes.object,
  classes: PropTypes.object,
  children: PropTypes.any,
  style: PropTypes.number,
};

export default withStyles(styles)(TooltipZoom);
