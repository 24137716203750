import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, CardActionArea, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ClickCard = ({ classes, img, subtitle, title, projectId, children }) => {
  let navigate = useNavigate();

  return (
    <Card className={`${classes.card}`}>
      <CardActionArea
        className={`${classes.card}_area`}
        onClick={() => {
          navigate(`/project/${projectId}`);
        }}
      >
        {!!img && <CardMedia className={`${classes.card}-media`} component='img' image={img} alt='Project Image' />}
        <CardContent className={`${classes.card}_content`}>
          <Grid className={`${classes.card}-subtitle`}>{!!subtitle && subtitle}</Grid>
          <Grid className={`${classes.card}-title`}>{!!title && title}</Grid>
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ClickCard.propTypes = {
  classes: PropTypes.object,
  img: PropTypes.string,
  subtitle: PropTypes.element,
  title: PropTypes.string,
  children: PropTypes.any,
};

// Redux connection
export default ClickCard;
