import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

export const ContractSelector = ({ contractId, setContractId, contracts }) => {
  const intl = useIntl();
  const handleChangeResult = event => {
    setContractId(event.target.value);
  };

  const selectLabelUI = (
    <Typography variant='inherit' color='text.secondary'>
      {intl.messages[`projectInfo.contract.select`]}{' '}
      <Typography component='span' color='red'>
        *
      </Typography>
    </Typography>
  );

  const renderFoundValue = selected => {
    const foundParentTask = contracts.find(contract => contract.id === selected)?.contract_object;
    return <>{foundParentTask && <Typography textTransform='capitalize'>{foundParentTask}</Typography>}</>;
  };

  return (
    <>
      <InputLabel id='select-parent-label'>{selectLabelUI}</InputLabel>
      <Select
        displayEmpty
        labelId='select-parent-label'
        id='select-parent-input'
        required
        value={contractId}
        label={selectLabelUI}
        onChange={handleChangeResult}
        renderValue={renderFoundValue}
      >
        {!isEmpty(contracts) &&
          contracts.map((contract, index) => (
            <MenuItem key={`Result_${index}_${contract.id}`} value={contract.id} >
              <Grid container maxWidth='350px'>
                <Typography textTransform='capitalize' textOverflow='ellipsis' >
                  {contract.contract_object}
                </Typography>
              </Grid>
            </MenuItem>
          ))}
      </Select>
    </>
  );
};
