import { Divider, Tab, Tabs, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useMemo } from 'react';
import styles from './styles';

const TabDivider = ({ classes }) => <Divider className={`${classes.root}__divider`} orientation='vertical' />;

const withoutDivider = currentValue => {
  const without = [];
  without.push(currentValue);
  without.push(currentValue + 1);
  return without;
};

const childrenItems = (classes, currentValue, itemsList) => {
  const children = [];
  const without = withoutDivider(currentValue);
  itemsList.forEach((tab, index) => {
    if (!index % 2 !== 1 && !without.includes(index)) {
      children.push(<TabDivider classes={classes} key={`divider_${index}`} />);
    }
    children.push(
      <Tab
        className={`${classes.root}__tab`}
        label={
          <Typography variant='subtitle2' color='primary.dark'>
            {tab}
          </Typography>
        }
        key={`toggle_${index}`}
        value={index}
      />
    );
  });
  return children;
};

const ToggleMenu = ({ classes, itemsList, currentValue, handleChangeToggleMenu }) => {
  const children = useMemo(() => childrenItems(classes, currentValue, itemsList), [currentValue]);

  return (
    <Tabs
      className={`${classes.root}`}
      value={currentValue}
      onChange={(event, newValue) => handleChangeToggleMenu(newValue)}
      centered
      aria-label='Tabs Menu'
    >
      {children}
    </Tabs>
  );
};

export default withStyles(styles)(ToggleMenu);
