import { Grid, IconButton, Link } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';

const FileList = ({
  classes,
  headTitle,
  titleAll,
  onClickAll,
  filesArray,
  onClickFile,
  icon,
  iconSize,
  onClickIcon,
}) => {
  return (
    <Grid container className={classes.root}>
      <Grid className={`${classes.root}_head`}>
        {headTitle}
        <Link component='button' variant='body2' onClick={() => onClickAll()}>
          {titleAll}
        </Link>
      </Grid>
      <Grid className={`${classes.root}_body`}>
        {filesArray.map((file, index) => (
          <Grid key={`fileList_${index}`} className={`${classes.root}_column`}>
            <Link component='button' variant='body2' onClick={() => onClickFile(index)}>
              {file.name}
            </Link>
            <IconButton size={iconSize} aria-label='Icon-Button' onClick={() => onClickIcon(index)}>
              {icon}
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FileList);
