import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { taskActions } from '../../../../redux/actions/task-actions';
import CloseIcon from '@mui/icons-material/Close';
import CollectionsIcon from '@mui/icons-material/Collections';

import styles from './styles';
import FileList from '../../../../components/fileList';
import { Clear } from '@mui/icons-material';
import DragDropFile from '../../../../components/dragDropFile';
import { NumericFormat } from 'react-number-format';
import TooltipZoom from '../../../../components/tooltipZoom';
import { projectUtil } from '../../../../utils/project.utils';

const CreateAdvanceModal = ({ classes, task, currency, type, onClose }) => {
  const defaultAdvance = {
    description: '',
    amount: '',
    valueAddedAt: '',
    valueAdded: '',
    evidences: [],
  };

  const intl = useIntl();
  const dispatch = useDispatch();
  const formatDate = intl.messages['projectInfo.product.dateFormat'];
  const [openCreateAdvance, setOpenCreateAdvance] = useState(false);
  const [newAdvance, setNewAdvance] = useState({ ...defaultAdvance });
  const [flag, setFlag] = useState('normal');
  const loading = useSelector(state => state.tasks.loading);
  const inputRef = useRef(null);

  useEffect(() => {
    if (openCreateAdvance) {
      dispatch(taskActions.getTaskLogs(task.id));
    }
  }, [openCreateAdvance]);

  const restartNewAdvance = () => {
    setOpenCreateAdvance(false);
    setNewAdvance({ ...defaultAdvance });
  };

  const handleAdvanceChange = prop => event => {
    const value = prop === 'valueAddedAt' ? event : event.target.value;
    setNewAdvance({ ...newAdvance, [prop]: value });
  };

  const handleFulfillmentChange = value => {
    setNewAdvance({ ...newAdvance, valueAdded: value });
  };

  const handleFiles = files => {
    const joinedEvidences = [...newAdvance.evidences, ...Object.values(files)];
    setNewAdvance({ ...newAdvance, evidences: [...joinedEvidences] });
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleAddAdvanceButton = () => {
    const auxAdvance = {
      ...newAdvance,
      valueAddedAt: new Date(newAdvance.valueAddedAt).toISOString(),
      amount: projectUtil.removeDecimalsFromAmount(newAdvance.amount),
    };
    dispatch(taskActions.setTaskLog(task.id, auxAdvance));
    restartNewAdvance();
  };

  const handleCancelButton = () => {
    onClose && onClose();
    restartNewAdvance();
  };

  const onClickDeleteAll = () => {
    setNewAdvance({ ...newAdvance, evidences: [] });
  };

  const onClickDeleteFile = fileIndex => {
    const clonedEvidences = [...newAdvance.evidences];
    clonedEvidences.splice(fileIndex, 1);
    setNewAdvance({ ...newAdvance, evidences: [...clonedEvidences] });
  };

  const inputsValid = () => {
    const { amount, valueAdded, evidences, valueAddedAt } = newAdvance;

    return (
      isEmpty(amount) || isEmpty(valueAdded) || isEmpty(evidences) || isEmpty(valueAddedAt && valueAddedAt.toString())
    );
  };

  return (
    <>
      <TooltipZoom title={<FormattedMessage id='projectInfo.product.newAdvance' defaultMessage='New advance' />}>
        <MenuItem aria-label='open progress modal' onClick={() => setOpenCreateAdvance(true)}>
          {intl.messages['label.deliver']}
        </MenuItem>
      </TooltipZoom>
      <Modal open={openCreateAdvance} onClose={() => setOpenCreateAdvance(false)}>
        <Grid className={`${classes.task}-modal`}>
          {loading && (
            <Stack alignItems='center' justifyContent='center' width='100%' height='50%'>
              <CircularProgress size={100} />
            </Stack>
          )}
          {!loading && (
            <>
              <Grid container className={`${classes.task}_head`} justifyContent='space-between'>
                <Grid item xs={10}>
                  <Typography variant='h5' color='text.secondary'>
                    {intl.messages['projectInfo.advance.recordProgress']} {type} {task.nested_positions}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    className={`${classes.task}_head-button`}
                    color='primary'
                    aria-label='close task modal'
                    onClick={handleCancelButton}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='inherit' color='text.secondary'>
                    {intl.messages['mainContract.requiredAsteriskInfo']}{' '}
                    <Typography component='span' color='red'>
                      *
                    </Typography>
                    .
                  </Typography>
                </Grid>
              </Grid>
              <Divider flexItem className={`${classes.advance}-divider`} />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={<FormattedMessage id='projectInfo.advance.advanceDate' defaultMessage='Advance Date' />}
                  inputFormat={formatDate}
                  id='valueAddedAt'
                  value={newAdvance.valueAddedAt}
                  onChange={handleAdvanceChange('valueAddedAt')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: intl.messages['projectInfo.advance.date'] || 'Date',
                      }}
                      size='small'
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Grid container className={`${classes.advance}_grid_advance`}>
                <FormControl className={`${classes.advance}_label`}>
                  <FormLabel className={`${classes.task}_label`} id='labelRadioButtons'>
                    <Typography variant='inherit' color='text.secondary'>
                      <FormattedMessage id='projectInfo.advance.fulfillment' defaultMessage='Fulfillment' />{' '}
                      <Typography component='span' color='red'>
                        *
                      </Typography>
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='radio-buttons-group'
                    name='radio-buttons-group'
                    value={newAdvance.valueAdded}
                    onChange={event => handleFulfillmentChange(event.target.value)}
                    required
                  >
                    <FormControlLabel
                      className={`${classes.task}_radioButton`}
                      value={1}
                      control={<Radio />}
                      label={<FormattedMessage id='projectInfo.advance.yes' defaultMessage='Yes' />}
                    />
                    <FormControlLabel
                      className={`${classes.task}_radioButton`}
                      value={0}
                      control={<Radio />}
                      label={<FormattedMessage id='projectInfo.advance.no' defaultMessage='No' />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <NumericFormat
                allowLeadingZeros
                thousandSeparator=','
                customInput={TextField}
                id='amount'
                label={<FormattedMessage id='projectInfo.advance.amount' defaultMessage='Budget' />}
                value={newAdvance.amount || null}
                onChange={handleAdvanceChange('amount')}
                size='small'
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>{currency || 'USD'}</InputAdornment>,
                }}
              />
              <TextField
                id='description'
                label={<FormattedMessage id='projectInfo.advance.comment' defaultMessage='Comment' />}
                value={newAdvance.description}
                onChange={handleAdvanceChange('description')}
                placeholder={intl.messages['projectInfo.advance.addComment'] || 'Add Comment'}
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              />
              <Grid container className={`${classes.advance}_grid_evidence`}>
                <Typography variant='subtitle2' color='text.secondary'>
                  <FormattedMessage id='projectInfo.advance.addEvidence' defaultMessage='Add evidence' />{' '}
                  <Typography component='span' color='red'>
                    *
                  </Typography>
                </Typography>
                <Typography variant='body' color='text.secondary'>
                  <FormattedMessage
                    id='projectInfo.advance.attachEvidence'
                    defaultMessage='Add here the evidence you want to attach.'
                  />
                </Typography>
                <DragDropFile inputRef={inputRef} handleFiles={handleFiles} flag={flag} setFlag={setFlag}>
                  <Grid className={`${classes.advance}_grid-icon`}>
                    <CollectionsIcon sx={{ fontSize: 24 }} />
                  </Grid>
                  <Grid container className={`${classes.advance}_grid_link`}>
                    <Link underline='always' component='button' onClick={onButtonClick}>
                      <FormattedMessage id='projectInfo.advance.upload' defaultMessage='Click to upload' />
                    </Link>
                    <Typography variant='subtitle2' color='text.secondary'>
                      <FormattedMessage id='projectInfo.advance.dragDrop' defaultMessage='or drag and drop here' />
                    </Typography>
                  </Grid>
                  <Grid container className={`${classes.advance}_grid_link`}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      SVG, PNG, JPG, GIF, PDF, DOC o XLSX (max. [3MB])
                    </Typography>
                  </Grid>
                </DragDropFile>
              </Grid>
              {!isEmpty(newAdvance.evidences) && (
                <FileList
                  headTitle={<FormattedMessage id='projectInfo.advance.attachments' defaultMessage='Attachments' />}
                  titleAll={<FormattedMessage id='projectInfo.advance.deleteAll' defaultMessage='DELETE ALL' />}
                  onClickAll={onClickDeleteAll}
                  filesArray={newAdvance.evidences}
                  icon={<Clear />}
                  onClickIcon={onClickDeleteFile}
                />
              )}
              <Grid container className={`${classes.advance}_grid_button`}>
                <Button variant='contained' onClick={handleAddAdvanceButton} disabled={inputsValid()}>
                  <FormattedMessage id='projectInfo.advance.submit' defaultMessage={'Submit progress for review'} />
                </Button>
                <Button variant='outlined' onClick={handleCancelButton}>
                  <FormattedMessage id='projectInfo.advance.cancel' defaultMessage={'Cancel'} />
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Modal>
    </>
  );
};

export default withStyles(styles)(CreateAdvanceModal);
