import React, { Fragment } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import configureStore from './redux/store/configureStore';
import CapGreenRouter from './router';
import { getTheme } from './settings/themes';

function App() {
  const store = configureStore;
  return (
    <Fragment>
      <ThemeProvider theme={getTheme()}>
        <CssBaseline />
        <Provider store={store}>
          <CapGreenRouter />
        </Provider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
