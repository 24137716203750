String.prototype.capitalizeFirstLetter = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const esFormatDate = (dateString, separatorString) => {
  const regex = /[a-z]/;
  const foundletter = dateString[dateString.search(regex)];
  return dateString.includes(',')
    ? dateString
    : dateString.replace(foundletter, foundletter.toUpperCase()).replaceAll(' ', separatorString);
};
