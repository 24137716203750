import styles from '../styles';
import { withStyles } from '@mui/styles';
import { Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { contractModificationActions } from '../../../../redux/actions/contract-modification-actions';
import { useDispatch, useSelector } from 'react-redux';
import StoryCard from './storyCard';
import { FormattedMessage } from 'react-intl';

const CheckBoxWithLabel = ({ label, name, checked, handleChange }) => {
  return (
    <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} name={name} />} label={label} />
  );
};

const LabelText = ({ text }) => {
  return (
    <Typography variant='subtitle2' color='GrayText'>
      {text}
    </Typography>
  );
};

const StoryFilters = ({ filtersChecked, setFiltersChecked }) => {
  const filters = [
    {
      name: 'status',
      label: (
        <LabelText text={<FormattedMessage id='projectInfo.product.productFilter.status' defaultMessage='Estado' />} />
      ),
      checked: filtersChecked.status,
    },
    {
      name: 'termInDays',
      label: <LabelText text={<FormattedMessage id='mainContractStory.term' defaultMessage='Plazo' />} />,
      checked: filtersChecked.termInDays,
    },
    {
      name: 'amountCents',
      label: <LabelText text={<FormattedMessage id='mainContractStory.amount' defaultMessage='Monto' />} />,
      checked: filtersChecked.amountCents,
    },
  ];

  return (
    <Grid container spacing={1.3} color='red' alignItems='center'>
      <Grid item>
        <Typography variant='subtitle1' color='GrayText'>
          <FormattedMessage id='projectInfo.product.productFilter.filters' defaultMessage='Filters' />
        </Typography>
      </Grid>
      {filters.map(({ name, label, checked }) => (
        <Grid item key={name}>
          <CheckBoxWithLabel
            label={label}
            checked={checked}
            name={name}
            handleChange={e => setFiltersChecked({ ...filtersChecked, ...{ [e.target.name]: e.target.checked } })}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const StoryCards = ({ classes, contractModifications, filtersChecked }) => {
  return (
    <Grid container spacing={2} className={`${classes.story}_group`}>
      {contractModifications.map((contractModification, index) => {
        const prevModifications = contractModifications.slice(index + 1, contractModifications.length);
        const prevAmountChangeModification = prevModifications.findLast(item => item.amount_cents > 0);
        const prevTermModification = prevModifications.findLast(item => item.term_in_days > 0);
        return (
          <StoryCard
            key={contractModification.id}
            contractModification={contractModification}
            classes={classes}
            previousModification={prevModifications[0] || {}}
            prevAmountModification={prevAmountChangeModification}
            prevTermModification={prevTermModification}
            filtersChecked={filtersChecked}
          />
        );
      })}
    </Grid>
  );
};

const ContractModificationStory = ({ classes, contractId }) => {
  const dispatch = useDispatch();
  const contractModifications = useSelector(state => state.contractModifications.contractModifications);
  const isContractModificationsLoading = useSelector(state => state.contractModifications.loading);
  const [filtersChecked, setFiltersChecked] = useState({
    status: false,
    termInDays: false,
    compensableDays: false,
    amountCents: false,
  });

  useEffect(() => {
    if (contractId) {
      dispatch(contractModificationActions.getContractModifications(contractId));
    }
  }, [contractId]);

  return (
    <>
      {!isContractModificationsLoading && (
        <Grid container spacing={2} flexDirection='column' width='96%'>
          <Grid item>
            <StoryFilters filtersChecked={filtersChecked} setFiltersChecked={setFiltersChecked} />
          </Grid>
          <Grid item>
            <StoryCards
              contractModifications={contractModifications}
              classes={classes}
              filtersChecked={filtersChecked}
            />
          </Grid>
        </Grid>
      )}
      {isContractModificationsLoading && (
        <Grid container alignItems='center' justifyContent='center'>
          <CircularProgress color='secondary' size={50} />
        </Grid>
      )}
    </>
  );
};
export default withStyles(styles)(ContractModificationStory);
