/**
 *
 * Auth saga file
 * Any action made for an projects should go here
 *
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import PhysicalProgressService from '../../services/physical.progress.services';
import { physicalProgressConstants } from '../constants/physical-progress-constants';

/**
 * Workers
 */

export function* createPhysicalProgress(action) {
  const result = yield call(PhysicalProgressService.createPhysicalProgress, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: physicalProgressConstants.CREATE_PHYSICAL_PROGRESS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: physicalProgressConstants.CREATE_PHYSICAL_PROGRESS_FAIL,
    });
  }
}

export function* getPhysicalProgresses(action) {
  const result = yield call(PhysicalProgressService.getPhysicalProgresses, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: physicalProgressConstants.GET_PHYSICAL_PROGRESSES_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: physicalProgressConstants.GET_PHYSICAL_PROGRESSES_FAIL,
    });
  }
}

/**
 * Watchers
 */

export function* createPhysicalProgressRequest() {
  yield takeEvery(physicalProgressConstants.CREATE_PHYSICAL_PROGRESS, createPhysicalProgress);
}

export function* getPhysicalProgressesRequest() {
  yield takeEvery(physicalProgressConstants.GET_PHYSICAL_PROGRESSES, getPhysicalProgresses);
}

/**
 * Export function
 */
function* physicalProgressSaga() {
  // Watchers request
  yield all([createPhysicalProgressRequest(), getPhysicalProgressesRequest()]);
}

export default physicalProgressSaga;
