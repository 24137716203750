/**
 * Configuration file for stores 
 * like middlewares
 */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import sagaFiles from '../sagas';


const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  applyMiddleware(sagaMiddleware)
];
const configureStore = createStore(rootReducer, compose.apply(undefined, middlewares));

sagaMiddleware.run(sagaFiles);

export default configureStore;
