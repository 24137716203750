const styles = theme => ({
  breadCrumbs: {
    '&.MuiBreadcrumbs-root': {
      margin: 10,
      color:theme.palette.primary.main,
      fontWeight: '400 !important',
    },
  },
});

export default styles;
