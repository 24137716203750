import API from "./api.service";

class UserService {
  static async updateUser ({clientId, user}) {
    const params = {
      user: {
        ...user,
        client_id: clientId
      }
    };
    try {
      const resp = await API().put(`users/${user.id}`, params);
      return { status: resp.status, data: resp.data };
    } catch (e) {
      return { status: e.status };
    }
  }
}

export default UserService;
