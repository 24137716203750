import { Modal, Button, Grid, IconButton, Typography } from '@mui/material';
import { FormattedDate, FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { contractStatuses } from '../../../../../components/headCard';
import { contractModificationNames, contractModificationsTypes } from '../storyCard';

const StoryDetailModal = ({
  classes,
  contract,
  contractModification,
  modificationName,
  prevStatus,
  prevFormattedAmount,
  prevTermInDays,
  open,
  onClose,
}) => {
  const {
    status,
    created_at: createdAt,
    effective_date: effectiveDate,
    reason,
    modification_type: modificationType,
    compensable_days: compensableDays,
    termination_date: terminationDate,
    amount_cents: amountCents,
    formatted_amount: formattedAmount,
    external_references: externalReferences,
    comment: comment,
    term_in_days: termInDays,
  } = contractModification || {};

  const isACompensableDaysChange = !!compensableDays && !!terminationDate;
  const isAmountChange = amountCents > 0;
  const isTermInDaysChange = termInDays > 0;
  const isAStatusChange = prevStatus !== status && !!prevStatus;

  const actionButtonUI = (
    <Grid container className={`${classes.modal}_body_button_container`} marginBottom={4}>
      <Button variant='outlined' color='info' className={classes.button} onClick={onClose}>
        <FormattedMessage id='projectInfo.product.close' defaultMessage='Close' />
      </Button>
    </Grid>
  );

  const titleUI = (
    <Grid container justifyContent='space-between' marginBottom={1}>
      <Grid item xs={11}>
        <Typography variant='subtitle1'>
          <FormattedMessage id='mainContractLabel.contractModification' defaultMessage='Contract Modification' />
        </Typography>
        <Typography variant='h4'>
          <FormattedMessage
            id={`mainContractStory.${modificationName}`}
            defaultMessage={contractModificationNames[modificationName]?.default}
          />
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton color='secondary' aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const mainAttributes = [
    {
      label: 'mainContractStory.modificationType',
      dafultLabel: 'Modification type',
      value: contractModificationsTypes[modificationType].label,
    },
    {
      label: 'mainContractStory.modificationDate',
      defaultLabel: 'Modification date',
      value: <FormattedDate value={createdAt} month='short' year='numeric' day='2-digit' />,
    },
    {
      label: 'mainContractLabel.effectiveDate',
      defaultLabel: 'Effective date',
      value: <FormattedDate value={effectiveDate} month='short' year='numeric' day='2-digit' />,
    },
    {
      label: 'mainContractStory.justification',
      defaultLabel: 'Justification',
      value: reason?.name,
    },
  ];

  const modificationDetails = (() => {
    const modificationDetails = [];
    if (isACompensableDaysChange || isTermInDaysChange) {
      modificationDetails.concat([
        {
          label: 'mainContractLabel.currentTerminationDate',
          defaultLabel: 'Fecha del último desembolso del convenio',
          value: <FormattedDate value={terminationDate} month='short' year='numeric' day='2-digit' />,
        },
        {
          label: 'mainContractStory.currentTermInDays',
          defaultLabel: 'Current contract term in days',
          value: prevTermInDays || contract?.original_term_in_days,
        },
      ]);

      isACompensableDaysChange &&
        modificationDetails.push({
          label: 'mainContractLabel.compensableDays',
          defaultLabel: 'Compensable Days',
          value: compensableDays,
        });

      isTermInDaysChange &&
        modificationDetails.push({
          label: 'mainContractStory.contractTermExtension',
          defaultLabel: 'Contract term extension',
          value: termInDays - (prevTermInDays || contract?.original_term_in_days),
        });

      modificationDetails.push({
        label: 'mainContractStory.newTermInDays',
        defaultLabel: 'New contract term in days',
        value: termInDays,
      });
    }

    isAStatusChange &&
      modificationDetails.push({
        label: 'mainContractStory.status',
        defaultLabel: 'Status',
        value: contractStatuses[status]?.label,
      });

    return modificationDetails;
  })();

  const amountModification = [
    {
      label: 'mainContractStory.currentAmount',
      defaultLabel: 'Current amount',
      value: prevFormattedAmount || contract?.formatted_original_amount,
    },
    {
      label: 'mainContractStory.newAmount',
      defaultLabel: 'New amount',
      value: formattedAmount,
    },
  ];

  const bodyUI = (
    <Grid container>
      {mainAttributes.map(attribute => (
        <Grid item xs={12} key={attribute.label} marginBottom='10px'>
          <Typography color='gray.dark' variant='subtitle2'>
            <FormattedMessage id={attribute.label} defaultMessage={attribute.defaultLabel} />:
          </Typography>
          <Typography color='gray.dark' variant='subtitle1'>
            {attribute.value}
          </Typography>
        </Grid>
      ))}
      <Grid item xs={12} marginBottom='10px'>
        {modificationDetails.map(attribute => (
          <Grid container key={attribute.label} alignItems='baseline'>
            <Typography color='gray.dark' variant='subtitle2'>
              <FormattedMessage id={attribute.label} defaultMessage={attribute.defaultLabel} />
              :&nbsp;
            </Typography>
            <Typography color='gray.dark' variant='subtitle1'>
              {attribute.value}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {isAmountChange && (
        <Grid item xs={12} marginBottom='10px'>
          <Typography color='gray.dark' variant='subtitle2'>
            <FormattedMessage id='mainContractLabel.currentAgreementAmount' defaultMessage='Contract Agreement' />
          </Typography>
          {amountModification.map(attribute => (
            <Grid container key={attribute.label} alignItems='baseline'>
              <Typography color='gray.dark' variant='subtitle2'>
                <FormattedMessage id={attribute.label} defaultMessage={attribute.defaultLabel} />
                :&nbsp;
              </Typography>
              <Typography color='gray.dark' variant='subtitle1'>
                {attribute.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}

      {externalReferences && (
        <Grid item xs={12} marginBottom='10px'>
          <Typography color='gray.dark' variant='subtitle2'>
            <FormattedMessage id='mainContractStory.externalReferences' defaultMessage='External references' />:
          </Typography>
          <Typography color='gray.dark' variant='subtitle1'>
            {externalReferences}
          </Typography>
        </Grid>
      )}
      {comment && (
        <Grid item xs={12} marginBottom='10px'>
          <Typography color='gray.dark' variant='subtitle2'>
            <FormattedMessage id='mainContractStory.comment' defaultMessage='Comments' />:
          </Typography>
          <Typography color='gray.dark' variant='subtitle1'>
            {comment}
          </Typography>
        </Grid>
      )}
      {actionButtonUI}
    </Grid>
  );

  return (
    <Modal open={open} onClose={onClose} className={`${classes.modal}_container`}>
      <Grid container className={`${classes.modal}_body`} justifyContent='space-between' paddingBottom={12}>
        {titleUI}
        {bodyUI}
      </Grid>
    </Modal>
  );
};

StoryDetailModal.propTypes = {
  classes: PropTypes.object,
  contract: PropTypes.object,
  contractModification: PropTypes.object,
  modificationName: PropTypes.string,
  prevStatus: PropTypes.string,
  prevFormattedAmount: PropTypes.string,
  prevTermInDays: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StoryDetailModal;
