import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useIntl } from 'react-intl';
import { Box, Divider, Grid } from '@mui/material';
import CreateAdvanceModal from './modals/createAdvanceModal';
import { useSelector } from 'react-redux';
import { projectUtil } from '../../../utils/project.utils';
import ApproveAdvanceModal from './modals/approveAdvanceModal';

export const StatusMenu = ({ classes, initialStatus, task, currency, type }) => {
  const currentUser = useSelector(state => state.authentication.user);
  const project = useSelector(state => state.projects.project);
  const { progress_logs: logs } = task || {};
  const log = logs?.pop;
  const isAPrivilegeUser = projectUtil.isAPrivilegeUser(currentUser.user_type);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const intl = useIntl();

  const statuses = {
    to_do: {
      class: `${classes.table}-advance-chip-pending`,
      label: intl.messages['labelState.pending'],
      active: true,
    },
    in_progress: {
      class: `${classes.table}-advance-chip-pending`,
      label: intl.messages['labelState.pending'],
      active: true,
    },
    done: {
      class: `${classes.table}-advance-chip-done`,
      label: intl.messages['labelState.complete'],
    },
    not_done: {
      class: `${classes.table}-advance-chip-not_done`,
      label: intl.messages['labelState.notDone'],
    },
  };
  const isMenuActive = statuses[initialStatus].active;
  return (
    <>
      <Button
        id='fade-button'
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={!isMenuActive}
        className={`${classes.table}-advance-chip ${statuses[initialStatus].class}`}
      >
        <Box display='flex' alignItems='center'>
          <Grid container marginRight={1}>
            {statuses[initialStatus].label}
          </Grid>
          {isMenuActive && (
            <>
              <Divider orientation='vertical' flexItem />
              {open ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </Box>
      </Button>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {isAPrivilegeUser && logs.length > 0 && <ApproveAdvanceModal projectId={project.id} product={task} log={log} />}
        {logs.length === 0 && <CreateAdvanceModal task={task} currency={currency} type={type} onClose={handleClose} />}
      </Menu>
    </>
  );
};
