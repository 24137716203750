import { financialProgressConstants } from '../constants/financial-progress-constants';

const createFinancialProgress = (contractId, financialProgress) => ({
  type: financialProgressConstants.CREATE_FINANCIAL_PROGRESS,
  payload: { contractId, ...financialProgress },
});

const getFinancialProgresses = (contractId, params = {}) => ({
  type: financialProgressConstants.GET_FINANCIAL_PROGRESSES,
  payload: { 
    contractId,
    ...params
  },
});

export const financialProgressActions = {
  createFinancialProgress,
  getFinancialProgresses,
};
