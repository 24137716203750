import { memo } from 'react';
import { googleConfig } from '../../settings';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerDefault = {
  width: '400px',
  height: '400px',
};

const centerDefault = {
  lat: 9.8642,
  lng: -83.9128,
};

const Map = ({
  containerStyle = { ...containerDefault },
  center = { ...centerDefault },
  zoom = 10,
  children = <></>,
}) => {
  return (
    <LoadScript googleMapsApiKey={googleConfig.apiKey}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
        {children}
      </GoogleMap>
    </LoadScript>
  );
};

export default memo(Map);
