import API from "./api.service";

export class OrgGroupService {
  static async getTypes(data = {}) {
    try {
      const response = await API().get(`clients/${data.clientId}/org_groups/types`);
      return {
        data: response.data,
        status: response.status
      };
    } catch (e) {
      console.error("e", e);
      return { status: e.status };
    }
  }

  static async getOrgGroupByType(data = {}) {
    try {
      const params = {
        org_group_type_id: data.typeId
      };
      const response = await API().get(`clients/${data.clientId}/org_groups`, { params });
      return {
        data: response.data,
        status: response.status
      };
    } catch (e) {
      console.error("e", e);
      return { status: e.status };
    }
  }
}

export default OrgGroupService;
