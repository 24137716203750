
import Storage from '../../utils/storage';
import { commonConstants } from '../constants/common-constants';
import { projectConstants } from '../constants/project-constants';

// The initial state for projects
const projectsInitialState = {
  project: null,
  projects: [],
  filters: {},
  pageSize: 24,
  page: 1,
  total: undefined,
  totalPages: 0,
  historyKey: undefined,
  loading: false,
  fail: undefined
};

export default function projects(state = projectsInitialState, action) {
  let newState;
  switch (action.type) {
    case projectConstants.GET_FILTERED_PROJECTS:
      const filters = { ...action.payload };
      newState = {
        ...state,
        fail: false,
        ...filters,
        loading: true,
      };
      storeNewState(newState);
      return newState;
    case projectConstants.GET_FILTERED_PROJECTS_SUCCESS:
      const meta = action.payload.data.meta;
      const projects = action.payload.data.projects;
      newState = {
        ...state,
        loading: false,
        fail: false,
        page: meta.current_page,
        total: meta.total_count,
        totalPages: meta.total_pages,
        projects
      };
      storeNewState(newState);
      return newState;
    case projectConstants.GET_FILTERED_PROJECTS_FAIL:
        newState = {
          ...state,
          fail: true,
          filters: {},
          loading: false,
          page: 1,
          projects: []
        };
        storeNewState(newState);
        return newState;
    case projectConstants.GET_PROJECT_DETAILS:
      newState = {
        ...projectsInitialState,
        project: null,
        loading: true,
      };
      storeNewState(newState);
      return newState;
    case projectConstants.GET_PROJECT_DETAILS_SUCCESS:
      newState = {
        ...state,
        loading: false,
        fail: false,
        project: action.payload.data
      };
      storeNewState(newState);
      return newState;
    case projectConstants.GET_PROJECT_DETAILS_FAIL:
      newState = {
        ...state,
        fail: true,
        loading: false,
        project: null
      };
      storeNewState(newState);
      return newState;
    case commonConstants.RESET:
      storeNewState(projectsInitialState);
      return projectsInitialState;
    default:
      return state;
  }
}

const storeNewState = state => {
  const storeGetFilter = {...Storage.getData('filteredProjects', null, {}), ...state };
  Storage.setData('filteredProjects', storeGetFilter);
}
