const styles = theme => ({
  card: {
    flexBasis: '100%',
    display: 'flex',
    border: 'none',
    boxShadow: 'none !important',
    margin: 'auto',

    '&_status': {
      '&_menu': {
        '& .MuiList-root': {
          padding: 0,
        },
        '& .MuiPaper-root': {
          borderRadius: '8px !important',
        },
      },
      '&_button': {
        color: `${theme.palette.secondary.contrastText} !important`,
        fontWeight: 400,
        fontSize: 13,
      },
    },
    '&-media': {
      maxWidth: 150,
      height: 150,
    },

    '&_content': {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      width: '100%',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '19px',

      '&-head': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        fontSize: 12,
      },

      '&_title': {
        '&_wrapper': {
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '&_name': {
          color: theme.palette.secondary.main,
          fontSize: 16,
          fontWeight: 700,
          textTransform: 'uppercase'
        },
      },

      '&_codes': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        color: theme.palette.gray.main,
      },

      '&_manager': {
        gap: 10,

        '&-person': {
          color: theme.palette.gray.dark,
        },
        '&-phone': {
          color: theme.palette.statusLevel.TODO,
        },
      },
    },
    '&_chip': {
      color: `${theme.palette.secondary.contrastText} !important`,
      fontWeight: 400,
      borderRadius: '100px !important',
      padding: '4px 9px !important',
      fontSize: 13,
      '&_in_negotiation': {
        backgroundColor: `${theme.palette.gray.dark} !important`,
      },
      '&_in_progress': {
        backgroundColor: `${theme.palette.statusLevel.IN_PROGRESS} !important`,
      },
      '&_finished': {
        backgroundColor: `${theme.palette.statusLevel.COMPLETE} !important`,
      },
      '&_suspended': {
        backgroundColor: `${theme.palette.statusLevel.CANCEL} !important`,
      },
    },
  },
  progress: {
    '&_label__container': {
      marginTop: 10,
      color: theme.palette.gray.dark,
      fontSize: 14,
      fontWeight: 700,
    },
    '&_linear-progress': {
      borderRadius: '0px !important',
      height: '4px !important',
      marginTop: 10,
      '&_color': {
        '&_other_dark': {
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.other.dark,
          }
        },
        '&_secondary_dark': {
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.secondary.dark,
          }
        },
        '&_primary_dark': {
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.primary.dark,
          }
        },
      },
    },
    '&_popper-box': {
      padding: "10px 12.5px",
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.15)",
      borderRadius: 4,
      '&_color': {
        '&_other_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.other.dark
          },
        },
        '&_secondary_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.secondary.dark
          },
        },
        '&_primary_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.primary.dark
          },
        },
      },
      '& .MuiChip-root': {
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
      }
    }
  },
});

export default styles;
