import { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Avatar, Card, Grid, Typography, Link } from '@mui/material';
import { projectUtil } from '../../../../../utils/project.utils';
import { contractStatuses } from '../../../../../components/headCard';
import StoryDetailModal from '../storyDetailModal';
import styles from '../../styles';

export const contractModificationsTypes = {
  addendum: { label: 'Addenda' },
  contract_update: { label: 'Contract Update' },
  contract_creation: { label: 'Contract Creation' },
};

export const contractModificationNames = {
  changeTermAmountAndCompensableDays: {
    default: 'Cambio en el plazo, el monto y días compensados del contrato'
  },
  changeAmountAndCompensableDays: {
    default: 'Cambio en el monto y días compensados del contrato'
  },
  changeTermsAndCompensableDays: {
    default: 'Cambio en el plazo y días compensados del contrato'
  },
  changeAmountAndCompensableDays: {
    default: 'Cambio en el monto y el plazo del contrato'
  },
  changeContractStatus: {
    default: 'Cambio del estado del contrato'
  },
  changeCompensableDays: {
    default: 'Cambio en los días compensados del contrato'
  },
  changeTermInDays: {
    default: 'Cambio del plazo del convenio'
  },
  changeContractAmount: {
    default: 'Cambio en el monto del convenio'
  },
}

const StoryCard = ({
  contractModification,
  classes,
  previousModification,
  prevAmountModification,
  prevTermModification,
  filtersChecked,
}) => {
  const [openStoryDetailModal, setOpenStoryDetailModal] = useState(false);

  const {
    id,
    status,
    responsible,
    effective_date,
    modification_type,
    compensable_days,
    termination_date,
    amount_cents,
    formatted_amount,
    term_in_days,
  } = contractModification || {};

  const { full_name, first_name, email } = responsible;
  const { formatted_amount: prevFormattedAmount } = prevAmountModification || {};
  const { term_in_days: prevTermInDays } = prevTermModification || {};
  const { status: prevStatus } = previousModification;
  const isACompensableDaysChange = !!compensable_days && !!termination_date;
  const isAmountChange = amount_cents > 0;
  const isTermInDaysChange = term_in_days > 0;
  const isAStatusChange = prevStatus !== status && !!prevStatus;

  const applyFilters = () => {
    const isStatusFiltered = filtersChecked.status && isAStatusChange;
    const isTermInDaysFiltered = filtersChecked.termInDays && isTermInDaysChange;
    const isAmountFiltered = filtersChecked.amountCents && isAmountChange;
    const isCompensableDaysFiltered = filtersChecked.compensableDays && isACompensableDaysChange;
    const isNotFiltered =
      !filtersChecked.status &&
      !filtersChecked.termInDays &&
      !filtersChecked.amountCents &&
      !filtersChecked.compensableDays &&
      modification_type !== 'contract_creation';

    return isStatusFiltered || isTermInDaysFiltered || isAmountFiltered || isCompensableDaysFiltered || isNotFiltered;
  };

  const isCardDisplayable = applyFilters();
  const project = useSelector(state => state.projects.project);
  const { main_contract: mainContract } = project || {};

  const StatusRow = ({ prevTile, prevValue, newTitle, newValue }) => (
    <Grid container marginBottom='10px'>
      <Grid item width='50%'>
        <Typography color='GrayText' variant='subtitle2'>
          {prevTile}
        </Typography>
        <Typography color='GrayText' variant='subtitle1'>
          {prevValue}
        </Typography>
      </Grid>
      <Grid item width='50%'>
        <Typography color='GrayText' variant='subtitle2'>
          {newTitle}
          {newTitle && ':'}
        </Typography>
        <Typography color='GrayText' variant='subtitle1'>
          {newValue}
        </Typography>
      </Grid>
    </Grid>
  );

  const modificationsUI = (
    <Grid container width='40%' justifyContent='space-between'>
      {isAStatusChange && (
        <StatusRow
          prevTile={<FormattedMessage id='mainContractStory.previousStatus' defaultMessage='Estado anterior' />}
          prevValue={contractStatuses[prevStatus]?.label}
          newTitle={<FormattedMessage id='mainContractStory.newStatus' defaultMessage='Nuevo estado' />}
          newValue={contractStatuses[status]?.label}
        />
      )}
      {isACompensableDaysChange && (
        <StatusRow
          prevTile={<FormattedMessage id='mainContractLabel.compensableDays' defaultMessage='Dias compensados' />}
          prevValue={compensable_days}
          newTitle={
            !isTermInDaysChange && (
              <FormattedMessage id='mainContractStory.newTerminationDate' defaultMessage='Nueva fecha de terminacion' />
            )
          }
          newValue={
            !isTermInDaysChange && <FormattedDate value={termination_date} month='short' year='numeric' day='2-digit' />
          }
        />
      )}
      {isAmountChange && (
        <StatusRow
          prevTile={<FormattedMessage id='mainContractStory.previousAmount' defaultMessage='Monto anterior' />}
          prevValue={prevFormattedAmount || mainContract?.formatted_original_amount}
          newTitle={<FormattedMessage id='mainContractStory.newAmount' defaultMessage='Nuevo monto' />}
          newValue={formatted_amount}
        />
      )}
      {isTermInDaysChange && (
        <>
          <StatusRow
            prevTile={<FormattedMessage id='mainContractLabel.compensableDays' defaultMessage='Dias agregados' />}
            prevValue={term_in_days - prevTermInDays || term_in_days - mainContract?.original_term_in_days}
            newTitle={<FormattedMessage id='mainContractStory.previousTerm' defaultMessage='Plazo anterior' />}
            newValue={prevTermInDays || mainContract?.original_term_in_days}
          />
          <StatusRow
            prevTile={
              <FormattedMessage id='mainContractStory.newTerminationDate' defaultMessage='Nueva fecha de terminacion' />
            }
            prevValue={<FormattedDate value={termination_date} month='short' year='numeric' day='2-digit' />}
            newTitle={<FormattedMessage id='mainContractStory.newTerm' defaultMessage='Nuevo plazo' />}
            newValue={term_in_days}
          />
        </>
      )}
    </Grid>
  );

  const avatarUI = (
    <Grid container width='60%'>
      <Grid item marginLeft='auto'>
        <Grid container marginTop={1} alignItems='center'>
          <Grid item className={`${classes.story}_avatar`} marginLeft='auto'>
            <Avatar sx={{ bgcolor: projectUtil.getRandomColorByString(full_name) }}>
              {projectUtil.renderAvatarInitials(responsible)}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography color='GrayText' variant='subtitle1'>
              {first_name ? full_name : email}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item marginLeft='auto'>
            <Typography color='GrayText' variant='subtitle2'>
              <FormattedDate value={effective_date} month='short' year='numeric' day='2-digit' />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const storyCardContentUI = (
    <>
      {modificationsUI}
      {avatarUI}
    </>
  );
  
  const modificationName = (() => {
    if (isTermInDaysChange && isACompensableDaysChange && isAmountChange)
      return 'changeTermAmountAndCompensableDays'
    if (isACompensableDaysChange && isAmountChange && !isTermInDaysChange)
      return 'changeAmountAndCompensableDays'
    if (isACompensableDaysChange && isTermInDaysChange && !isAmountChange)
      return 'changeTermsAndCompensableDays'
    if (isAmountChange && isTermInDaysChange && !isACompensableDaysChange)
      return 'changeAmountAndCompensableDays'
    if (isAStatusChange && !isACompensableDaysChange && !isAmountChange && !isTermInDaysChange)
      return 'changeContractStatus'
    if (isACompensableDaysChange && !isAStatusChange && !isAmountChange && !isTermInDaysChange)
      return 'changeCompensableDays'
    if (isTermInDaysChange && !isAStatusChange && !isAmountChange && !isACompensableDaysChange)
      return 'changeTermInDays'
    if (isAmountChange && !isAStatusChange && !isTermInDaysChange && !isACompensableDaysChange)
      return 'changeContractAmount'
  })()

  const titleUI = modificationName && (
    <Typography variant='subtitle1' color='GrayText'>
      <FormattedMessage
        id={`mainContractStory.${modificationName}`}
        defaultMessage={contractModificationNames[modificationName]?.default}
      />
    </Typography>
  );

  const handleStoryDetailModal = () => {
    setOpenStoryDetailModal(true);
  };

  return (
    <>
      {isCardDisplayable && (
        <Grid item key={{ id }} width='100%'>
          <Card variant='outlined' className={`${classes.story}_container`}>
            <Grid container padding='10px' minHeight='94px'>
              <Grid container> {titleUI}</Grid>
              <Grid container>
                <Typography color='GrayText' marginBottom='10px'>
                  {contractModificationsTypes[modification_type].label}
                </Typography>
              </Grid>
              <Grid container>{storyCardContentUI}</Grid>
              <Grid container marginTop={1.5}>
                <Grid item marginLeft='auto'>
                  <Link
                    component='button'
                    variant='body2'
                    onClick={handleStoryDetailModal}
                  >
                    <FormattedMessage id='mainContractStory.showDetails' defaultMessage='Ver detalle' />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <StoryDetailModal
            classes={classes}
            contract={mainContract}
            contractModification={contractModification}
            modificationName={modificationName}
            prevFormattedAmount={prevFormattedAmount}
            prevStatus={prevStatus}
            prevTermInDays={prevTermInDays}
            open={openStoryDetailModal}
            onClose={() => setOpenStoryDetailModal(false)}
          />
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(StoryCard);
