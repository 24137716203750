/**
 * Index file for reducers
 * Add the reducers files here
 */
import { combineReducers } from 'redux';

import authentication from './auth-reducer';
import projects from './project-reducer';
import orgGroups from './org-group-reducer';
import filters from './filter-reducer';
import tasks from './task-reducer';
import contracts from './contract-reducer';
import contractors from './contractor-reducer';
import contractModifications from './contract-modification-reducer';
import physicalProgress from './physical-progress-reducer';
import financialProgress from './financial-progress-reducer';
import currencies from './currency-reducer';

const rootReducer = combineReducers({
  authentication,
  projects,
  orgGroups,
  filters,
  tasks,
  contracts,
  contractors,
  contractModifications,
  physicalProgress,
  financialProgress,
  currencies,
});

export default rootReducer;
