const styles = theme => ({
  modal: {
    '&_container': {
      display: 'flex',
      padding: 15,
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&_body': {
      backgroundColor: theme.palette.background.paper,
      paddingBottom: '20px !important',
      color: theme.palette.gray.dark,
      position: 'relative',
    },
  },
  table: {
    margin: '1em 0',
    borderTop: `1px solid ${theme.palette.other.dark}`,

    '&_color': {
      '&_secondary': {
        borderTopColor: theme.palette.secondary.light,
      },
    },
  },
  progress: {
    '&_row': {
      '& .MuiChip-root': {
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
      },

      '&_color': {
        '&_other_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.other.dark,
          },
        },
        '&_secondary_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
        '&_primary_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
    },
  },
  linear_progress: {
    height: '6px !important',

    '&_color': {
      '&_other_dark': {
        '& .MuiLinearProgress-bar': {
          backgroundColor: theme.palette.other.dark,
        },
      },
      '&_secondary_dark': {
        '& .MuiLinearProgress-bar': {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
      '&_primary_dark': {
        '& .MuiLinearProgress-bar': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },

    '&_value': {
      fontWeight: 700,
    },

    '&_last-modification': {
      fontSize: '.9em',
      textAlign: 'right',
    },
  },
  button: {
    textTransform: 'uppercase !important',
  },
});

export default styles;
