import {
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Brightness1 from '@mui/icons-material/Brightness1';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import styles from './styles';
import { isEmpty } from 'lodash';
import { FormattedDate, FormattedMessage } from 'react-intl';
import SecondaryContracts from './secondaryContracts';
import { projectUtil } from '../../../utils/project.utils';
import EditModal from './editModal';
import { useEffect } from 'react';
import { contractActions } from '../../../redux/actions/contract-actions';
import ContractModificationStory from './contractModificationStory';
import ManageContractModal from './manageContractModal';
import { contractorActions } from '../../../redux/actions/contractor-actions';

export const HeaderInfo = ({ classes }) => (
  <>
    <Grid container>
      <Typography variant='body2'>
        <FormattedMessage
          id='mainContract.requiredAsteriskInfo'
          defaultMessage='All the inputs with an asterisk are required'
        />{' '}
        <Typography component='span' color='red'>
          *
        </Typography>
        .
      </Typography>
    </Grid>
    <Divider className={`${classes.divider}_modal`} />
  </>
);
const ContractInfoValue = ({ value, isADate = false }) => {
  if (!!value && !isADate) {
    return <Typography variant='subtitle1'>{value}</Typography>;
  }
  if (value && isADate) {
    return (
      <Typography variant='subtitle1'>
        <FormattedDate value={value} month='short' year='numeric' day='2-digit' />
      </Typography>
    );
  }
  return <>-</>;
};

const ContractualInfo = ({ classes, isMainContract = true }) => {
  const project = useSelector(state => state.projects.project);
  const contractType = isMainContract ? "Agreement" : "Contract" 
  const [openModifyContractModal, setOpenModifyContractModal] = useState(false);
  const [openManageContractModal, setOpenManageContractModal] = useState(false);
  const [isStoryClicked, setIsStoryClicked] = useState(false);
  const [addMainContract, setAddMainContract] = useState(false);
  const [updateContract, setUpdateContract] = useState(false);
  const contractLoading = useSelector(state => state.projects.loading);
  const currentContract = useSelector(state => state.contracts.contract);
  const contract = isEmpty(currentContract) ? project?.main_contract : currentContract;
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user) || {};
  const { client_id: clientId } = currentUser || {};
  const isAPrivilegeUser = projectUtil.isAPrivilegeUser(currentUser.user_type);

  const {
    spent_days: spentDays,
    compensable_days: compensableDays,
    formatted_current_amount: currentContractAmount,
    currency,
    current_amount: currentAmount,
    signing_date: signingDate,
    start_date: startDate,
    current_termination_date: currentTerminationDate,
    current_term_in_days: currentTermInDays,
    contractor,
    contract_number: contractNumber,
    financial_progress_mode: financialProgressMode,
    financial_progress: financialProgress,
    reference_number1: referenceNumber1,
    reference_number2: referenceNumber2,
  } = contract || {};
  const { name: contractorName } = contractor || {};

  const calculateCircularValue = () => {
    const currentPercentage = projectUtil.calculateExecutionPeriod(
      spentDays || 0,
      currentTermInDays || 0,
      compensableDays || 0
    );

    return ((!!currentPercentage ? currentPercentage : 0) * 75) / 100;
  };

  useEffect(() => {
    if (clientId) {
      dispatch(contractorActions.getContractors(clientId));
    }
  }, [clientId]);

  useEffect(() => {
    if (project) {
      dispatch(contractActions.getProjectContracts(project.id));
    }
  }, [project, currentContract]);

  const detailNumbersUI = (
    <Grid container className={`${classes.card}_spent-days_container`}>
      <Grid container justifyContent='center' className={`${classes.card}_spent-days`}>
        {spentDays || 0}
      </Grid>
      <Grid container justifyContent='center'>
        <LinearProgress color='secondary' variant='determinate' value={100} className={`${classes.card}_separator`} />
      </Grid>
      <Grid container justifyContent='center'>
        <Grid item className={`${classes.card}_spent-days_amount`}>
          {currentTermInDays || 0}
        </Grid>
      </Grid>
    </Grid>
  );

  const circularWidgetUI = (
    <Grid item xs={5}>
      <Grid container justifyContent='center' position='relative'>
        <CircularProgress
          variant='determinate'
          value={75}
          className={classes.circularProgressBG}
          size={120}
          thickness={2}
        />
        <CircularProgress
          variant='determinate'
          value={calculateCircularValue()}
          color='secondary'
          className={classes.circularProgressFill}
          size={120}
          thickness={2}
        />
        {detailNumbersUI}
      </Grid>
    </Grid>
  );

  const handleModifyContract = () => {
    setOpenModifyContractModal(true);
  };

  const openUpdateContract = () => {
    setOpenManageContractModal(true);
    setAddMainContract(true);
    setUpdateContract(true);
  };

  const callToActionUI = (
    <>
      <Button
        variant='outlined'
        color='info'
        className={classes.button}
        disabled={!isAPrivilegeUser}
        onClick={openUpdateContract}
      >
        <FormattedMessage id='projectInfo.contractualInfo.edit' defaultMessage='EDIT' />
      </Button>
      <Button
        color='primary'
        variant='contained'
        className={classes.button}
        onClick={handleModifyContract}
        disabled={!isAPrivilegeUser}
      >
        <FormattedMessage id={`mainContractLabel.modify${contractType}`} defaultMessage='MODIFICAR CONVENIO' />
      </Button>

      {!isEmpty(project) && (
        <EditModal
          classes={classes}
          projectId={project.id}
          contract={contract}
          setOpenModifyContractModal={setOpenModifyContractModal}
          openModifyContractModal={openModifyContractModal}
        />
      )}
    </>
  );

  const titleUI = (
    <Grid container className={classes.header}>
      <Grid item className={`${classes.header}_title`}>
        <FormattedMessage id={`main${contractType}Label.mainContract`} defaultMessage='Convenio' />
      </Grid>
      <Grid item>{!isEmpty(contract) && callToActionUI}</Grid>
    </Grid>
  );

  const ListIcon = () => (
    <ListItemIcon>
      <Brightness1 fontSize='inherit' />
    </ListItemIcon>
  );

  const textWidgetUI = (
    <Grid item xs={7}>
      <Grid className={`${classes.card}_list-title`}>
        <FormattedMessage id={`main${contractType}Label.currentPeriodTermInDays`} defaultMessage='Plazo del contrato en días' />
      </Grid>
      <List>
        <ListItem className={`${classes.card}_list-item`}>
          <ListIcon />
          <FormattedMessage id='mainContractLabel.spentDays' defaultMessage='Plazo transcurrido en días' />
        </ListItem>
        <ListItem className={`${classes.card}_list-item ${classes.card}_list-item_last`}>
          <ListIcon />
          <FormattedMessage id='mainContractLabel.currentTermInDays' defaultMessage=' Duración del contrato en días' />
        </ListItem>
      </List>
    </Grid>
  );

  const DaysWidgetUI = (
    <Grid item xs>
      <Card className={`${classes.card}`}>
        <Grid container className={`${classes.card}_container`} minWidth='300px'>
          {circularWidgetUI}
          {textWidgetUI}
        </Grid>
      </Card>
    </Grid>
  );

  const getFinancialProgressExecutedAmount = () => {
    if (financialProgressMode === 'amount') {
      return financialProgress / 100;
    }
    return (currentAmount * financialProgress) / 100;
  };

  const calculateFinancialProgressPercentage = () => {
    const calculatedValue = Math.round((financialProgress / currentAmount) * 100);

    if (financialProgressMode === 'amount') {
      return calculatedValue >= 100 ? 100 : calculatedValue;
    }

    return financialProgress || 0;
  };

  const contractAmountUI = (
    <Card className={classes.card}>
      <Grid container className={`${classes.card}_list-title`}>
        <FormattedMessage id={`main${contractType}Label.currentAgreementAmount`} defaultMessage='Monto del Convenio' />
      </Grid>
      <Grid container>
        <Grid container className={`${classes.card}_amount`}>
          {currentContractAmount}
        </Grid>
        <Grid container>
          <LinearProgress
            value={calculateFinancialProgressPercentage()}
            variant='determinate'
            className={`${classes.card}_linear-progress`}
          />
        </Grid>
      </Grid>
      <Grid container className={`${classes.card}_text`}>
        <FormattedMessage id='mainContractLabel.executedAmount' defaultMessage='Monto ejecutado:' />
      </Grid>
      <Grid container className={`${classes.card}_text`}>
        {projectUtil.numberToCurrency(getFinancialProgressExecutedAmount(), currency)}
      </Grid>
    </Card>
  );

  const contractDetailsPart1UI = (
    <TableContainer>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow className={`${classes.table}_header`}>
            <TableCell align='left'>
              <FormattedMessage id={`main${contractType}Label.signingDate`} defaultMessage='Fecha de aprobación del convenio:' />
            </TableCell>
            <TableCell align='left'>
              <FormattedMessage
                id={`mainContractLabel.startDate${contractType}`}
                defaultMessage='Fecha de firma del convenio'
              />
              :
            </TableCell>
            <TableCell align='left'>
              <FormattedMessage
                id={`mainContractLabel.currentTerminationDate${contractType}`}
                defaultMessage='Fecha del último desembolso del convenio'
              />
              :
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align='left'>
              <ContractInfoValue value={signingDate} isADate />
            </TableCell>
            <TableCell align='left'>
              <ContractInfoValue value={startDate} isADate />
            </TableCell>
            <TableCell align='left'>
              <ContractInfoValue value={currentTerminationDate} isADate />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const contractDetailsPart2UI = (
    <TableContainer>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow className={`${classes.table}_header`}>
            <TableCell align='left'>
              <FormattedMessage id={`mainContractLabel.no${contractType}`} defaultMessage='No. Convenio:' />:
            </TableCell>
            <TableCell align='left'>
              <FormattedMessage id='mainContractLabel.contractor' defaultMessage='Organismo ejecutor' />:
            </TableCell>
            <TableCell align='left'>
              <FormattedMessage
                id={`main${contractType}Label.noReference`}
                defaultMessage='No. de referencia asociados al convenio:'
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align='left'>
              <ContractInfoValue value={contractNumber} />
            </TableCell>
            <TableCell align='left'>
              <ContractInfoValue value={contractorName} />
            </TableCell>
            <TableCell align='left'>
              <Grid container>
                No. Ref. 1: &nbsp;
                <Typography variant='button'>
                  <ContractInfoValue value={referenceNumber1} />
                </Typography>
              </Grid>
              <Grid container>
                No. Ref. 2: &nbsp;
                <Typography variant='button'>
                  <ContractInfoValue value={referenceNumber2} />
                </Typography>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const handleClickHistory = () => {
    setIsStoryClicked(!isStoryClicked);
  };

  const storyUI = (
    <Grid container marginBottom={5} marginTop={1}>
      <Card className={`${classes.card} ${classes.card}_story`}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          onClick={handleClickHistory}
          className={`${classes.card}_story-control`}
        >
          <Grid item>
            <FormattedMessage id='mainContractLabel.story' defaultMessage='Historial' />
          </Grid>
          <Grid item marginBottom='0px'>
            {!isStoryClicked && <ExpandMore />}
            {isStoryClicked && <ExpandLess />}
          </Grid>
        </Grid>
        {isStoryClicked && (
          <>
            <Grid paddingX={2} marginTop={1}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid container margin={2.5}>
              <ContractModificationStory classes={classes} contractId={contract.id} />
            </Grid>
          </>
        )}
      </Card>
    </Grid>
  );

  const handleAddContract = e => {
    setOpenManageContractModal(true);
    setAddMainContract(e.target.name === 'add_main');
  };

  const secondaryContractsUI = (
    <>
      <Grid container className={classes.header}>
        <Grid item className={`${classes.header}_title`}>
          <FormattedMessage id='mainContractLabel.secondaryContracts' defaultMessage='Contratos' />
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            color='info'
            className={classes.button}
            onClick={handleAddContract}
            name='add_secondary'
            disabled={!isAPrivilegeUser}
          >
            <FormattedMessage id='mainContractLabel.addContract' defaultMessage='AGREGAR CONTRATO' />
          </Button>
        </Grid>
      </Grid>
      <SecondaryContracts classes={classes} />
    </>
  );

  const contractInfoUI = (
    <>
      <Grid container spacing={3}>
        {DaysWidgetUI}
        <Grid item xs>
          {contractAmountUI}
        </Grid>
      </Grid>
      {contractDetailsPart1UI}
      {contractDetailsPart2UI}
      {storyUI}
    </>
  );

  const emptyStateUI = (
    <Grid
      container
      alignItems='center'
      spacing={1}
      flexDirection='column'
      marginBottom={6}
      marginTop={4}
      className={classes.simpleText}
    >
      <Grid container marginBottom={2} alignItems='center' flexDirection='column' spacing={1}>
        <Typography variant='subtitle1'>
          <FormattedMessage
            id={`main${contractType}Label.addAgreementMessage`}
            defaultMessage=' Aún no ha sido agregado el convenio de este proyecto.'
          />
        </Typography>
        <Typography variant='subtitle2'>
          <FormattedMessage
            id='mainContractLabel.addAgreementMessageDetail'
            defaultMessage=' Si deseas hacerlo ahora, has click en el botón..'
          />
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant='contained'
          color='info'
          className={classes.button}
          onClick={handleAddContract}
          name='add_main'
          disabled={!isAPrivilegeUser}
        >
          <FormattedMessage id={`mainContractLabel.add${contractType}`} defaultMessage='AGREGAR CONVENIO' />
        </Button>
      </Grid>
    </Grid>
  );

  const handleContractSubmit = params => {
    dispatch(contractActions.createProjectContract(project.id, params));
  };

  const handleCloseUpdateModal = () => {
    setOpenManageContractModal(false);
    setUpdateContract(false);
  };

  const handleUpdateContractSubmit = params => {
    dispatch(contractActions.updateProjectContract(project.id, contract.id, params));
  };

  const contractModalUI = (
    <>
      {!updateContract && (
        <ManageContractModal
          classes={classes}
          openManageContractModal={openManageContractModal}
          isMainContract={addMainContract}
          onSubmit={handleContractSubmit}
          onClose={() => setOpenManageContractModal(false)}
        />
      )}
      {updateContract && (
        <ManageContractModal
          classes={classes}
          openManageContractModal={openManageContractModal}
          isMainContract={addMainContract}
          onSubmit={handleUpdateContractSubmit}
          initialState={contract}
          isAnUpdate
          onClose={handleCloseUpdateModal}
        />
      )}
    </>
  );

  return (
    <>
      {contractLoading && <CircularProgress color='inherit' size={70} />}
      {!contractLoading && (
        <Grid container>
          {titleUI}
          {!isEmpty(contract) && contractInfoUI}
          {isEmpty(contract) && emptyStateUI}

          {isMainContract && (
            <>
              <Divider className={classes.divider} />
              {secondaryContractsUI}
            </>
          )}
          {contractModalUI}
        </Grid>
      )}
    </>
  );
};
export default withStyles(styles)(ContractualInfo);
