import styles from '../styles';

import { withStyles } from '@mui/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import Dropdown from '../../../../components/dropdown';
import CurrencyInput from '../../../../components/currencyField';
import DateInput from '../../../../components/dateField';
import { currencyActions } from '../../../../redux/actions/currency-actions';
import { useDispatch, useSelector } from 'react-redux';
import { projectUtil } from '../../../../utils/project.utils';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { HeaderInfo } from '..';
import { ParentSelector as ProductSelector } from '../../products/modals/ParentSelector';

const ManageContractModal = ({
  classes,
  openManageContractModal,
  isMainContract,
  onSubmit,
  initialState = {},
  isAnUpdate = false,
  onClose,
}) => {
  const [signingDate, setSigningDate] = useState(initialState.signing_date || null);
  const [startDate, setStartDate] = useState(initialState.start_date || null);
  const [termInDays, setTermInDays] = useState(initialState.current_term_in_days);
  const [contractor, setContractor] = useState(null);
  const productLevel = useSelector(state => state.tasks.taskLevels.find(level => level.name === 'product'));
  const [productId, setProductId] = useState(null);
  const [contractNumber, setContractNumber] = useState(initialState.contract_number);
  const [currency, setCurrency] = useState(null);
  const [financialProgressMode, setFinancialProgressMode] = useState(
    initialState.financial_progress_mode || 'percentage'
  );
  const [object, setObject] = useState(initialState.contract_object);
  const [contractAmount, setContractAmount] = useState(
    initialState.current_amount_cents && initialState.current_amount_cents / 100
  );
  const [terminationDate, setTerminationDate] = useState(initialState.current_termination_date || null);
  const [reference1, setReference1] = useState(initialState.reference_number1);
  const [reference2, setReference2] = useState(initialState.reference_number2);
  const contractors = useSelector(state => state.contractors.contractors);
  const currencies = useSelector(state => state.currencies.currencies);
  const currentUser = useSelector(state => state.authentication.user);
  const dispatch = useDispatch();



  useEffect(() => {
    if (openManageContractModal && currencies.length === 0) {
      dispatch(currencyActions.getCurrencies());
    }
  }, [openManageContractModal]);

  useEffect(() => {
    if (!isEmpty(initialState) && !!initialState.currency && currencies.length > 0) {
      const initialCurrency = currencies.find(currency => currency.iso_code === initialState.currency);
      setCurrency(initialCurrency);
    }
  }, [currencies]);

  useEffect(() => {
    if (!isEmpty(initialState) && !!initialState.contractor && contractors.length > 0) {
      const initialContractor = contractors.find(contractor => contractor.name === initialState.contractor.name);
      setContractor(initialContractor);
    }
  }, [contractors]);

  const addUpdateContractParams = params => {
    const effectiveDate = new Date(Date.now()).toISOString();
    if (isAnUpdate) {
      return {
        ...params,
        ...{
          current_amount: projectUtil.removeDecimalsFromAmount(contractAmount),
          current_term_in_days: termInDays,
          current_termination_date: projectUtil.convertToIsoString(terminationDate),
        },
      };
    }

    return {
      ...params,
      ...{
        effective_date: effectiveDate,
        responsible_id: currentUser.id,
      },
    };
  };

  const handleSubmit = () => {
    let params = {
      signing_date: projectUtil.convertToIsoString(signingDate),
      contract_number: contractNumber,
      main_contract: isMainContract,
      original_term_in_days: termInDays,
      start_date: projectUtil.convertToIsoString(startDate),
      original_termination_date: projectUtil.convertToIsoString(terminationDate),
      reference_number1: reference1,
      reference_number2: reference2,
      contractor_id: contractor?.id,
      original_amount: projectUtil.removeDecimalsFromAmount(contractAmount),
      currency: currency?.iso_code,
      contract_object: object,
      product_id: productId,
      financial_progress_mode: financialProgressMode,
      progresses_from_project_task_levels: true
    };
    onSubmit(addUpdateContractParams(params));
    onClose();
  };

  const areInputsValid = () => {
    return (
      !!signingDate && !!startDate && !!terminationDate && !!termInDays && !!currency && !!contractAmount && !!object
    );
  };

  const actionButtonUI = (
    <Grid container className={`${classes.modal}_body_button_container`} marginTop={2}>
      <Button
        variant='contained'
        color='info'
        className={classes.button}
        disabled={!areInputsValid()}
        onClick={handleSubmit}
      >
        {isMainContract ? (
          <FormattedMessage id='mainContractLabel.addAgreement' defaultMessage='Agregar Convenio' />
        ) : (
          <FormattedMessage id='mainContractLabel.addContract' defaultMessage='Agregar Contrato' />
        )}
      </Button>
      <Button variant='outlined' color='info' className={classes.button} onClick={onClose}>
        <FormattedMessage id='projectInfo.product.cancel' defaultMessage='Cancelar' />
      </Button>
    </Grid>
  );

  const titleUI = (
    <>
      <Grid container>
        <Grid item marginLeft='auto'>
          <IconButton color='primary' aria-label='add an alarm' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between' marginBottom={1}>
        <Grid container>
          <Grid item>
            <Typography variant='h4'>
              <FormattedMessage
                id={`mainContractLabel.${isAnUpdate ? 'update' : 'add'}${isMainContract ? 'Agreement' : 'Contract'}`}
                defaultMessage={`${isAnUpdate ? 'Actualizar' : 'Agregar'} ${isMainContract ? 'Convenio' : 'Contrato'}`}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const calculateTerminationDate = newDate => {
    if (!!startDate && !!newDate) {
      setTermInDays(projectUtil.getDaysDifferencesFromDates(startDate, newDate));
    }
  };

  const handleTerminationDateChange = newDate => {
    if (newDate > startDate) {
      setTerminationDate(newDate);
      calculateTerminationDate(newDate);
    }
  };

  const handleTermInDaysChange = e => {
    const currentIInputTermInDays = e.target.value;
    setTermInDays(currentIInputTermInDays);
    if (currentIInputTermInDays && startDate) {
      setTerminationDate(projectUtil.addDaysToDate(startDate, currentIInputTermInDays));
    }
  };

  const objectInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <TextField
        value={object}
        onChange={e => setObject(e.target.value)}
        label={<FormattedMessage id='mainContractLabel.object' defaultMessage='Objeto' />}
        size='small'
        variant='outlined'
        multiline
        rows={3}
        required
      />
    </Grid>
  );

  const CurrencyInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <Grid container justifyContent='space-between'>
        <Grid item xs={2.8} paddingRight={2}>
          <Dropdown
            required
            labelField='iso_code'
            idName='mainContractLabel.currency'
            label={<FormattedMessage id='mainContractLabel.currency' defaultMessage='Moneda' />}
            listItems={currencies}
            handleOnChange={setCurrency}
            hookValue={currency}
          />
        </Grid>
        <Grid item xs={9}>
          <CurrencyInput
            label={
              isMainContract ? (
                <FormattedMessage id='mainContractLabel.contractAmountAgreement' defaultMessage='Monto del convenio' />
              ) : (
                <FormattedMessage id='mainContractLabel.contractAmount' defaultMessage='Monto del contrato' />
              )
            }
            onChange={e => setContractAmount(e.target.value)}
            value={contractAmount}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const financialProgressModeInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <FormControl>
        <FormLabel id='demo-controlled-radio-buttons-group'>
          <Typography color='gray.dark'>
            <FormattedMessage
              id='mainContractLabel.financialProgressModeLabel'
              defaultMessage='¿Cómo se actualizaran los avance financiero?'
            />
            &nbsp;
            <Typography component='span' color='red'>
              *
            </Typography>
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby='progress-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={financialProgressMode}
          onChange={e => setFinancialProgressMode(e.target.value)}
          className={`${classes.modal}_input_radio`}
        >
          <FormControlLabel
            value='amount'
            control={<Radio />}
            label={<FormattedMessage id='mainContractStory.amount' defaultMessage='Monto' />}
          />
          <FormControlLabel
            value='percentage'
            control={<Radio />}
            label={<FormattedMessage id='mainContractStory.percentage' defaultMessage='Porcentaje' />}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  const inputsUI = (
    <>
      {objectInputUI}
      <Grid container className={`${classes.modal}_input_container`}>
        <DateInput
          value={signingDate}
          label={
            isMainContract ? (
              <FormattedMessage id='projectInfo.signatureAgreement' defaultMessage='Fecha de aprobación del convenio' />
            ) : (
              <FormattedMessage id='projectInfo.signature' defaultMessage='Fecha firma contrato' />
            )
          }
          onChange={setSigningDate}
          classes={classes}
        />
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <DateInput
          value={startDate}
          label={
            isMainContract ? (
              <FormattedMessage
                id='mainContractLabel.startDateAgreement'
                defaultMessage='Fecha de firma del convenio'
              />
            ) : (
              <FormattedMessage id='mainContractLabel.startDate' defaultMessage='Fecha de inicio del contrato' />
            )
          }
          onChange={setStartDate}
          classes={classes}
        />
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <DateInput
          value={terminationDate}
          label={
            isMainContract ? (
              <FormattedMessage
                id='mainContractLabel.currentTerminationDateAgreement'
                defaultMessage='Fecha del último desembolso del convenio'
              />
            ) : (
              <FormattedMessage
                id='mainContractLabel.currentTerminationDate'
                defaultMessage='Current Termination Date'
              />
            )
          }
          onChange={handleTerminationDateChange}
          classes={classes}
        />
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <TextField
          fullWidth
          label={
            isMainContract ? (
              <FormattedMessage
                id='mainContractLabel.currentTermInDaysAgreement'
                defaultMessage='Duración del convenio en días'
              />
            ) : (
              <FormattedMessage
                id='mainContractLabel.currentTermInDays'
                defaultMessage='Duración del contrato en días'
              />
            )
          }
          onChange={handleTermInDaysChange}
          size='small'
          value={termInDays}
          InputLabelProps={{ shrink: !!termInDays }}
          type='number'
          required
        />
      </Grid>
      {CurrencyInputUI}
      {financialProgressModeInputUI}
      <Grid container className={`${classes.modal}_input_container`}>
        <Dropdown
          labelField='name'
          idName='mainContractLabel.contractor'
          label={<FormattedMessage id='mainContractLabel.contractor' defaultMessage='Organismo Ejecutor' />}
          listItems={contractors}
          handleOnChange={setContractor}
          hookValue={contractor}
        />
      </Grid>
      {!isMainContract && (
        <Grid container className={`${classes.modal}_input_container`}>
          <FormControl fullWidth>
            <ProductSelector
              parentLevel={productLevel}
              parentId={productId}
              setParentId={setProductId}
              required={false}
            />
          </FormControl>
        </Grid>
      )}
      <Grid container className={`${classes.modal}_input_container`}>
        <TextField
          fullWidth
          label={
            isMainContract ? (
              <FormattedMessage id='mainContractLabel.noAgreement' defaultMessage='No. Convenio' />
            ) : (
              <FormattedMessage id='mainContractLabel.noContract' defaultMessage='No. Contrato' />
            )
          }
          onChange={e => setContractNumber(e.target.value)}
          size='small'
          value={contractNumber}
        />
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <TextField
          fullWidth
          label={<FormattedMessage id='mainContractLabel.referenceNumber1' defaultMessage='Numero de Referencia 1' />}
          onChange={e => setReference1(e.target.value)}
          size='small'
          value={reference1}
        />
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <TextField
          fullWidth
          label={<FormattedMessage id='mainContractLabel.referenceNumber2' defaultMessage='Numero de Referencia 2' />}
          onChange={e => setReference2(e.target.value)}
          size='small'
          value={reference2}
        />
      </Grid>
    </>
  );

  const bodyUI = (
    <Grid container>
      {inputsUI}
      {actionButtonUI}
    </Grid>
  );

  return (
    <Modal open={openManageContractModal} onClose={onClose} className={`${classes.modal}_container`}>
      <Grid container className={`${classes.modal}_body`} justifyContent='space-between' paddingBottom={12}>
        {titleUI}
        <Grid container>
          <HeaderInfo classes={classes} />
        </Grid>
        {bodyUI}
      </Grid>
    </Modal>
  );
};

ManageContractModal.propTypes = {
  classes: PropTypes.object,
  openManageContractModal: PropTypes.bool,
  isMainContract: PropTypes.bool,
  isAnUpdate: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  initialState: PropTypes.object,
};

export default withStyles(styles)(ManageContractModal);
