import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

// Styles
import styles from './styles';

const Footer = ({ classes }) => {
  return (
    <Paper className={classes.root} square={true}>
      <Typography variant="body2" className={`${classes.root}__text`}>
        © Copyright {new Date().getFullYear()}
      </Typography>
    </Paper>
  );
}

Footer.propTypes = {
    classes: PropTypes.object
  };

// Redux connection
export default withStyles(styles)(Footer);
