import { useIntl } from 'react-intl';
import { ProductRow } from './ProductRow';
import { CollapsibleRow } from './CollapsibleRow';
import { TableRowParent } from './TableRowParent';

export const DeliverableRow = ({
  classes,
  result,
  currency,
  responsible,
  defaultTask,
  projectId,
  type,
  parentLevel,
  level,
  siblings,
  products,
}) => {
  const intl = useIntl();

  return (
    <>
      <CollapsibleRow
        ParentComponent={props => (
          <TableRowParent
            classes={classes}
            name={result.name}
            siblings={siblings}
            product={result}
            description={result.description}
            parentId={result.project_task_level_id}
            progress={result.children_progress}
            type='result'
            {...props}
          />
        )}
      >
        {result?.children.map((product, idx) => (
          <CollapsibleRow
            key={idx}
            ParentComponent={props => (
              <TableRowParent
                classes={classes}
                name={product.name}
                description={product.description}
                siblings={result?.children}
                product={product}
                parentId={product.project_task_level_id}
                progress={product.children_progress}
                type='product'
                {...props}
              />
            )}
          >
            {product?.contracts?.map((contract, idx) => (
              <CollapsibleRow
                key={idx}
                ParentComponent={props => (
                  <TableRowParent
                    classes={classes}
                    name={`${intl.messages['mainContractLabel.contract']} ${idx + 1}:  ${contract.description}`}
                    siblings={product?.contracts}
                    product={contract}
                    parentId={product.id}
                    progress={contract.children_progress}
                    type='contract'
                    {...props}
                  />
                )}
              >
                {contract?.deliverables?.map((product, index) => (
                  <ProductRow
                    key={index}
                    product={product}
                    classes={classes}
                    index={index}
                    currency={currency}
                    responsible={responsible}
                    defaultTask={defaultTask}
                    type={intl.messages[`projectInfo.${type.toLowerCase()}`]}
                    projectId={projectId}
                    products={products}
                    level={level}
                    siblings={contract?.deliverables}
                    parentLevel={parentLevel}
                  />
                ))}
              </CollapsibleRow>
            ))}
          </CollapsibleRow>
        ))}
      </CollapsibleRow>
    </>
  );
};

export default DeliverableRow;
