export const contractConstants = {
  SET_NEW_PROJECT_CONTRACT: 'SET_NEW_PROJECT_CONTRACT',
  SET_NEW_PROJECT_CONTRACT_SUCCESS: 'SET_NEW_PROJECT_CONTRACT_SUCCESS',
  SET_NEW_PROJECT_CONTRACT_FAIL: 'SET_NEW_PROJECT_CONTRACT_FAIL',

  GET_PROJECT_CONTRACT_LIST: 'GET_PROJECT_CONTRACT_LIST',
  GET_PROJECT_CONTRACT_LIST_SUCCESS: 'GET_PROJECT_CONTRACT_LIST_SUCCESS',
  GET_PROJECT_CONTRACT_LIST_FAIL: 'GET_PROJECT_CONTRACT_LIST_FAIL',

  GET_PROJECT_CONTRACT: 'GET_PROJECT_CONTRACT',
  GET_PROJECT_CONTRACT_SUCCESS: 'GET_PROJECT_CONTRACT_SUCCESS',
  GET_PROJECT_CONTRACT_FAIL: 'GET_PROJECT_CONTRACT_FAIL',

  UPDATE_PROJECT_CONTRACT: 'UPDATE_PROJECT_CONTRACT',
  UPDATE_PROJECT_CONTRACT_SUCCESS: 'UPDATE_PROJECT_CONTRACT_SUCCESS',
  UPDATE_PROJECT_CONTRACT_FAIL: 'UPDATE_PROJECT_CONTRACT_FAIL',
  
  CREATE_PROJECT_CONTRACT: 'CREATE_PROJECT_CONTRACT',
  CREATE_PROJECT_CONTRACT_SUCCESS: 'CREATE_PROJECT_CONTRACT_SUCCESS',
  CREATE_PROJECT_CONTRACT_FAIL: 'CREATE_PROJECT_CONTRACT_FAIL',
  
  UPDATE_CONTRACTS_WEIGHTS: 'UPDATE_CONTRACTS_WEIGHTS',
  UPDATE_CONTRACTS_WEIGHTS_SUCCESS: 'UPDATE_CONTRACTS_WEIGHTS_SUCCESS',
  UPDATE_CONTRACTS_WEIGHTS_FAIL: 'UPDATE_CONTRACTS_WEIGHTS_FAIL',
};
