import { Card, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { FormattedDate, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import styles from '../../styles';

const CheckInput = ({
  input,
  checkedInputs,
  setCheckedInputs,
  contract,
  selectedInput,
  setSelectedInput,
  classes,
  terminationDate,
}) => {
  const { current_term_in_days: actualTermInDays, currency, formatted_current_amount: formattedOldAmount } = contract;
  const { term_in_days: termInDays } = checkedInputs;
  const { id, title, label, value } = input;
  const { checked } = checkedInputs[id];
  const updateCheckedInputs = newValues => {
    setCheckedInputs({ ...checkedInputs, ...newValues });
  };

  const handleCheckChange = e => {
    updateCheckedInputs({ [id]: { checked: e.target.checked, value: !checked ? value : null } });
  };

  const handleInputChange = e => {
    updateCheckedInputs({ [id]: { checked, value: e.target.value } });
  };

  const actualAmountUI = (
    <Grid container marginTop={1}>
      <Typography variant='body2'>
        <FormattedMessage id='mainContractLabel.previousContractAmount' defaultMessage='Monto anterior del contrato' />:
        &nbsp; {formattedOldAmount}
      </Typography>
    </Grid>
  );

  const CurrencyField = () => {
    const materialUITextFieldProps = {
      fullWidth: true,
      label: label,
      size: 'small',
      autoFocus: selectedInput === id,
    };

    return (
      <NumericFormat
        value={value}
        required
        allowLeadingZeros
        thousandSeparator=','
        customInput={TextField}
        onChange={handleInputChange}
        onClick={() => setSelectedInput(id)}
        {...materialUITextFieldProps}
      />
    );
  };

  const terminationDateUI = (
    <Grid container marginTop={1}>
      <Typography variant='body2'>
        <FormattedMessage id='projectInfo.finishDate' defaultMessage='Fecha de terminación' />: &nbsp;
        <FormattedDate value={terminationDate} year='numeric' month='short' day='2-digit' />
      </Typography>
    </Grid>
  );
  const newTernUI = (
    <Grid container marginTop={1}>
      <Typography variant='body2'>
        <FormattedMessage id='mainContractStory.newTerm' defaultMessage='Nuevo plazo' />: &nbsp; {termInDays.value}
      </Typography>
    </Grid>
  );

  const previousTermUI = (
    <Grid container marginTop={1}>
      <Typography variant='body2'>
        <FormattedMessage id='mainContractStory.previousTerm' defaultMessage='Plazo anterior' />: &nbsp;
        {actualTermInDays}
      </Typography>
    </Grid>
  );

  const renderTextField = () => {
    if (id === 'contract_amount') {
      return (
        <>
          <Grid container spacing={1}>
            <Grid item width='20%'>
              <Grid container>
                <Typography variant='body2'>
                  <FormattedMessage id='mainContractLabel.currency' defaultMessage='Moneda' />
                </Typography>
              </Grid>
              <Grid container>
                <Typography variant='body2'>{currency}</Typography>
              </Grid>
            </Grid>
            <Grid item width='80%'>
              <CurrencyField />
            </Grid>
          </Grid>
          {actualAmountUI}
        </>
      );
    }

    const renderUpdatedValue = () => {
      if (id === 'compensable_days') {
        return terminationDateUI;
      }

      if (id === 'term_in_days') {
        return (
          <>
            {previousTermUI}
            {newTernUI}
            {terminationDateUI}
          </>
        );
      }
    };

    return (
      <>
        <TextField
          fullWidth
          label={label}
          onChange={handleInputChange}
          onClick={() => setSelectedInput(id)}
          size='small'
          value={value}
          type='number'
          autoFocus={selectedInput === id}
          required
        />
        {renderUpdatedValue()}
      </>
    );
  };

  return (
    <Card className={`${classes.card}_input`} key={id}>
      <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckChange} />} label={title} />
      {checked && renderTextField()}
    </Card>
  );
};

CheckInput.propType = {
  input: PropTypes.object,
  terminationDate: PropTypes.string,
  classes: PropTypes.object,
  checkedInputs: PropTypes.object,
  contract: PropTypes.object,
  setCheckedInputs: PropTypes.func,
  setSelectedInput: PropTypes.func,
  selectedInput: PropTypes.object,
};

export default withStyles(styles)(CheckInput);
