/**
 *
 * Auth saga file
 * Any action made for an projects should go here
 *
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import TaskService from '../../services/task.service';
import { taskConstants } from '../constants/task-constants';

/**
 * Workers
 */
export function* setProjectTask(action) {
  const result = yield call(TaskService.setTask, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.SET_NEW_PROJECT_TASK_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.SET_NEW_PROJECT_TASK_FAIL,
    });
  }
}

export function* getProjectTaskLevels(action) {
  const result = yield call(TaskService.getTaskLevels, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.GET_PROJECT_TASK_LEVELS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.GET_PROJECT_TASK_LEVELS_FAIL,
    });
  }
}

export function* createProjectTaskLevel(action) {
  const result = yield call(TaskService.createProjectTaskLevel, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.CREATE_PROJECT_TASK_LEVEL_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.CREATE_PROJECT_TASK_LEVEL_FAIL,
    });
  }
}

export function* getProjectTasks(action) {
  const result = yield call(TaskService.getTasks, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.GET_PROJECT_TASK_LIST_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.GET_PROJECT_TASK_LIST_FAIL,
    });
  }
}

export function* getProjectParentTasks(action) {
  const result = yield call(TaskService.getTasks, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.GET_PROJECT_PARENT_TASK_LIST_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.GET_PROJECT_PARENT_TASK_LIST_FAIL,
    });
  }
}

export function* updateProjectTask(action) {
  const result = yield call(TaskService.updateTask, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.UPDATE_PROJECT_TASK_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.UPDATE_PROJECT_TASK_FAIL,
    });
  }
}

export function* updateProjectTaskStatus(action) {
  const result = yield call(TaskService.updateTaskStatus, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.UPDATE_PROJECT_TASK_STATUS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.UPDATE_PROJECT_TASK_STATUS_FAIL,
    });
  }
}

export function* setTaskLog(action) {
  const result = yield call(TaskService.setLog, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.SET_NEW_TASK_LOG_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.SET_NEW_TASK_LOG_FAIL,
    });
  }
}

export function* getTaskLogs(action) {
  const result = yield call(TaskService.getLogs, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.GET_TASK_LOGS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.GET_TASK_LOGS_FAIL,
    });
  }
}

export function* getProducsStateLogs(action) {
  const result = yield call(TaskService.getStatusLogs, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.GET_PRODUCT_STATUS_LOGS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.GET_PRODUCT_STATUS_LOGS_FAIL,
    });
  }
}

export function* setProductStatusLog(action) {
  const result = yield call(TaskService.setStatusLog, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.SET_PRODUCT_STATUS_LOG_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.SET_PRODUCT_STATUS_LOG_FAIL,
    });
  }
}

export function* downloadProductEvidencesLog(action) {
  const result = yield call(TaskService.downloadEvidences, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: taskConstants.DOWNLOAD_TASK_LOGS_EVIDENCES_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.DOWNLOAD_TASK_LOGS_EVIDENCES_FAIL,
    });
  }
}

export function* updateProjectTaskWeights(action) {
  const result = yield call(TaskService.updateTaskWeights, action.payload);
  if (!!result && result.status === 204) {
    yield put({
      type: taskConstants.UPDATE_TASK_WEIGHTS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: taskConstants.UPDATE_TASK_WEIGHTS_FAIL,
    });
  }
}

/**
 * Watchers
 */
export function* setProjectTaskRequest() {
  yield takeEvery(taskConstants.SET_NEW_PROJECT_TASK, setProjectTask);
}

export function* getTasksRequest() {
  yield takeEvery(taskConstants.GET_PROJECT_TASK_LIST, getProjectTasks);
}

export function* getParentTasksRequest() {
  yield takeEvery(taskConstants.GET_PROJECT_PARENT_TASK_LIST, getProjectParentTasks);
}

export function* getTaskLevelsRequest() {
  yield takeEvery(taskConstants.GET_PROJECT_TASK_LEVELS, getProjectTaskLevels);
}

export function* updateTaskRequest() {
  yield takeEvery(taskConstants.UPDATE_PROJECT_TASK, updateProjectTask);
}

export function* updateTaskStatusRequest() {
  yield takeEvery(taskConstants.UPDATE_PROJECT_TASK_STATUS, updateProjectTaskStatus);
}

export function* setLogRequest() {
  yield takeEvery(taskConstants.SET_NEW_TASK_LOG, setTaskLog);
}

export function* getLogsRequest() {
  yield takeEvery(taskConstants.GET_TASK_LOGS, getTaskLogs);
}

export function* getLogsStateRequest() {
  yield takeEvery(taskConstants.GET_PRODUCT_STATUS_LOGS, getProducsStateLogs);
}

export function* setLogStatusRequest() {
  yield takeEvery(taskConstants.SET_PRODUCT_STATUS_LOG, setProductStatusLog);
}

export function* downloadTaskLogEvidencesRequest() {
  yield takeEvery(taskConstants.DOWNLOAD_TASK_LOGS_EVIDENCES, downloadProductEvidencesLog);
}

export function* updateTaskWeightsRequest() {
  yield takeEvery(taskConstants.UPDATE_TASK_WEIGHTS, updateProjectTaskWeights);
}

export function* createProjectTaskLevelRequest() {
  yield takeEvery(taskConstants.CREATE_PROJECT_TASK_LEVEL, createProjectTaskLevel);
}

/**
 * Export function
 */
function* taskSaga() {
  // Watchers request
  yield all([
    setProjectTaskRequest(),
    getTasksRequest(),
    getParentTasksRequest(),
    getTaskLevelsRequest(),
    updateTaskRequest(),
    updateTaskStatusRequest(),
    setLogRequest(),
    getLogsRequest(),
    getLogsStateRequest(),
    setLogStatusRequest(),
    downloadTaskLogEvidencesRequest(),
    updateTaskWeightsRequest(),
    createProjectTaskLevelRequest(),
  ]);
}

export default taskSaga;
