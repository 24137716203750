import { Grid, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import ColorLinearProgress from '../../../components/colorLinearProgress';
import AssignWeightsModal from './modals/AssignWeightsModal';

const ExpandCollapseButton = ({ open, onClick }) => (
  <IconButton aria-label='expand row' size='small' onClick={onClick}>
    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  </IconButton>
);

export const TableRowParent = ({
  classes,
  name = '',
  description = '',
  type,
  product = {},
  setOpen = () => {},
  open,
  parentId,
  progress = 0,
  siblings,
}) => {
  const isContract = type === 'contract';
  const className = `${classes.table}-cell-body-sticky-left-not-bg ${classes.table}-row-${type}-group`;
  const subLevelMultiplayer = { result: 0, product: 1.5, contract: 3 };

  return (
    <TableRow className={`${classes.table}-row-${type}-group`}>
      <TableCell align='center' colSpan={isContract ? 2 : 1} className={className}>
        <Grid container alignItems='center' width={290} spacing={2}>
          <Grid item xs={2} marginLeft={subLevelMultiplayer[type]}>
            <ExpandCollapseButton open={open} onClick={() => setOpen(!open)} />
          </Grid>
          <Grid item>
            <Typography
              textAlign='left'
              textTransform='capitalize'
              className={isContract && `${classes.table}_description`}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align='center' colSpan={isContract ? 1 : 2}>
        <Typography textAlign='left' className={`${classes.table}_description`} textTransform='capitalize'>
          {description}
        </Typography>
      </TableCell>
      <TableCell align='right' colSpan={6}>
        <AssignWeightsModal type={type} items={siblings} label={name} product={product} parentId={parentId} />
      </TableCell>
      <TableCell align='right' colSpan={1}>
        <ColorLinearProgress variant='determinate' value={progress} status='to_do' />
      </TableCell>
      <TableCell
        colSpan={1}
        align='right'
        className={`${classes.table}-cell-body-sticky-right-not-bg ${classes.agreement}_head_bg`}
      >
        <div className={`${classes.agreement}_box ${classes.table}-row-${type}-group`} />
      </TableCell>
    </TableRow>
  );
};
