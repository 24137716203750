import API from './api.service';

export class TaskService {
  static async setTask(data = {}) {
    try {
      const {
        projectTaskLevelId,
        newTask: {
          description,
          budget,
          goal,
          goalUnit,
          goalDescription,
          plannedStartDate,
          plannedCompleteDate,
          quantifiable,
          responsible,
          type,
          parentId,
          contractId,
        },
      } = data;
      let params = {
        description,
        budget,
        goal: !!goal ? goal : 0,
        goal_unit: goalUnit,
        goal_description: goalDescription,
        planned_to_start_at: plannedStartDate,
        planned_to_complete_at: plannedCompleteDate,
        quantifiable,
        responsible_id: responsible,
        type,
        parent_id: parentId,
        contract_id: contractId,
      };

      const response = await API(false, false).post(`project_task_levels/${projectTaskLevelId}/tasks/`, params);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getTasks(data = {}) {
    try {
      const response = await API(false, false).get(`project_task_levels/${data.projectTaskLevelId}/tasks/`);

      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getTaskLevels(data = {}) {
    try {
      const response = await API(false, false).get(`projects/${data.projectId}/task_levels/`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async createProjectTaskLevel(data = {}) {
    try {
      const { projectId, params } = data;
      const response = await API(false, false).post(`projects/${projectId}/task_levels/`, {
        project_level: { ...params },
      });

      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async updateTask(data = {}) {
    try {
      const {
        projectTaskLevelId,
        currentTask: {
          id,
          description,
          budget,
          goal,
          goalUnit,
          goalDescription,
          plannedStartDate,
          plannedCompleteDate,
          quantifiable,
          responsible,
          type,
          parentId,
          contractId,
        },
      } = data;

      let params = {
        description,
        budget,
        goal: !!goal ? goal : 0,
        goal_unit: goalUnit,
        goal_description: goalDescription,
        planned_to_start_at: plannedStartDate,
        planned_to_complete_at: plannedCompleteDate,
        quantifiable,
        responsible_id: responsible,
        type,
        parent_id: parentId,
        contract_id: contractId,
      };
      const response = await API(false, false).put(`project_task_levels/${projectTaskLevelId}/tasks/${id}`, params);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async updateTaskStatus(data = {}) {
    try {
      const { projectTaskLevelId, taskId, status, completedAt: completed_at } = data;
      let params = { status, completed_at };
      const response = await API(false, false).put(
        `project_task_levels/${projectTaskLevelId}/tasks/${taskId}/update_status`,
        params
      );
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async setLog(data = {}) {
    try {
      const { taskId, description, amount, valueAddedAt, valueAdded, evidences } = data;
      const body = new FormData();

      body.append('description', description);
      body.append('amount', amount);
      body.append('value_added_at', valueAddedAt);
      body.append('value_added', valueAdded);
      evidences.forEach(file => {
        body.append('evidences[]', file);
      });

      const response = await API(false, false).post(`/tasks/${taskId}/progress_logs`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getLogs(data = {}) {
    try {
      const response = await API(false, false).get(`/tasks/${data.taskId}/progress_logs`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getStatusLogs(data = {}) {
    try {
      const response = await API(false, false).get(`/tasks/${data.productId}/progress_logs?status=${data.status}`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async setStatusLog(data = {}) {
    try {
      let params = {
        status: data.status,
        rejection_comments: data.comments,
      };

      const response = await API(false, false).put(
        `/tasks/${data.productId}/progress_logs/${data.advanceId}/update_status`,
        params
      );
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async downloadEvidences(data = {}) {
    try {
      const response = await API(false, false).get(
        `/tasks/${data.productId}/progress_logs/${data.id}/download_evidences`,
        { responseType: 'blob' }
      );
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.id);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async updateTaskWeights(data = {}) {
    try {
      const { importanceFactors: tasks, id } = data;

      let params = { tasks };
      const response = await API(false, false).patch(`project_task_levels/${id}/tasks/batch_importance_factor`, params);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }
}

export default TaskService;
