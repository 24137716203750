/**
 *
 * Auth saga file
 * Any action made for an projects should go here
 *
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import CurrencyService from '../../services/currency.services';
import { currencyConstants } from '../constants/currency-constants';

/**
 * Workers
 */

export function* getCurrencies(action) {
  const result = yield call(CurrencyService.getCurrencies, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: currencyConstants.GET_CURRENCIES_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: currencyConstants.GET_CURRENCIES_FAIL,
    });
  }
}

/**
 * Watchers
 */

export function* getCurrenciesRequest() {
  yield takeEvery(currencyConstants.GET_CURRENCIES, getCurrencies);
}

/**
 * Export function
 */
function* currencySaga() {
  // Watchers request
  yield all([getCurrenciesRequest()]);
}

export default currencySaga;
