/**
 *
 * Auth saga file
 * Any action made for an projects should go here
 *
 */
import { call, put, takeEvery, all } from "redux-saga/effects";
import { notify } from "../../components/toast-message";
import ProjectService from "../../services/project.service";
import { projectConstants } from "../constants/project-constants";

/**
 * Workers
 */
export function* getProjects(action) {
   try {
     const result = yield call(ProjectService.getPaginatedProjects, action.payload);
     if (!!result && result.status === 200) {
       yield put({
         type: projectConstants.GET_FILTERED_PROJECTS_SUCCESS,
         payload: { data: result.data },
       });
     } else {
       notify('Something was wrong, please try again later', 'error');
       yield put({ type: projectConstants.GET_FILTERED_PROJECTS_FAIL });
     }
   } catch (error) {
     notify('Something was wrong, please try again later', 'error');
     yield put({ type: projectConstants.GET_FILTERED_PROJECTS_FAIL });
   }
}

export function* getProject(action) {
  try {
    const result = yield call(ProjectService.getProject, action.payload);
    if (!!result && result.status === 200) {
      yield put({
        type: projectConstants.GET_PROJECT_DETAILS_SUCCESS,
        payload: { data: result.data },
      });
    } else {
      notify('Something was wrong, please try again later', 'error');
      yield put({ type: projectConstants.GET_PROJECT_DETAILS_FAIL });
    }
  } catch (error) {
    notify('Something was wrong, please try again later', 'error');
    yield put({ type: projectConstants.GET_PROJECT_DETAILS_FAIL });
  }
}
 
 /**
  * Watchers
  */
 export function* getProjectsRequest() {
   yield takeEvery(projectConstants.GET_FILTERED_PROJECTS, getProjects);
 }

 export function* getProjectRequest() {
  yield takeEvery(projectConstants.GET_PROJECT_DETAILS, getProject);
}
 
/**
 * Export function
 */
function* projectSaga() {
  // Watchers request
  yield all([getProjectsRequest(), getProjectRequest()]);
}

export default projectSaga;
