import { filterConstants } from "../constants/filter-constants";

/**
 * Request all countries in client
 */
function requestCountries(clientId) {
  return {
    type: filterConstants.GET_COUNTRIES,
    payload: { clientId }
  };
}

/**
 * Select a country by user
 */
function selectCountry (countryId) {
  return {
    type: filterConstants.SELECT_COUNTRY,
    payload: {
      countryId
    }
  };
};

/**
 * Get Custom Filed Filters
 */
 function getCfFilters(clientId, formularyId, orgGroupId) {
   return {
    type: filterConstants.GET_CUSTOM_FIELD_FILTERS,
    payload: {
      clientId,
      orgGroupId,
      formularyId
    }
  };
}

/**
 * Get Filtered Administrative divisions
 */
 function getFilteredAdministrativeDivisions(countryId, selectedId, level) {
   return {
    type: filterConstants.GET_FILTERED_ADMINISTRATIVE_DIVISIONS,
    payload: {
      countryId,
      selectedId,
      level
    }
  };
};

function clearFilters() {
  return {
    type: filterConstants.CLEAR_FILTERS
  };
};


export const filterActions = {
  requestCountries,
  selectCountry,
  getCfFilters,
  getFilteredAdministrativeDivisions,
  clearFilters
};
