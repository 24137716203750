import { Card, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import styles from './styles';

const AdditionalInfo = ({ classes }) => {
  const project = useSelector(state => state.projects.project);
  const { cf_values } = project || {};
  return (
    <Card className={classes.card}>
      <Grid container margin='auto'>
        {cf_values.map((cfValue, index) => {
          return (
            <Grid key={`cfValues_${index}`} item width='200px' margin={1}>
              <Grid container>
                <Typography className={`${classes.card}_name`}>{`${cfValue.definition_name}`}</Typography>
              </Grid>
              <Grid container>
                <Typography className={`${classes.card}_value`}>
                  {!!cfValue.humanize_value ? `${cfValue.humanize_value}` : '---'}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default withStyles(styles)(AdditionalInfo);
