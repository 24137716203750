import { projectConstants } from "../constants/project-constants";

/**
 * Get Filtered Projects action
 */
function getFilteredProjects (clientId, filters = {}) {
  return ({
    type: projectConstants.GET_FILTERED_PROJECTS,
    payload: {
      clientId,
      ...filters
    }
  })
};

/**
 * Get Project Details
 */
function getProjectDetails (clientId, projectId) {
  return ({
    type: projectConstants.GET_PROJECT_DETAILS,
    payload: {
      clientId,
      projectId
    }
  })
}

/**
 * Clean Projects action
 */
function cleanFormularyReport(clientId) {
  return ({
    type: projectConstants.GET_FILTERED_PROJECTS,
    payload: { clientId }
  });
};

export const projectActions = {
  getFilteredProjects,
  getProjectDetails,
  cleanFormularyReport
};
