import { alpha } from '@mui/material/styles';

const styles = theme => ({
  selected: {
    '&-image': {
      width: 282,
      height: 282,
      marginBottom: 8,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  },
  carousel: {
    position: 'relative',
    width: 282,
    '&__image': {
      margin: '0px 3px',
      height: 51,
      width: 51,
      border: `3px solid ${alpha(theme.palette.gray.main, 0.0)}`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&-selected': {
        border: `3px solid ${theme.palette.secondary.main} !important`,
      },
    },
    '&__images': {
      display: 'flex',
      justifyContent: 'center',
      width: 282,
      overflowX: 'hidden',
    },
    '&-container': {
      margin: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '&__button': {
      position: 'absolute !important',
      top: '30% !important',
      '&-left': {
        left: 5,
      },
      '&-right': {
        right: 5,
      },
    },
  },
});

export default styles;
