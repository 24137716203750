import { commonConstants } from '../constants/common-constants';
export const commonActions = {
  reset
};

function reset() {
  return {
    type: commonConstants.RESET
  };
}
