import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormControl, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { projectUtil } from '../../../../utils/project.utils';
import { taskActions } from '../../../../redux/actions/task-actions';
import TooltipZoom from '../../../../components/tooltipZoom';
import EditIcon from '@mui/icons-material/Edit';
import BaseModal from './baseModal';
import styles from './styles';
import { ParentSelector } from './ParentSelector';
import { ContractSelector } from './ContractSelector';

const EditTaskModal = ({ classes, projectId, parentLevel, currency = 'USD', defaultTask, level }) => {
  const { id: projectTaskLevelId, level_type: currentLevelType, name: levelName } = level;
  const { contractId: currentContractId, parentId: currentParentId } = defaultTask;
  const dispatch = useDispatch();
  const [newTask, setNewTask] = useState({ ...defaultTask });
  const [openCreateTask, setOpenCreateTask] = useState(false);
  const [parentId, setParentId] = useState(currentParentId);
  const [contracts, setContracts] = useState([]);
  const [contractId, setContractId] = useState(currentContractId);
  const [selectGoalUnit, setSelectGoalUnit] = useState('');
  const [textGoalUnit, setTextGoalUnit] = useState(null);

  const handleActionButton = () => {
    const auxTask = {
      ...newTask,
      ...{ budget: projectUtil.removeDecimalsFromAmount(newTask.budget) },
      ...{ parentId },
      ...{ contractId },
    };
    dispatch(taskActions.updateProjectTask(projectTaskLevelId, auxTask));
    setOpenCreateTask(false);
  };

  const handleCancelButton = () => {
    setOpenCreateTask(false);
  };

  const modalButton = (
    <TooltipZoom title={<FormattedMessage id='projectInfo.product.editResult' defaultMessage='Edit Result' />}>
      <IconButton
        color='primary'
        aria-label='open progress modal'
        onClick={() => setOpenCreateTask(true)}
        className={`${classes.approve}-button`}
      >
        <EditIcon />
      </IconButton>
    </TooltipZoom>
  );

  const changeContracts = contracts => {
    setContracts(contracts);
  };

  return (
    <BaseModal
      title={<FormattedMessage id={`projectInfo.${levelName}.edit`} defaultMessage='Edit task' />}
      productName={<FormattedMessage id={`projectInfo.${levelName}`} defaultMessage="Task's name" />}
      currency={currency}
      newTask={newTask}
      setNewTask={setNewTask}
      modalButton={modalButton}
      openCreateTask={openCreateTask}
      setOpenCreateTask={setOpenCreateTask}
      selectGoalUnit={selectGoalUnit}
      setSelectGoalUnit={setSelectGoalUnit}
      textGoalUnit={textGoalUnit}
      setTextGoalUnit={setTextGoalUnit}
      textActionButton={<FormattedMessage id='projectInfo.product.update' defaultMessage={'Update'} />}
      handleActionButton={handleActionButton}
      handleCancelButton={handleCancelButton}
    >
      {currentLevelType !== 'one' && (
        <FormControl fullWidth>
          <ParentSelector
            parentLevel={parentLevel}
            parentId={parentId}
            setParentId={setParentId}
            onParentChange={changeContracts}
          />
        </FormControl>
      )}
      {currentLevelType === 'three' && (
        <FormControl fullWidth>
          <ContractSelector contracts={contracts} contractId={contractId} setContractId={setContractId} />
        </FormControl>
      )}
    </BaseModal>
  );
};

export default withStyles(styles)(EditTaskModal);
