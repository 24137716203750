import { contractConstants } from '../constants/contract-constants';

const setProjectContract = (projectId, newContract) => ({
  type: contractConstants.SET_NEW_PROJECT_CONTRACT,
  payload: { projectId, ...newContract },
});

const getProjectContracts = projectId => ({
  type: contractConstants.GET_PROJECT_CONTRACT_LIST,
  payload: { projectId },
});

const getProjectContract = (projectId, contractId) => ({
  type: contractConstants.GET_PROJECT_CONTRACT,
  payload: { projectId, contractId },
});

const updateProjectContract = (projectId, contractId, updateContract) => ({
  type: contractConstants.UPDATE_PROJECT_CONTRACT,
  payload: { projectId, contractId, ...updateContract },
});

const createProjectContract = (projectId, createContract) => ({
  type: contractConstants.CREATE_PROJECT_CONTRACT,
  payload: { projectId, ...createContract },
});

const updateContractsWeights = (importanceFactors, productId) => ({
  type: contractConstants.UPDATE_CONTRACTS_WEIGHTS,
  payload: { importanceFactors, productId },
});

export const contractActions = {
  setProjectContract,
  getProjectContracts,
  getProjectContract,
  updateProjectContract,
  createProjectContract,
  updateContractsWeights,
};
