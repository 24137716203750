import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  LinearProgress,
  IconButton,
  Typography,
  Modal,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import styles from './styles';
import ProgressRow from '../progressRow';
import { physicalProgressActions } from '../../../redux/actions/physical-progress-actions';
import { financialProgressActions } from '../../../redux/actions/financial-progress-actions';

const ProgressHistoryModal = ({
  open,
  onClose,
  contractId,
  progressKey,
  value,
  color,
  classes,
  openAddProgressModal,
}) => {
  const columns = {
    registeredDate: 'Fecha registro',
    advanceDate: 'Fecha avance',
    percentage: 'Porcentaje',
    comments: 'Comentarios',
    enteredBy: 'Ingresado por',
  };

  const dispatch = useDispatch();
  const progresses = useSelector(state => state[progressKey].progresses);
  const isLoading = useSelector(state => state[progressKey].loading);

  const progressDispatch = {
    physicalProgress: physicalProgressActions.getPhysicalProgresses,
    financialProgress: financialProgressActions.getFinancialProgresses,
  };

  const lastModification = () => (progresses?.length ? progresses[progresses.length - 1].created_at : 0);

  useEffect(() => {
    if (contractId && progressKey) {
      dispatch(progressDispatch[progressKey](contractId, { pageSize: 20 }));
    }
  }, [contractId]);

  const closeUI = (
    <Grid container justifyContent='space-between' marginBottom={1}>
      <Grid item marginLeft='auto'>
        <IconButton color='primary' aria-label='close_modal' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const headerUI = (
    <>
      <Grid container marginBottom={3}>
        <Typography variant='h4'>
          <FormattedMessage id={`labelState.${progressKey}History`} defaultMessage='Historial Avance' />
        </Typography>
      </Grid>
      <Grid container justifyContent='space-between' alignItems='center' spacing={1} marginBottom={1}>
        <Grid item>
          <FormattedMessage id='projectInfo.advance.actualState' defaultMessage='Registrar Avance' />
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={10}>
              <LinearProgress
                value={value}
                variant='determinate'
                className={`${classes.linear_progress} ${classes.linear_progress}_color_${color}`}
              />
            </Grid>
            <Grid item className={`${classes.linear_progress}_value`}>
              {value}%
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={`${classes.linear_progress}_last-modification`}>
          <FormattedMessage id='labelState.lastModification' defaultMessage='Última modificación' />
          :&nbsp;
          <Typography component='span' variant='subtitle1'>
            {!!lastModification() && (
              <FormattedDate value={lastModification()} year='numeric' month='short' day='2-digit' />
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const buttonsUI = (
    <Grid container marginTop={1} justifyContent='flex-end'>
      <Button variant='contained' color='info' disableElevation={true} onClick={openAddProgressModal}>
        <Typography variant='button'>
          <FormattedMessage id='labelState.registerProgress' defaultMessage='Registrar Avance' />
        </Typography>
      </Button>
    </Grid>
  );

  return (
    <Modal open={open} onClose={onClose} className={`${classes.modal}_container`}>
      <Grid
        container
        className={`${classes.modal}_body`}
        justifyContent='space-between'
        maxWidth='800px'
        padding='20px 40px 40px'
      >
        {closeUI}
        {headerUI}
        {isLoading && (
          <Grid container alignItems='center' justifyContent='center'>
            <CircularProgress color='secondary' size={50} />
          </Grid>
        )}
        {!isLoading && (
          <TableContainer>
            <Table className={`${classes.table} ${classes.table}_color_secondary`}>
              <TableHead>
                <TableRow>
                  {Object.keys(columns).map(key => (
                    <TableCell key={key}>
                      <FormattedMessage id={`projectInfo.advance.${key}`} defaultMessage={columns[key]} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {progresses?.map(progress => (
                  <ProgressRow
                    key={progress.id}
                    createdAt={progress.created_at}
                    effectiveDate={progress.effective_date}
                    formattedValue={progress.formatted_value}
                    comment={progress.comment}
                    responsible={progress.responsible}
                    full={true}
                    color={color}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {buttonsUI}
      </Grid>
    </Modal>
  );
};

ProgressHistoryModal.propTypes = {
  open: PropTypes.bool,
  progressKey: PropTypes.string,
  onClose: PropTypes.func,
  openAddProgressModal: PropTypes.func,
  contractId: PropTypes.number,
  value: PropTypes.number,
  color: PropTypes.string,
};

export default withStyles(styles)(ProgressHistoryModal);
