const styles = (theme) => ({
  root: {
    '&_header': {
      position: 'relative',

      '&-button': {
        position: 'absolute',
        bottom: 30,
        right: 40,
      },

      '&-img': {
        width: '100%',
        //height: 'auto',
        maxHeight: 500,
        objectFit: 'cover',
        objectPosition: '50% 50%',
      }

    }
  },

  filters: {
    display: 'flex !important',
    flexWrap: 'wrap !important',
    flexDirection: 'row !important',
  },

  dropdown: {
    flexGrow: 1,
    flexBasis: '30%',
    padding: 10,
    minWidth: 400,
  },

  search: {
    width: '100%'
  },

  cards: {
    margin: 10,
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    gap: 10,
  },
  
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
});

export default styles;
