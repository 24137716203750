import { physicalProgressConstants } from '../constants/physical-progress-constants';

const createPhysicalProgress = (contractId, physicalProgress) => ({
  type: physicalProgressConstants.CREATE_PHYSICAL_PROGRESS,
  payload: { contractId, ...physicalProgress },
});

const getPhysicalProgresses = (contractId, params = {}) => ({
  type: physicalProgressConstants.GET_PHYSICAL_PROGRESSES,
  payload: { 
    contractId,
    ...params
  },
});

export const physicalProgressActions = {
  createPhysicalProgress,
  getPhysicalProgresses,
};
