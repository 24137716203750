import { get } from "lodash";
import Storage from "./storage";

const Auth = require("j-toker");

export const clearAuth = () => {
  Storage.clearAll();
};

export const saveAuth = (data) => {
  Storage.setData("currentUser", data.user);
  Storage.setData("isLoggedIn", get(data, "data.signedIn", true));
};

export const isAuthenticated = () => Storage.getData("isLoggedIn");

export const storageUser = () => Storage.getData("currentUser");

export const setAuthHeaders = (headers) => {
  //delele headers['content-type'];
  const storageHeaders = {
    'access-token': headers['access-token'],
    'cache-control': headers['cache-control'],
    client: headers.client,
    uid: headers.uid
  }
  Storage.setData("authHeaders", storageHeaders);
};

export const setResetHeaders = (headers) => {
  const storageHeaders = {
    ...headers
  }
  Storage.setData("authHeaders", storageHeaders);
};

export const getAuthHeaders = () => Storage.getData("authHeaders");

Auth.configure({
  apiUrl: process.env.REACT_APP_API_URL,
  signOutPath: `${process.env.REACT_APP_API_URL}/auth/sign_out`,
  // emailSignInPath: `${process.env.REACT_APP_API_URL}/auth/sign_in`,
  emailRegistrationPath: "/registration",
  accountUpdatePath: "/update",
  accountDeletePath: "/delete",
  passwordResetPath: "/auth/reset_password",
  passwordUpdatePath: "/update_password",
  tokenValidationPath: "/auth/validate_token",

  proxyIf() {
    return false;
  },

  proxyUrl: "/proxy",
  validateOnPageLoad: false,
  forceHardRedirect: false,
  storage: "localStorage",
  cookieExpiry: 14,
  cookiePath: "/",

  passwordResetSuccessUrl() {
    return window.location.href;
  },

  confirmationSuccessUrl() {
    return window.location.href;
  },

  tokenFormat: {
    "access-token": "access-token",
    "token-type": "Bearer",
    client: "client",
    expiry: "expiry",
    uid: "uid",
  },

  parseExpiry(headers) {
    // convert from ruby time (seconds) to js time (millis)
    return parseInt(headers.expiry, 10) * 1000 || null;
  },

  handleLoginResponse(resp) {
    return resp.data;
  },

  handleAccountUpdateResponse(resp) {
    return resp.data;
  },

  handleTokenValidationResponse(resp) {
    return resp.data;
  },

  authProviderPaths: {
    github: "/github",
    facebook: "/facebook",
    google: "/google_oauth2",
  },
});
