import { Button, Card, Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { projectActions } from '../../redux/actions/project-actions';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { withStyles } from '@mui/styles';
import styles from './styles';
import { projectUtil } from '../../utils/project.utils';
import LabelState from '../../components/labelState';
import Map from '../../components/map';
import { Marker } from '@react-google-maps/api';
import MainContractInfo from '../projects/projectCard/mainContractInfo';
import { mainContractLabels } from './main_contract_labels';
import { statuses } from '../projects/projectCard/mainContractInfo/contract_statuses';
const images = require.context('../../assets/images');

const Project = ({ classes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.authentication.user);
  const project = useSelector(state => state.projects.project);
  const mainContract = project?.main_contract;
  const projectId = useParams().id;
  const [selectedCfValues, setSelectedCfValues] = useState([]);

  useEffect(() => {
    setSelectedCfValues(projectUtil.cfValuesState(project));
  }, [project]);

  useEffect(() => {
    if (currentUser && projectId) {
      dispatch(projectActions.getProjectDetails(currentUser.client_id, projectId));
    }
  }, [dispatch, currentUser, projectId]);

  const CfValues = () => {
    return (
      <>
        {!!selectedCfValues.length &&
          selectedCfValues.map((cfValue, index) => {
            return <LabelState key={`labelState_${index}`} cfValue={cfValue} />;
          })}
      </>
    );
  };

  const CfDetails = () => {
    return (
      <>
        {!!project?.cf_values &&
          project.cf_values.map((cfValue, index) => {
            return (
              <Fragment key={`cfValue_${index}`}>
                <Grid className={`${classes.root}_body_details_values-name`}>{`${cfValue.definition_name}`}</Grid>
                <Grid className={`${classes.root}_body_details_values-value`}>
                  {!!cfValue.humanize_value ? `${cfValue.humanize_value}` : '---'}
                </Grid>
              </Fragment>
            );
          })}
      </>
    );
  };

  const MainContractDetail = ({ attribute }) => {
    const [name, value] = attribute;
    const splitName = name?.split('_');
    const canRenderAttribute = name !== 'id' && name !== 'main_contract';
    const isValidDate = splitName[splitName.length - 1] === 'date';
    const isValidAmount = splitName[splitName.length - 1] === 'amount';

    const renderValue = () => {
      if (value === null) {
        return '---';
      }
      if (isValidDate) {
        return <FormattedDate value={value} month='short' year='numeric' day='2-digit' />;
      }
      if (isValidAmount) {
        return value;
      }
      if (name === 'contractor') {
        return value.name;
      }
      if (name === 'status') {
        const current_status = statuses[value];
        return <FormattedMessage id={current_status.id} defaultMessage={current_status.value} />;
      }
      return value;
    };

    return (
      <>
        {canRenderAttribute && (
          <>
            <Grid className={`${classes.root}_body_details_values-name`}>{mainContractLabels[name]?.label}</Grid>
            <Grid className={`${classes.root}_body_details_values-value`}>
              {mainContractLabels[name] && renderValue()}
            </Grid>
          </>
        )}
      </>
    );
  };

  return (
    <Grid container className={`${classes.root}`}>
      <Grid container className={`${classes.root}_head`}>
        <Link className={`${classes.root}_head-link`} to='/projects'>
          <FormattedMessage id='project.allProjects' defaultMessage={'All Projects'} />
        </Link>
        <Grid item className={`${classes.root}_head-name`}>
          {project?.name}
        </Grid>
      </Grid>
      <Grid container className={`${classes.root}_body`}>
        <Grid item className={`${classes.root}_body_details`}>
          <Card className={`${classes.root}_body_details-state`} elevation={3}>
            {!isEmpty(mainContract) ? <MainContractInfo mainContract={mainContract} /> : <CfValues />}
          </Card>
          <Grid item className={`${classes.root}_body_details_values`}>
            <Grid className={`${classes.root}_body_details_values-title`}>
              <FormattedMessage id='project.details' defaultMessage={'Details'} />
            </Grid>
            <Grid></Grid>
            {!isEmpty(mainContract) &&
              Object.entries(mainContract).map((attribute, index) => {
                return <MainContractDetail key={`mainContractDetails_${index}`} attribute={attribute} />;
              })}
          </Grid>
          {true && (
            <Button
              variant='contained'
              size='small'
              onClick={() => navigate(`/project/info/${projectId}`)}
              className={`${classes.root}_body_details_values-button`}
            >
              <FormattedMessage id='project.manage' defaultMessage={'Manage'} />
            </Button>
          )}
        </Grid>
        <Grid item className={`${classes.root}_body_media`}>
          <Grid container className={`${classes.root}_google-maps`}>
            <Map containerStyle={{ width: '100%', height: 450 }} center={{ lat: 0.9097, lng: -77.8270 }}>
              <Marker position={{ lat: 0.909694, lng: -77.827056 }} />
            </Map>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}><img className={`${classes.root}_body_media-img`} src={images('./Image_1.jpg')} alt='Map' /></Grid>
            <Grid item xs={4}><img className={`${classes.root}_body_media-img`} src={images('./Image_2.jpg')} alt='Map' /></Grid>
            <Grid item xs={4}><img className={`${classes.root}_body_media-img`} src={images('./Image_3.jpg')} alt='Map' /></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Project);
