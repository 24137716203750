import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { withStyles } from '@mui/styles';
import styles from './styles';

const NumberInput = ({ classes, label = ' ', value, onChange, required = true }) => {
  const materialUITextFieldProps = {
    label: label,
    size: 'medium',
  };

  const MAX_LIMIT = 100;
  const MIN_LIMIT = 0;

  return (
    <NumericFormat
      value={value}
      required={required}
      className={classes.root}
      name='number'
      customInput={TextField}
      onChange={onChange}
      isAllowed={values => {
        const { floatValue } = values;
        return (floatValue <= MAX_LIMIT && floatValue >= MIN_LIMIT) || floatValue === null || floatValue === undefined;
      }}
      {...materialUITextFieldProps}
    />
  );
};

NumberInput.propType = {
  classes: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.any,
};

export default withStyles(styles)(NumberInput);
