const styles = theme => ({
  root: {
    padding: '.5em 1em',
    width: '100%',
    background: `${theme.palette.primary.main} !important`,

    '&__text': {
      textAlign: 'right',
      fontSize: '.75rem',
      lineHeight: '1.5em',
      color: theme.palette.primary.contrastText
    }
  }
});

export default styles;
