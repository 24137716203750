const styles = theme => ({
  root: {
    flexGrow: 1,
    flexBasis: '25%',
    '&_textfield': {
      borderRadius: '4px',
      backgroundColor: theme.palette.gray.contrastText,
    },
  },
});

export default styles;
