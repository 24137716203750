import styles from '../styles';

import { withStyles } from '@mui/styles';
import { Autocomplete, Button, Chip, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import Dropdown from '../../../../components/dropdown';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { contractModificationActions } from '../../../../redux/actions/contract-modification-actions';
import { projectUtil } from '../../../../utils/project.utils';
import CheckInput from './checkInput';

const EditModal = ({ classes, setOpenModifyContractModal, openModifyContractModal, projectId, contract }) => {
  const contractModificationsTypes = [
    { name: 'Addenda', value: 'addendum' },
    { name: 'Contract Update', value: 'contract_update' },
  ];

  const checkInputsInitState = {
    compensable_days: {
      checked: false,
      value: '',
    },
    term_in_days: {
      checked: false,
      value: '',
    },
    contract_amount: {
      checked: false,
      value: '',
    },
  };

  const dispatch = useDispatch();
  const [contractModificationType, setContractModificationType] = useState({ name: '', value: '' });
  const [externalReferences, setExternalReferences] = useState(null);
  const [comment, setComment] = useState('');
  const [selectedInput, setSelectedInput] = useState(null);
  const [checkedInputs, setCheckedInputs] = useState(checkInputsInitState);
  const currentUser = useSelector(state => state.authentication.user);
  const clientId = currentUser.client_id;
  const reasons = useSelector(state => state.contractModifications.justifications);
  const [reason, setReason] = useState({ name: '', id: '' });
  const [effectiveDate, setEffectiveDate] = useState(Date.now());
  const intl = useIntl();
  const formatDate = intl.messages['projectInfo.product.dateFormat'];
  const {
    status,
    current_termination_date: currentTerminationDate,
    start_date: startDate,
    compensable_days: currentCompensableDays,
    current_term_in_days: actualTermInDays,
  } = contract;

  const [terminationDate, setTerminationDate] = useState(currentTerminationDate);

  const {
    compensable_days: compensableDays,
    term_in_days: termInDays,
    contract_amount: contractAmount,
  } = checkedInputs;

  const calculateTerminationDays = () => {
    const currentStartDate = new Date(startDate);
    const days =
      (Number(compensableDays.value) + Number(currentCompensableDays) || 0) +
      (Number(termInDays.value) || actualTermInDays);
    const calculatedTerminationDays = projectUtil.addDaysToDate(currentStartDate, days);
    setTerminationDate(calculatedTerminationDays);
  };

  useEffect(() => {
    if (!!compensableDays.value || !!termInDays.value) {
      calculateTerminationDays();
    } else {
      setTerminationDate(currentTerminationDate);
    }
  }, [checkedInputs, currentTerminationDate]);

  useEffect(() => {
    if (clientId) {
      dispatch(contractModificationActions.getContractModificationJustifications(clientId));
    }
  }, [clientId]);

  const checkInputs = [
    {
      id: 'term_in_days',
      title: <FormattedMessage id='mainContractLabel.modifyTermInDays' defaultMessage='Modificar plazo de convenio' />,
      label: <FormattedMessage id='mainContractLabel.typeDaysValue' defaultMessage='Indique el valor el dias *' />,
      value: termInDays.value,
    },
    {
      id: 'contract_amount',
      title: (
        <FormattedMessage id='mainContractLabel.modifyContractAmount' defaultMessage='Modificar monto del convenio' />
      ),
      label: <FormattedMessage id='mainContractLabel.contractAmount' defaultMessage='Monto del convenio *' />,
      value: contractAmount.value,
    },
  ];

  const inputsUI = (
    <>
      <Grid container className={`${classes.modal}_input_container`}>
        <Dropdown
          required
          labelField={'name'}
          idName='mainContractLabel.selectModificationType'
          label={
            <FormattedMessage
              id='mainContractLabel.selectModificationType'
              defaultMessage='¿Qué tipo de modificación de convenio desea realizar?'
            />
          }
          listItems={contractModificationsTypes}
          handleOnChange={setContractModificationType}
          hookValue={contractModificationType}
        />
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={<FormattedMessage id='mainContractLabel.effectiveDate' defaultMessage='Fecha efectiva' />}
            value={effectiveDate}
            className={classes.datePicker}
            inputFormat={formatDate}
            onChange={setEffectiveDate}
            renderInput={params => <TextField {...params} size='small' required />}
            components={{
              OpenPickerIcon: CalendarTodayIcon,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <Dropdown
          required
          labelField={'name'}
          idName='mainContractLabel.reason'
          label={<FormattedMessage id='mainContractLabel.reason' defaultMessage='Justificación/Causa' />}
          listItems={reasons}
          handleOnChange={setReason}
          hookValue={reason}
        />
      </Grid>
      {checkInputs.map(input => (
        <CheckInput
          key={input.id}
          input={input}
          terminationDate={terminationDate}
          classes={classes}
          checkedInputs={checkedInputs}
          contract={contract}
          setCheckedInputs={setCheckedInputs}
          setSelectedInput={setSelectedInput}
          selectedInput={selectedInput}
        />
      ))}
      <Grid container className={`${classes.modal}_body_part_sub-title`}>
        <Typography variant='h6'>
          <FormattedMessage id='mainContractLabel.externalReferences' defaultMessage='Referencias externas' />
        </Typography>
      </Grid>
      <Grid container>
        <FormattedMessage
          id='mainContractLabel.externalReferencesMessage'
          defaultMessage='Agregar las referencias externas facilitan el seguimiento del proyecto.'
        />
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <Autocomplete
          multiple
          id='tags-filled'
          options={[]}
          freeSolo
          renderTags={(value, getTagProps) => {
            setExternalReferences(value.join(','));
            return value.map((option, index) => (
              <Chip variant='outlined' color='secondary' label={option} {...getTagProps({ index })} />
            ));
          }}
          renderInput={params => (
            <TextField
              {...params}
              size='small'
              variant='outlined'
              label=''
              placeholder='Referencia externa'
              onClick={() => {
                setSelectedInput(null);
              }}
            />
          )}
        />
      </Grid>
      <Grid container marginBottom={2}>
        <FormattedMessage
          id='mainContractLabel.addExternalReferences'
          defaultMessage='Añada varias referencias escribiendola y presionando ENTER.'
        />
      </Grid>
      <Grid container className={`${classes.modal}_input_container`}>
        <TextField
          autoFocus
          value={comment}
          onChange={e => setComment(e.target.value)}
          label={<FormattedMessage id='projectInfo.approve.comments' defaultMessage='Comentarios' />}
          size='small'
          variant='outlined'
          onClick={() => {
            setSelectedInput(null);
          }}
          multiline
          rows={5}
        />
      </Grid>
    </>
  );

  const areInputsValid = () => {
    const isCompensableDaysValid = compensableDays.checked ? !!compensableDays.value : '_';
    const isTermInDaysDaysValid = termInDays.checked ? !!termInDays.value : '_';
    const isContractAmountValid = contractAmount.checked ? !!contractAmount.value : '_';
    const isEffectiveDateValid = !!effectiveDate;
    const isReasonValid = !!reason.id;
    const isContractModificationsTypesValid = !!contractModificationType.value;

    return (
      isCompensableDaysValid &&
      isTermInDaysDaysValid &&
      isContractAmountValid &&
      isContractModificationsTypesValid &&
      isReasonValid &&
      isEffectiveDateValid
    );
  };

  const handleSubmitEditModal = () => {
    const contractModificationParams = {
      comment,
      compensable_days: compensableDays.value || 0,
      amount: projectUtil.removeDecimalsFromAmount(contractAmount.value),
      effective_date: new Date(effectiveDate).toISOString(),
      external_references: externalReferences,
      modification_type: contractModificationType.value,
      reason_id: reason.id,
      status: status,
      term_in_days: termInDays.value || 0,
      termination_date: new Date(terminationDate).toISOString(),
      responsible_id: currentUser.id,
    };

    dispatch(contractModificationActions.createContractModification(contract.id, contractModificationParams));
    setOpenModifyContractModal(false);
  };

  const modifyContractModalContentUI = (
    <Grid container className={`${classes.modal}_body`} justifyContent='space-between'>
      <Grid container marginTop={1}>
        <Grid item marginLeft='auto'>
          <IconButton color='primary' aria-label='add an alarm' onClick={() => setOpenModifyContractModal(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container className={`${classes.modal}_body_part_title`}>
        <Grid item>
          <FormattedMessage id='mainContractLabel.modifyContract' defaultMessage='Modificar convenio' />
        </Grid>
      </Grid>
      <Grid item className={`${classes.modal}_body_part ${classes.modal}_body_part_left`}>
        <Grid container>{inputsUI}</Grid>
      </Grid>
      <Grid container className={`${classes.modal}_body_button_container`}>
        <Button
          variant='contained'
          color='info'
          className={classes.button}
          disabled={!areInputsValid()}
          onClick={handleSubmitEditModal}
        >
          <FormattedMessage id='mainContractLabel.confirmModification' defaultMessage='Confirmar modificación' />
        </Button>
        <Button
          variant='outlined'
          color='info'
          className={classes.button}
          onClick={() => setOpenModifyContractModal(false)}
        >
          <FormattedMessage id='projectInfo.product.cancel' defaultMessage='Cancelar' />
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={openModifyContractModal}
      onClose={() => setOpenModifyContractModal(false)}
      className={`${classes.modal}_container`}
    >
      {modifyContractModalContentUI}
    </Modal>
  );
};
export default withStyles(styles)(EditModal);
