import { authConstants } from "../constants/auth-constants";

function login(email, password) {
  return {
    type: authConstants.LOGIN_REQUEST,
    payload: { email, password },
  };
}

function setLoggedUser(user) {
  return {
    type: authConstants.LOGIN_SUCCESS,
    payload: { user },
  };
}

function updateLoggedUser(user) {
  return {
    type: authConstants.LOGIN_USER_UPDATE,
    payload: { ...user }
  }
}

function logout() {
  return {
    type: authConstants.LOGOUT,
  };
}

function resetPassword(email) {
  return {
    type: authConstants.RESET_PASSWORD_REQUEST,
    payload: email,
  };
}

function setNewPassword(password, passwordConfirmation) {
  return {
    type: authConstants.SET_NEW_PASSWORD,
    payload: { password, passwordConfirmation }
  };
}

/**
 * Request the locale location
*/
function changeLocale (clientId, userId, locale) {
  return {
    type: authConstants.USER_LOCALE_CHANGE_REQUEST,
    payload: {
      clientId,
      user: {
        id: userId,
        settings_locale: locale
      }
    }
  };
};

/**
 * Apply the locale error action
 */
function changeLocaleFail(){ 
  return {
    type: authConstants.USER_LOCALE_CHANGE_ERROR
  };
}

export const authActions = {
  login,
  logout,
  setLoggedUser,
  updateLoggedUser,
  resetPassword,
  setNewPassword,
  changeLocale,
  changeLocaleFail
};
