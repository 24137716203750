/**
 *
 * Auth saga file
 * Any action made for an projects should go here
 *
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import ContractorService from '../../services/contractor.services';
import { contractorConstants } from '../constants/contractor-constants';

/**
 * Workers
 */

export function* createContractor(action) {
  const result = yield call(ContractorService.createContractor, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractorConstants.CREATE_CONTRACTOR_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractorConstants.CREATE_CONTRACTOR_FAIL,
    });
  }
}

export function* getContractors(action) {
  const result = yield call(ContractorService.getContractors, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractorConstants.GET_CONTRACTORS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractorConstants.GET_CONTRACTORS_FAIL,
    });
  }
}

/**
 * Watchers
 */

export function* createContractorRequest() {
  yield takeEvery(contractorConstants.CREATE_CONTRACTOR, createContractor);
}

export function* getContractorsRequest() {
  yield takeEvery(contractorConstants.GET_CONTRACTORS, getContractors);
}

/**
 * Export function
 */
function* contractorSaga() {
  // Watchers request
  yield all([
    createContractorRequest(),
    getContractorsRequest()
  ]);
}

export default contractorSaga;
