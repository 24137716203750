import { alpha } from '@mui/material';

const styles = theme => ({
  root: {
    '&_variant1': {
      position: 'relative',
      height: 144,
      flexDirection: 'column !important',
      alignItems: 'center',
      marginTop: '10px !important',
      padding: '20px 10px !important',
      gap: 8,
      border: `1px dashed ${theme.palette.gray.dark} !important`,
      borderRadius: 4,
      '&-normal': {},
      '&-neutral': {
        backgroundColor: alpha(theme.palette.gray.main, 0.3),
      },
      '&-approve': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.3),
      },
      '&-reject': {
        backgroundColor: `${theme.palette.error.main}`,
      },
    },
    '&_drag': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: 4,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
});

export default styles;
