import { commonConstants } from '../constants/common-constants';
import { filterConstants } from '../constants/filter-constants';

const adminDivisions = {
  level1: [],
  loadingLvl1: false,
  selectedLvl1: null,
  level2: [],
  loadingLvl2: false,
  selectedLvl2: null,
  level3: [],
  loadingLvl3: false,
  selectedLvl3: null,
  level4: [],
  loadingLvl4: false,
  selectedLvl4: null
};

const cfFilters = {
  loading: false,
  filters: []
};

// AD = Administrative Divisions
const initState = {
  countries: [],
  selectedCountry: null,
  loadingCountries: false,
  adminDivisions,
  cfFilters
};

export default function filters(state = initState, action) {
  let level = null;
  let newState;
  switch (action.type) {
    case filterConstants.GET_COUNTRIES:
      newState = {
        ...state,
        loadingCountries: true
      };
      return newState;
    case filterConstants.GET_COUNTRIES_SUCCESS:
      newState = {
        ...state,
        loadingCountries: false,
        countries: action.payload.data
      };
      return newState;
    case filterConstants.GET_COUNTRIES_FAIL:
      newState = {
        ...state,
        loadingCountries: false,
        countries: []
      };
      return newState;
    case filterConstants.SELECT_COUNTRY:
      newState = {
        ...state,
        selectedCountry: action.payload.countryId,
        adminDivisions
      };
      return newState;
    case filterConstants.GET_CUSTOM_FIELD_FILTERS:
      newState = {
        ...state,
        cfFilters: { ...cfFilters, loading: true }
      };
      return newState;
    case filterConstants.GET_CUSTOM_FIELD_FILTERS_SUCCESS:
      newState = {
        ...state,
        cfFilters: { ...state.cfFilters, loading: false, filters: action.payload.data }
      };
      return newState;
    case filterConstants.GET_CUSTOM_FIELD_FILTERS_FAIL:
      newState = {
        ...state,
        cfFilters
      };
      return newState;
    case filterConstants.GET_FILTERED_ADMINISTRATIVE_DIVISIONS:
      const selectedLvlId = action.payload.selectedId || null;
      const currentLevel = {};
      level = action.payload.level || 1;
      if (!!selectedLvlId) {
        currentLevel[`selectedLvl${parseInt(level) - 1}`] = selectedLvlId;
      }
      currentLevel[`loadingLvl${level}`] = true;
      currentLevel[`level${level}`] = [];
      newState = {
        ...state,
        adminDivisions: { ...state.adminDivisions, ...currentLevel }
      };
      return newState;
    case filterConstants.GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS:
      level = action.payload.level || 1;
      return setAdminDivisionLevelData(state, level, {}, action.payload.data || []);
    case filterConstants.GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL:
      level = action.payload.level || 1;
      return setAdminDivisionLevelData(state, level, {});
    case commonConstants.RESET:
      return initState;
    default:
      return state;
  }
}

const setAdminDivisionLevelData = (state, level, currentLevel, data = []) => {
  currentLevel[`level${level}`] = data;
  currentLevel[`loadingLvl${level}`] = false;
  currentLevel[`selectedLvl${level}`] = null;
  cleanLowAdminDivisionLevels(level, currentLevel);
  const newState = {
    ...state,
    adminDivisions: { ...state.adminDivisions, ...currentLevel }
  };
  return newState;
}

const cleanLowAdminDivisionLevels = (level, currentLevel) => {
  for (let i = level; i < 4; i++) {
    currentLevel[`level${i + 1}`] = [];
    currentLevel[`loadingLvl${i + 1}`] = false;
    currentLevel[`selectedLvl${i + 1}`] = null;
  }
}
