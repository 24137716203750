/**
 *
 * Auth saga file
 * Any action made for an projects should go here
 *
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import FinancialProgressService from '../../services/financial.progress.services';
import { financialProgressConstants } from '../constants/financial-progress-constants';

/**
 * Workers
 */

export function* createFinancialProgress(action) {
  const result = yield call(FinancialProgressService.createFinancialProgress, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: financialProgressConstants.CREATE_FINANCIAL_PROGRESS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: financialProgressConstants.CREATE_FINANCIAL_PROGRESS_FAIL,
    });
  }
}

export function* getFinancialProgresses(action) {
  const result = yield call(FinancialProgressService.getFinancialProgresses, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: financialProgressConstants.GET_FINANCIAL_PROGRESSES_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: financialProgressConstants.GET_FINANCIAL_PROGRESSES_FAIL,
    });
  }
}

/**
 * Watchers
 */

export function* createFinancialProgressRequest() {
  yield takeEvery(financialProgressConstants.CREATE_FINANCIAL_PROGRESS, createFinancialProgress);
}

export function* getFinancialProgressesRequest() {
  yield takeEvery(financialProgressConstants.GET_FINANCIAL_PROGRESSES, getFinancialProgresses);
}

/**
 * Export function
 */
function* financialProgressSaga() {
  // Watchers request
  yield all([createFinancialProgressRequest(), getFinancialProgressesRequest()]);
}

export default financialProgressSaga;
