import { useDispatch, useSelector } from 'react-redux';
import ContractualInfo from '../contractualInfo';
import { projectActions } from '../../../redux/actions/project-actions';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styles from './style';
import { withStyles } from '@mui/styles';
import { contractActions } from '../../../redux/actions/contract-actions';
import HeadCard from '../../../components/headCard';

const SecundaryContractualInfoComponent = ({ classes }) => {
  const dispatch = useDispatch();
  const projectId = useParams().id;
  const contractId = useParams().contractId;
  const project = useSelector(state => state.projects.project);
  const currentContract = useSelector(state => state.contracts.contract);
  const currentUser = useSelector(state => state.authentication.user);
  const { client_id: clientId } = currentUser || {};
  const { contract_object: contractObject, contract_number: contractNumber } = currentContract || {};

  const { name } = project || {};
  useEffect(() => {
    if (clientId && projectId) {
      dispatch(projectActions.getProjectDetails(clientId, projectId));
    }
  }, [projectId, contractId, clientId]);

  useEffect(() => {
    if (projectId && contractId) {
      dispatch(contractActions.getProjectContract(projectId, contractId));
    }
  }, [contractId, projectId]);

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' className={classes.breadCrumbs}>
        <Link underline='hover' color='inherit' href={`/project/${projectId}`}>
          {name}
        </Link>
        <Link underline='hover' color='inherit' href={`/project/info/${projectId}`}>
          <FormattedMessage id='projectInfo.contractualInformation' defaultMessage='Informacion contractual' />
        </Link>
        <Typography variant='body2'>{contractNumber}</Typography>
      </Breadcrumbs>
      <Grid container>
        <Grid item xs>
          {currentContract && (
            <HeadCard currentContract={currentContract} name={contractNumber} description={contractObject} />
          )}
        </Grid>
      </Grid>
      <ContractualInfo isMainContract={false} />
    </>
  );
};

export const SecundaryContractualInfo = withStyles(styles)(SecundaryContractualInfoComponent);
