import { taskConstants } from '../constants/task-constants';

const setProjectTask = (projectTaskLevelId, newTask) => ({
  type: taskConstants.SET_NEW_PROJECT_TASK,
  payload: { projectTaskLevelId, newTask },
});

const getProjectTasks = (projectTaskLevelId, type = null) => ({
  type: taskConstants.GET_PROJECT_TASK_LIST,
  payload: { projectTaskLevelId, type },
});

const getProjectParentTasks = (projectTaskLevelId, type = null) => ({
  type: taskConstants.GET_PROJECT_PARENT_TASK_LIST,
  payload: { projectTaskLevelId, type },
});

const getProjectTaskLevels = (projectId, type = null) => ({
  type: taskConstants.GET_PROJECT_TASK_LEVELS,
  payload: { projectId, type },
});

const createProjectTaskLevel = (params, projectId, type = null) => ({
  type: taskConstants.CREATE_PROJECT_TASK_LEVEL,
  payload: { params, projectId, type },
});

const updateProjectTask = (projectTaskLevelId, currentTask, updateTask) => ({
  type: taskConstants.UPDATE_PROJECT_TASK,
  payload: { projectTaskLevelId, currentTask, ...updateTask },
});

const updateProjectTaskStatus = (projectTaskLevelId, taskId, updateTask) => ({
  type: taskConstants.UPDATE_PROJECT_TASK_STATUS,
  payload: { projectTaskLevelId, taskId, ...updateTask },
});

const setTaskLog = (taskId, newLog) => ({
  type: taskConstants.SET_NEW_TASK_LOG,
  payload: { taskId, ...newLog },
});

const getTaskLogs = taskId => ({
  type: taskConstants.GET_TASK_LOGS,
  payload: { taskId },
});

const getProductsStateLogs = (productId, status) => ({
  type: taskConstants.GET_PRODUCT_STATUS_LOGS,
  payload: { productId, status },
});

const setProductStateLog = (productId, advanceId, status, comments) => ({
  type: taskConstants.SET_PRODUCT_STATUS_LOG,
  payload: { productId, advanceId, status, comments },
});


const downloadTaskLogsEvidences = (productId, id) => ({
  type: taskConstants.DOWNLOAD_TASK_LOGS_EVIDENCES,
  payload: { productId, id },
});

const updateTaskWeights = (importanceFactors, id) => ({
  type: taskConstants.UPDATE_TASK_WEIGHTS,
  payload: { importanceFactors, id },
});

export const taskActions = {
  getProjectTaskLevels,
  createProjectTaskLevel,
  setProjectTask,
  getProjectTasks,
  getProjectParentTasks,
  updateProjectTask,
  updateProjectTaskStatus,
  setTaskLog,
  getTaskLogs,
  getProductsStateLogs,
  setProductStateLog,
  downloadTaskLogsEvidences,
  updateTaskWeights
};
