import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, FormControl } from '@mui/material';
import { withStyles } from '@mui/styles';
import { projectUtil } from '../../../../utils/project.utils';
import { taskActions } from '../../../../redux/actions/task-actions';
import AddIcon from '@mui/icons-material/Add';
import BaseModal from './baseModal';
import styles from './styles';
import { ParentSelector } from './ParentSelector';
import { ContractSelector } from './ContractSelector';

const CreateTaskModal = ({ classes, project, level, parentLevel, defaultTask }) => {
  const dispatch = useDispatch();
  const { main_contract } = project;
  const { level_type: currentLevelType, id: projectTaskLevelId, name: levelName } = level;
  const { currency } = main_contract || { currency: 'USD' };
  const [newTask, setNewTask] = useState({ ...defaultTask });
  const [openCreateTask, setOpenCreateTask] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [selectGoalUnit, setSelectGoalUnit] = useState('');
  const [textGoalUnit, setTextGoalUnit] = useState(null);
  const [parentId, setParentId] = useState(defaultTask.parentId);
  const [contractId, setContractId] = useState(defaultTask.contractId);

  const restartNewTask = () => {
    setOpenCreateTask(false);
    setSelectGoalUnit(null);
    setTextGoalUnit(null);
    setNewTask({ ...defaultTask });
    setParentId(null);
    setContractId(null);
  };

  const handleActionButton = () => {
    dispatch(
      taskActions.setProjectTask(projectTaskLevelId, {
        ...newTask,
        ...{ budget: projectUtil.removeDecimalsFromAmount(newTask.budget) },
        ...{ parentId },
        ...{ contractId },
      })
    );
    restartNewTask();
  };

  const handleCancelButton = () => {
    restartNewTask();
  };

  const modalButton = (
    <Button
      variant='contained'
      className={`${classes.task}-button`}
      startIcon={<AddIcon />}
      onClick={() => setOpenCreateTask(true)}
    >
      <FormattedMessage id={`projectInfo.${levelName}.add`} defaultMessage={'ADD PRODUCT'} />
    </Button>
  );

  const isSubmitDisable = () => {
    const isContractIdValid = currentLevelType === 'three' && !contractId;
    const isParentIdValid = currentLevelType !== 'one' && !parentId;

    return !newTask.budget || isParentIdValid || isContractIdValid;
  };

  const changeContracts = currentContracts => {
    if (parentId) {
      setContracts(currentContracts);
    }
  };

  return (
    <BaseModal
      title={<FormattedMessage id={`projectInfo.${levelName}.add`} defaultMessage='Add new task' />}
      productName={<FormattedMessage id={`projectInfo.${levelName}`} defaultMessage="Task's name" />}
      currency={currency}
      newTask={newTask}
      setNewTask={setNewTask}
      modalButton={modalButton}
      openCreateTask={openCreateTask}
      setOpenCreateTask={setOpenCreateTask}
      selectGoalUnit={selectGoalUnit}
      setSelectGoalUnit={setSelectGoalUnit}
      textGoalUnit={textGoalUnit}
      setTextGoalUnit={setTextGoalUnit}
      textActionButton={<FormattedMessage id='projectInfo.product.confirm' defaultMessage={'Confirm'} />}
      handleActionButton={handleActionButton}
      handleCancelButton={handleCancelButton}
      disabled={isSubmitDisable()}
    >
      {currentLevelType !== 'one' && (
        <FormControl fullWidth>
          <ParentSelector
            parentLevel={parentLevel}
            parentId={parentId}
            setParentId={setParentId}
            onParentChange={changeContracts}
          />
        </FormControl>
      )}
      {currentLevelType === 'three' && (
        <FormControl fullWidth>
          <ContractSelector contracts={contracts} contractId={contractId} setContractId={setContractId} />
        </FormControl>
      )}
    </BaseModal>
  );
};

export default withStyles(styles)(CreateTaskModal);
