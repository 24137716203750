const styles = theme => ({
  progressbar: {
    flexFlow: 'column nowrap !important',
    rowGap: 10,

    '&__title': {
      fontWeight: 'bold',
    },

    '&__linear': {
      display: 'flex',
      columnGap: 10,
    },

    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.gray.main,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});

export default styles;
