import { useEffect, useRef, useState } from "react";

const useDelay = (value, timeOut) => {

    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, timeOut);
        return () => clearTimeout(handler);
    }, [value, timeOut]);

    return debouncedValue;
};

const usePrevious = (value) => {
    const ref = useRef();
    
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const hooksUtil = {
    useDelay,
    usePrevious
}
