import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { isAuthenticated, storageUser } from './utils/auth.helper';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from './redux/actions/auth-actions';
import PrivateRoute from './hocs/private-route';
import Layout from './hocs/layout';
import Login from './pages/login';
import Projects from './pages/projects';
import Project from './pages/project';
import ProjectInfo from './pages/project-info';
import { isEmpty } from 'lodash';
import { SecundaryContractualInfo } from './pages/project-info/secundaryContractualInfo';

const CapGreenRouter = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const isUserAuthenticated = !!isAuthenticated();

  useEffect(() => {
    if (isUserAuthenticated && isEmpty(currentUser)) {
      const user = storageUser();
      dispatch(authActions.setLoggedUser(user));
    }
  }, [dispatch, currentUser, isUserAuthenticated]);

  return (
    <Router>
      <Routes>
        <Route exact path='/login' element={<Login />} />
        <Route
          path='/'
          element={
            <PrivateRoute isLoggedIn={!!isUserAuthenticated}>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to='projects' />} />
          <Route path='projects' element={<Projects />} />
          <Route path='project/:id' element={<Project />} />
          <Route path='project/info/:id' element={<ProjectInfo />} />
          <Route path='projects/:id/contracts/:contractId' element={<SecundaryContractualInfo />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default CapGreenRouter;
