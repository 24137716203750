import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import { authActions } from '../../redux/actions/auth-actions';
import logo from '../../assets/images/logo.svg';
import Footer from '../../components/footer';

// Material components
import {
  AppBar,
  Button,
  Toolbar,
  Grid,
  Paper,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import styles from './styles';

const Login = ({ classes }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.authentication.user);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    if (!!currentUser) {
      let from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  }, [currentUser, navigate, location]);

  const handleLogin = () => {
    dispatch(authActions.login(username, password));
  };

  const handleUserChange = event => {
    event.preventDefault();
    setUsername(event.target.value);
  };

  const handlePasswordChange = event => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Fragment>
      <AppBar position='static' className={`${classes.root}__header`}>
        <Toolbar className={`${classes.root}__header__toolbar`}>
          <img src={logo} alt='Logo' className={`${classes.root}__logo--cap`} />
        </Toolbar>
      </AppBar>
      {/* <img src={clientLogo} alt="Logo" className={`${classes.root}__logo--client`} /> */}
      <Grid container className={`${classes.root}__content`}>
        <Grid item className={`${classes.root}__wrap`}>
          <Paper className={`${classes.root}__form`} elevation={0}>
            <FormControl className={`${classes.root}__input`}>
              <InputLabel htmlFor='userEmail' className={`${classes.root}__input__label`}>
                <FormattedMessage id='login.user' defaultMessage='User' />
              </InputLabel>
              <OutlinedInput
                id='userEmail'
                value={username}
                name='username'
                onChange={handleUserChange}
                className={`${classes.root}__input`}
              />
            </FormControl>

            <FormControl className={`${classes.root}__input`}>
              <InputLabel htmlFor='adornmentPassword' className={`${classes.root}__input__label`}>
                <FormattedMessage id='login.password' defaultMessage='Password' />
              </InputLabel>
              <OutlinedInput
                id='adornmentPassword'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                className={`${classes.root}__input`}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {loginError && (
              <FormLabel error>
                <FormattedMessage id='login.wrong_user_password' defaultMessage='Incorrect user or password' />
              </FormLabel>
            )}
            <FormattedMessage id='login' defaultMessage='Login'>
              {msg => (
                <Button
                  color='primary'
                  size='large'
                  variant='contained'
                  className={`${classes.root}__submit`}
                  onClick={handleLogin}
                >
                  {msg[0]}
                </Button>
              )}
            </FormattedMessage>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default withStyles(styles)(Login);
