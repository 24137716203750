const styles = theme => ({
  root: {
    width: '100%',
    paddingBottom: '5px',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    '&__tab': {
      textTransform: 'none !important',
      '&.Mui-selected': {
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRight: 'none !important',
      },
      '&:hover': {
        backgroundColor: `${theme.palette.gray.light} !important`,
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '&__divider': {
      border: `1px solid ${theme.palette.primary.dark} !important`,
      height: '14px !important',
      marginTop: '18px !important',
    },
  },
  toggleGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    '&-button': {
      textTransform: 'capitalize !important',
      border: 'none !important',
      borderRadius: '0 !important',
      color: `${theme.palette.primary.dark} !important`,
      width: '100%',

      '& .Mui-selected': {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },

    '&-divider': {
      borderColor: `${theme.palette.primary.dark} !important`,
    },
  },
});

export default styles;
