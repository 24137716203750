import { contractConstants } from '../constants/contract-constants';
import { set } from 'lodash';
import { commonConstants } from '../constants/common-constants';

const contractInitialState = {
  contract: {},
  contractList: [],
  loading: false,
  fail: false,
  importanceFactorsUpdated: false,
};

export default function contracts(state = contractInitialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case contractConstants.UPDATE_CONTRACTS_WEIGHTS:
      set(newState, 'importanceFactorsUpdated', false);
      set(newState, 'loading', true);
      return newState;
    case contractConstants.SET_NEW_PROJECT_CONTRACT:
    case contractConstants.GET_PROJECT_CONTRACT_LIST:
    case contractConstants.GET_PROJECT_CONTRACT:
    case contractConstants.UPDATE_PROJECT_CONTRACT:
    case contractConstants.CREATE_PROJECT_CONTRACT:
      set(newState, 'loading', true);
      return newState;

    case contractConstants.SET_NEW_PROJECT_CONTRACT_FAIL:
    case contractConstants.GET_PROJECT_CONTRACT_LIST_FAIL:
    case contractConstants.GET_PROJECT_CONTRACT_FAIL:
    case contractConstants.UPDATE_PROJECT_CONTRACT_FAIL:
    case contractConstants.CREATE_PROJECT_CONTRACT_FAIL:
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;

    case contractConstants.SET_NEW_PROJECT_CONTRACT_SUCCESS:
    case contractConstants.UPDATE_PROJECT_CONTRACT_SUCCESS:
    case contractConstants.CREATE_PROJECT_CONTRACT_SUCCESS:
    case contractConstants.GET_PROJECT_CONTRACT_SUCCESS:
      set(newState, 'contract', action.payload.data);
      set(newState, 'loading', false);
      return newState;

    case contractConstants.GET_PROJECT_CONTRACT_LIST_SUCCESS:
      set(newState, 'contractList', action.payload.data);
      set(newState, 'loading', false);
      return newState;
    case contractConstants.UPDATE_CONTRACTS_WEIGHTS_FAIL:
      set(newState, 'importanceFactorsUpdated', false);
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;
    case contractConstants.UPDATE_CONTRACTS_WEIGHTS_SUCCESS:
      set(newState, 'importanceFactorsUpdated', true);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;
    case commonConstants.RESET:
      return contractInitialState;
    default:
      return state;
  }
}
