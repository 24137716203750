import { Fragment } from 'react';
import { Autocomplete, CircularProgress, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { ArrowDropDown } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import styles from './styles';

const Dropdown = ({
  classes,
  idName,
  label,
  listItems = [],
  labelField = null,
  handleOnChange,
  hookValue,
  loading = false,
  customOptionProps = null,
  required = false,
  minWidth = 10,
}) => {
  const defaultProps = {
    options: listItems,
    getOptionLabel: option => {
      return option[labelField];
    },
  };

  return (
    <Autocomplete
      className={`${classes.root}`}
      style={{ minWidth: minWidth }}
      {...(customOptionProps || defaultProps)}
      id={idName}
      value={hookValue || null}
      onChange={(event, newValue) => {
        handleOnChange(newValue);
      }}
      disableClearable
      loading={loading}
      popupIcon={<ArrowDropDown color='action' />}
      renderInput={params => (
        <TextField
          className={`${classes.root}_textfield`}
          required={required}
          {...params}
          label={label}
          size='small'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

Dropdown.propType = {
  classes: PropTypes.object,
  idName: PropTypes.string,
  label: PropTypes.object,
  listItems: PropTypes.array,
  labelField: PropTypes.any,
  handleOnChange: PropTypes.func,
  hookValue: PropTypes.any,
  loading: PropTypes.bool,
  customOptionProps: PropTypes.object,
};

// Redux connection
export default withStyles(styles)(Dropdown);
