import API from './api.service';

export class ContractService {
  static async setContract({ data } = {}) {
    try {
      const response = await API(false, false).post(`projects/${data.projectId}/contracts`, { ...data });
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getContracts(data = {}) {
    try {
      const response = await API(false, false).get(`projects/${data.projectId}/contracts`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getContract(data = {}) {
    try {
      const response = await API(false, false).get(`projects/${data.projectId}/contracts/${data.contractId}`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async updateContract(data = {}) {
    try {
      const response = await API(false, false).put(`projects/${data.projectId}/contracts/${data.contractId}`, {
        ...data,
      });
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async createContract(data = {}) {
    try {
      const response = await API(false, false).post(`projects/${data.projectId}/contracts`, {
        ...data,
      });
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async updateContractsWeights(data = {}) {
    try {
      const { importanceFactors: contracts, productId } = data;

      let params = { contracts };
      const response = await API(false, false).patch(`products/${productId}/contracts/batch_importance_factor`, params);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }
}
export default ContractService;
