const styles = theme => ({
  root: {
    '&__header': {
      width: '100% !important',
      background: 'white !important',
      border: 'none',
      boxShadow: `inset 0 -7px 0 0 ${theme.palette.primary.light} !important`,

      '&__toolbar': {
        minHeight: 55
      }
    },
    '&__content': {
			flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
    },
    '&__header2': {
      background: 'white',
      border: 'none',
      boxShadow: `inset 0 -7px 0 0 ${theme.palette.primary.light}`,
      position: 'fixed',

      '&__toolbar': {
        minHeight: 55
      }
    },

    '&__logo': {
      '&--client': {
        maxHeight: 80,
        margin: 32
      },
      '&--cap': {
        maxHeight: 55
      }
    },
    '&__logo2': {
      '&--client2': {
        maxHeight: 65,
        margin: 32
      },
      '&--cap2': {
        maxHeight: 40,
        paddingRight: 20
      }
    },

    '&__input': {
      width: '100%',

      '&__label': {

        '&[data-shrink="true"]': {
          transform: 'translate(0px, -21px)'
        }
      }
    },

    '&__form': {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px 15px 45px',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 300,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 4,
    },

    '&__submit': {
      margin: '3em auto 0',
      width: '8em'
    }
  }
});

export default styles;
