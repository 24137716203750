import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Button,
  Popper,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Schedule } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import ProgressRow from '../progressRow';
import { physicalProgressActions } from '../../../redux/actions/physical-progress-actions';
import { financialProgressActions } from '../../../redux/actions/financial-progress-actions';

const ProgressBarPopper = ({
  contractId,
  anchorEl,
  progressKey,
  onClickHistory,
  color,
  classes,
  onClickAddProgress,
}) => {
  const dispatch = useDispatch();
  const progresses = useSelector(state => state[progressKey].progresses);
  const isLoading = useSelector(state => state[progressKey].loading);

  const progressDispatch = {
    physicalProgress: physicalProgressActions.getPhysicalProgresses,
    financialProgress: financialProgressActions.getFinancialProgresses,
  };

  useEffect(() => {
    if (contractId && progressKey) {
      dispatch(progressDispatch[progressKey](contractId, { pageSize: 3 }));
    }
  }, [contractId]);

  const open = Boolean(anchorEl);

  return (
    <Popper
      placement='bottom'
      open={open}
      anchorEl={anchorEl}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [60, 5],
            },
          },
        ],
      }}
      className={`${classes.progress}_popper`}
    >
      <Box className={`${classes.progress}_popper-box ${classes.progress}_popper-box_color_${color}`}>
        {isLoading ? (
          <Grid container alignItems='center' justifyContent='center'>
            <CircularProgress color='secondary' size={50} />
          </Grid>
        ) : isEmpty(progresses) ? (
          <Grid container alignItems='center' justifyContent='center' direction='column' padding={2}>
            <Grid item>
              <Schedule />
            </Grid>
            <Grid item>
              <FormattedMessage
                id='projectInfo.log.emptyProgresses'
                defaultMessage='No progress has been registered yet'
              />
            </Grid>
          </Grid>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell size='small'>
                    <FormattedMessage id='projectInfo.log.date' defaultMessage='Date' />
                  </TableCell>
                  <TableCell size='small'>
                    <FormattedMessage id='projectInfo.log.progress' defaultMessage='Progress' />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {progresses?.map(progress => (
                  <ProgressRow
                    key={progress.id}
                    effectiveDate={progress.effective_date}
                    formattedValue={progress.formatted_value}
                    full={false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Grid container justifyContent='center'>
          <Button
            variant='contained'
            className={classes.button}
            color='info'
            disableElevation={true}
            onClick={onClickAddProgress}
          >
            <FormattedMessage id='labelState.registerProgress' defaultMessage='REGISTRAR AVANCE' />
          </Button>
          {!isEmpty(progresses) && (
            <Button variant='outlined' className={classes.button} color='primary' onClick={onClickHistory}>
              <FormattedMessage id='labelState.showHistory' defaultMessage='VER HISTORIAL' />
            </Button>
          )}
        </Grid>
      </Box>
    </Popper>
  );
};

ProgressBarPopper.propTypes = {
  contractId: PropTypes.number,
  progressKey: PropTypes.string,
  anchorEl: PropTypes.object,
  onClickHistory: PropTypes.func,
  onClickAddProgress: PropTypes.func,
  color: PropTypes.string,
  classes: PropTypes.object,
};

export default ProgressBarPopper;
