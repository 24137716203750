const styles = theme => ({
  root: {
    '& .MuiInputBase-input': {
      width: '28px',
      fontSize: '14px',
    },
    '& .MuiInputLabel-asterisk': {
      fontSize: '14px',
    }
  },
});

export default styles;
