import { FormattedMessage } from 'react-intl';

export const mainContractLabels = {
  compensable_days: {
    label: (
      <FormattedMessage
        id='mainContractLabel.compensableDays'
        defaultMessage='Dias Compensables'
      />
    ),
  },
  contract_number: {
    label: (
      <FormattedMessage
        id='mainContractLabel.contractNumber'
        defaultMessage='Número de convenio'
      />
    ),
  },
  contract_object: {
    label: (
      <FormattedMessage
        id='mainContractLabel.contractObject'
        defaultMessage='Objeto de Contrato'
      />
    ),
  },
  contractor: {
    label: (
      <FormattedMessage
        id='mainContractLabel.contractor'
        defaultMessage='Organismo Ejecutor"'
      />
    ),
  },
  formatted_current_amount: {
    label: (
      <FormattedMessage
        id='mainContractLabel.currentAgreementAmount'
        defaultMessage='Monto Actual del Convenio'
      />
    ),
  },
  current_term_in_days: {
    label: (
      <FormattedMessage
        id='mainContractLabel.currentTermInDaysAgreement'
        defaultMessage='Termino actual en dias'
      />
    ),
  },
  current_termination_date: {
    label: (
      <FormattedMessage
        id='mainContractLabel.currentTerminationDateAgreement'
        defaultMessage='Fecha del último desembolso del convenio'
      />
    ),
  },
  formatted_original_amount: {
    label: (
      <FormattedMessage
        id='mainContractLabel.originalAgreementAmount'
        defaultMessage='Monto Original del Convenio'
      />
    ),
  },
  original_termination_date: {
    label: (
      <FormattedMessage
        id='mainContractLabel.originalTerminationDateAgreement'
        defaultMessage='Fecha original de Terminacion de Convenio'
      />
    ),
  },
  reference_number1: {
    label: (
      <FormattedMessage
        id='mainContractLabel.referenceNumber1'
        defaultMessage='Numero de Referencia 1'
      />
    ),
  },
  reference_number2: {
    label: (
      <FormattedMessage
        id='mainContractLabel.referenceNumber2'
        defaultMessage='Numero de Referencia 2'
      />
    ),
  },
  spent_days: {
    label: (
      <FormattedMessage
        id='mainContractLabel.spentDays'
        defaultMessage='Dias Transcuridos'
      />
    ),
  },
  start_date: {
    label: (
      <FormattedMessage
        id='mainContractLabel.startDateAgreement'
        defaultMessage='Fecha de Inicio'
      />
    ),
  },
  status: {
    label: (
      <FormattedMessage
        id='projectInfo.product.productFilter.status'
        defaultMessage='Estado'
      />
    ),
  },
};
