const styles = theme => ({
  card: {
    padding: '20px 10px 20px 80px',
    margin: 'auto',

    '&_name': {
      fontSize: 14,
      color: theme.palette.gray.dark,
      fontWeight: '400 !important',
    },
    '&_value': {
      color: theme.palette.gray.dark,
      fontWeight: 700,
    },
  },
});

export default styles;
