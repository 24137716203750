import { isEmpty } from 'lodash';
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { taskActions } from '../../../../redux/actions/task-actions';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

import styles from './styles';
import FileList from '../../../../components/fileList';
import ImageCarousel from '../../../../components/imageCarousel';
import TooltipZoom from '../../../../components/tooltipZoom';

const ApproveAdvanceModal = ({ classes, product }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const deliverableDone = product.status === 'done';
  const [openApproveAdvance, setOpenApproveAdvance] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAdvance, setCurrentAdvance] = useState(null);
  const [approvedStatus, setApprovedStatus] = useState('pending_for_approval');
  const [currentEvidences, setCurrentEvidences] = useState([]);
  const productLog = useSelector(state => state.tasks.taskLog);
  const productLogList = useSelector(state => state.tasks.taskLogList);
  const [comments, setComments] = useState(productLogList[productLogList.length - 1]?.rejection_comments);
  const loading = useSelector(state => state.tasks.loading);
  const { project_task_level_id: projectTaskLevelId } = product || {};
  const imageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'bmp', 'svg'];

  useEffect(() => {
    if (openApproveAdvance) {
      dispatch(taskActions.getTaskLogs(product.id));
    }
  }, [openApproveAdvance, productLog]);

  useEffect(() => {
    if (!isEmpty(productLogList)) {
      const advance = productLogList[currentIndex];
      const apiUrl = process.env.REACT_APP_API_URL.slice(0, -1);
      const currentFiles = getFilesImage(advance.evidences_paths).map((url, id) => ({
        url: `${apiUrl}${url}`,
        id,
      }));
      setCurrentAdvance(advance);
      setCurrentEvidences(currentFiles);
      setApprovedStatus(advance.status);
    }
  }, [productLogList, currentIndex]);

  const handleDownloadFile = evidenceId => {
    dispatch(taskActions.downloadTaskLogsEvidences(product.id, evidenceId));
  };

  const handleApproveChange = value => {
    setApprovedStatus(value);
  };

  const productStatus = () => {
    if (approvedStatus === 'approved' && !deliverableDone && !!currentAdvance.value_added) {
      return 'done';
    }
    if (approvedStatus === 'rejected' || !currentAdvance.value_added) {
      return 'not_done';
    }

    if (approvedStatus === 'approved' && !currentAdvance.value_added) {
      return 'not_done';
    }

    return 'in_progress';
  };

  const handleConfirmButton = () => {
    dispatch(taskActions.setProductStateLog(product.id, currentAdvance.id, approvedStatus, comments));

    const status = productStatus();

    if (status) {
      const params = {
        status,
        completedAt: status === 'done' ? new Date() : null,
      };

      dispatch(taskActions.updateProjectTaskStatus(projectTaskLevelId, product.id, params));
    }
  };

  const handleCancelButton = () => {
    setOpenApproveAdvance(false);
  };

  const evidencesFileName = paths => {
    return paths.map(file => ({ name: file.replace(/^.*(\\|\/|\:)/, '') }));
  };

  const getFileExtension = filename => filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);

  const getFilesImage = allFiles =>
    allFiles.filter(file => imageExtensions.includes(getFileExtension(file).toLowerCase()));

  return (
    <>
      <TooltipZoom
        title={<FormattedMessage id='projectInfo.approve.reviewDeliverable' defaultMessage='Review progress' />}
      >
        <MenuItem aria-label='open progress modal' onClick={() => setOpenApproveAdvance(true)}>
          {intl.messages['projectInfo.approve.reviewAdvance']}
        </MenuItem>
      </TooltipZoom>
      <Modal open={openApproveAdvance} onClose={() => setOpenApproveAdvance(false)}>
        <Grid className={`${classes.approve}_modal`}>
          {loading && (
            <Stack alignItems='center' justifyContent='center' width='100%' height='50%'>
              <CircularProgress size={100} />
            </Stack>
          )}
          {!loading && !!currentAdvance && (
            <>
              <Grid container className={`${classes.approve}_head`}>
                <Grid container alignContent='space-between' alignItems='center'>
                  <Grid item>
                    <Typography variant='h4' color='text.secondary'>
                      <FormattedMessage id='projectInfo.approve.review' defaultMessage='Review product progress' />
                    </Typography>
                  </Grid>
                  <Grid item marginLeft='auto'>
                    <IconButton
                      color='primary'
                      aria-label='close task modal'
                      onClick={() => setOpenApproveAdvance(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography variant='h6' color='text.primary'>
                  {product?.name}
                </Typography>
              </Grid>
              <Grid className={`${classes.approve}_advanceList_detail`}>
                <Grid className={`${classes.approve}_advanceList_detail_title`}>
                  <Typography variant='body2' color='gray.dark'>
                    <FormattedMessage id='projectInfo.approve.advanceDate' defaultMessage='Advance date' />
                  </Typography>
                  <Typography variant='subtitle1' color='gray.dark'>
                    {new Date(currentAdvance.value_added_at).toLocaleDateString(intl.locale)}
                  </Typography>
                </Grid>
                <Grid className={`${classes.approve}_advanceList_detail_title`}>
                  <Typography variant='body2' color='gray.dark'>
                    <FormattedMessage
                      id='projectInfo.advance.fulfillment'
                      defaultMessage='Has the objective been met?'
                    />
                  </Typography>
                  <Typography variant='subtitle1' color='gray.dark'>
                    {!!currentAdvance.value_added ? (
                      <FormattedMessage id='projectInfo.advance.yes' defaultMessage='Yes' />
                    ) : (
                      <FormattedMessage id='projectInfo.advance.no' defaultMessage='No' />
                    )}
                  </Typography>
                </Grid>
                <Grid className={`${classes.approve}_advanceList_detail_title`}>
                  <Typography variant='body2' color='gray.dark'>
                    <FormattedMessage id='projectInfo.product.description' defaultMessage='Description' />
                  </Typography>
                  <Typography variant='subtitle1' color='gray.dark'>
                    {currentAdvance.description}
                  </Typography>
                </Grid>
                <Grid className={`${classes.approve}_advanceList_detail_title`}>
                  <Typography variant='body2' color='gray.dark'>
                    <FormattedMessage id='projectInfo.advance.amount' defaultMessage='Amount' />
                  </Typography>
                  <Typography variant='subtitle1' color='gray.dark'>
                    {currentAdvance.amount}
                  </Typography>
                </Grid>
                {!!currentEvidences.length && (
                  <Card>
                    <ImageCarousel images={currentEvidences} />
                  </Card>
                )}
                {!isEmpty(currentAdvance.evidences_paths) && (
                  <FileList
                    headTitle={
                      <Typography variant='body2' color='gray.dark'>
                        <FormattedMessage id='projectInfo.advance.attachments' defaultMessage='Attachments' />{' '}
                      </Typography>
                    }
                    titleAll={<FormattedMessage id='projectInfo.approve.downloadAll' defaultMessage='DOWNLOAD ALL' />}
                    onClickAll={() => handleDownloadFile(currentAdvance.id)}
                    filesArray={evidencesFileName(currentAdvance.evidences_paths)}
                    icon={<DownloadIcon />}
                    onClickIcon={() => handleDownloadFile(currentAdvance.id)}
                  />
                )}
                <FormControl className={`${classes.advance}_label`}>
                  <RadioGroup
                    row
                    aria-labelledby='radio-buttons-group'
                    name='radio-buttons-group'
                    value={approvedStatus}
                    onChange={event => handleApproveChange(event.target.value)}
                  >
                    <FormControlLabel
                      className={`${classes.task}_radioButton`}
                      value={'approved'}
                      control={<Radio />}
                      label={
                        <Typography variant='subtitle2' color='gray.dark'>
                          <FormattedMessage id='projectInfo.approve.passed' defaultMessage='Passed' />
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      className={`${classes.task}_radioButton`}
                      value={'rejected'}
                      control={<Radio className={`${classes.task}_radio`} />}
                      label={
                        <Typography variant='subtitle2' color='gray.dark'>
                          <FormattedMessage id='projectInfo.approve.refused' defaultMessage='Refused' />
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  label={
                    <Typography variant='subtitle2' color='gray.dark'>
                      <FormattedMessage id='projectInfo.approve.comments' defaultMessage='Comments' />{' '}
                      <Typography component='span' color='red'>
                        *
                      </Typography>
                    </Typography>
                  }
                  value={comments}
                  onChange={event => setComments(event.target.value)}
                  multiline
                  rows={5}
                  focused
                />
              </Grid>
              <Grid container className={`${classes.task}_buttons`}>
                <Button
                  variant='contained'
                  onClick={handleConfirmButton}
                  disabled={approvedStatus === 'rejected' && !comments}
                >
                  <FormattedMessage id='projectInfo.approve.confirm' defaultMessage={'Confirm'} />
                </Button>
                <Button variant='outlined' onClick={handleCancelButton}>
                  <FormattedMessage id='projectInfo.approve.cancel' defaultMessage={'Cancel'} />
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Modal>
    </>
  );
};

export default withStyles(styles)(ApproveAdvanceModal);
