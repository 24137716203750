import React from 'react';
import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './styles';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { ProductRow } from './ProductRow';
import { DeliverableTableBody } from './DeliverableTableBody';

const Products = ({
  classes,
  project,
  products = [],
  type,
  currency,
  responsible,
  defaultTask,
  parentLevel,
  level,
}) => {
  const intl = useIntl();
  const { projectId, main_contract: mainContract } = project;
  const { contract_object: contractObject } = mainContract || {};
  const isDeliverable = level.name === 'deliverable';
  const tasksHeaders = intl.messages[`projectInfo.tasks.headers`];
  const deliverableHeaders = intl.messages[`projectInfo.deliverable.headers`];
  const getHeaders = () => {
    if (isDeliverable) {
      return [...tasksHeaders.slice(0, 8), ...deliverableHeaders, ...tasksHeaders.slice(8)];
    }

    if (!isDeliverable) {
      return tasksHeaders;
    }
  };
  const headers = getHeaders();
  const headerUI = (
    <TableHead className={`${classes.table}_head`}>
      <TableRow>
        {headers?.map((header, index) => {
          const label = { inputProps: { 'aria-label': 'Checkbox deliverable' } };
          const isFirstPosition = index === 0;
          const cellClassName = `${classes.table}-cell${isFirstPosition ? ` ${classes.table}-cell-sticky-left` : ''}${
            index === headers.length - 1 ? ` ${classes.table}-cell-sticky-right` : ''
          }`;

          return (
            <TableCell className={cellClassName} key={header}>
              {isFirstPosition && isDeliverable ? (
                <Grid container alignItems='center' width={120}>
                  <Grid item xs={5}>
                    <Checkbox {...label} className={`${classes.table}_head_checker`} />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant='h5'>{header}</Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant='h5'>{header}</Typography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );

  const bodyUI = (
    <TableBody>
      {products.map((product, index) => (
        <ProductRow
          key={index}
          product={product}
          classes={classes}
          index={index}
          currency={currency}
          responsible={responsible}
          defaultTask={defaultTask}
          type={intl.messages[`projectInfo.${type.toLowerCase()}`]}
          projectId={projectId}
          products={products}
          level={level}
          parentLevel={parentLevel}
        />
      ))}
    </TableBody>
  );

  const agreementHeadUI = (
    <TableHead className={`${classes.agreement}_head`}>
      <TableRow>
        <TableCell align='center' colSpan={2} className={`${classes.table}-cell-body-sticky-left-not-bg`}>
          <Typography textAlign='justify' className={`${classes.table}_description`}>
            {contractObject}
          </Typography>
        </TableCell>
        <TableCell align='right' colSpan={7} />
        <TableCell align='right' colSpan={1} />
        <TableCell
          colSpan={1}
          align='right'
          className={`${classes.table}-cell-body-sticky-right-not-bg ${classes.agreement}_head_bg`}
        >
          <div className={`${classes.agreement}_box ${classes.agreement}_head_bg_color`} />
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      <TableContainer component={Paper} className={`${classes.table}`}>
        <Table>
          {headerUI}
          {!isEmpty(products) && agreementHeadUI}
          {isDeliverable ? (
            <DeliverableTableBody
              classes={classes}
              currency={currency}
              responsible={responsible}
              defaultTask={defaultTask}
              projectId={projectId}
              type={type}
              parentLevel={parentLevel}
              level={level}
              products={products}
            />
          ) : (
            <> {!isEmpty(products) && bodyUI}</>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(styles)(Products);
