import { set } from 'lodash';
import { commonConstants } from '../constants/common-constants';
import { currencyConstants } from '../constants/currency-constants';

const currencyInitialState = {
  currencies: [],
  loading: false,
  fail: false,
};

export default function currencies(state = currencyInitialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case currencyConstants.GET_CURRENCIES:
      set(newState, 'loading', true);
      return newState;

    case currencyConstants.GET_CURRENCIES_FAIL:
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;

    case currencyConstants.GET_CURRENCIES_SUCCESS:
      set(newState, 'currencies', action.payload.data);
      set(newState, 'loading', false);
      return newState;

    case commonConstants.RESET:
      return currencyInitialState;
    default:
      return state;
  }
}
