/**
 *
 * Auth saga file
 * Any action made for an organization groups should go here
 *
 */
import { call, put, takeEvery, all } from "redux-saga/effects";
import { notify } from "../../components/toast-message";
import OrgGroupService from "../../services/org.group.service";
import { orgGroupConstants } from "../constants/org-group-constants";
/**
 * Workers
 */
export function* getTypes(action) {
  try {
   const result = yield call(OrgGroupService.getTypes, action.payload);
    if (!!result && result.status === 200) {
      const types = [];
      let parent = result.data;

      if (!parent) {
        return;
      }
      do {
        types.push({
          id: parent.id,
          name: parent.name,
          leaf: parent.leaf,
          isACountry: parent.is_a_country,
          parentId: parent.parent_id,
          controlledByUser: parent.controlled_by_user
        });

        yield put({
          type: orgGroupConstants.GET_ORG_GROUP,
          payload: {...action.payload, typeId: parent.id }
        });

        parent = parent.child;
      } while (parent);
      yield put({
        type: orgGroupConstants.GET_ORG_TYPES_SUCCESS,
        payload: { data: types }
      });
    } else {
      notify('Something was wrong, please try again later', 'error');
      yield put({ type: orgGroupConstants.GET_ORG_TYPES_FAIL });
    }
  } catch (error) {
    notify('Something was wrong, please try again later', 'error');
    yield put({ type: orgGroupConstants.GET_ORG_TYPES_FAIL });
  }
}

export function* getOrgGroup(action) {
  try {
    const result = yield call(OrgGroupService.getOrgGroupByType, action.payload);
    if (!!result && result.status === 200) {
      yield put({
        type: orgGroupConstants.GET_ORG_GROUP_SUCCESS,
        payload: { id: action.payload.typeId, data: result.data }
      });
    } else {
      notify('Something was wrong, please try again later', 'error');
      yield put({ type: orgGroupConstants.GET_ORG_GROUP_FAIL });
    }
  } catch (error) {
    notify('Something was wrong, please try again later', 'error');
    yield put({ type: orgGroupConstants.GET_ORG_GROUP_FAIL });
  }
}
  
/**
 * Watchers
 */
export function* getTypesRequest() {
  yield takeEvery(orgGroupConstants.GET_ORG_TYPES, getTypes);
}

export function* getOrgGroupRequest() {
  yield takeEvery(orgGroupConstants.GET_ORG_GROUP, getOrgGroup);
}

/**
 * Export function
 */
function* orgGroupsSaga() {
  // Watchers request
  yield all([getTypesRequest(), getOrgGroupRequest()]);
}

export default orgGroupsSaga;
