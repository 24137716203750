import { alpha } from '@mui/material/styles';

const styles = theme => ({
  task: {
    '&_head': {
      width: '100%',
      alignItems: 'center',
      '&-button': {
        justifyContent: 'flex-end !important',
        alignItems: 'flex-start !important',
      },
      '&-divider': {
        marginTop: '10px !important',
        borderColor: `${theme.palette.secondary.light} !important`,
      },
    },
    '&_body': {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'flex-start',
      padding: 0,
      gap: 15,
    },
    '&_title': {
      marginTop: '17px !important',
    },
    '&_buttons': {
      columnGap: '10px',
      marginTop: 'auto',
    },
    '&-modal': {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'center',
      padding: '20px 40px 40px',
      gap: 20,
      width: 500,
      background: theme.palette.gray.contrastText,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '&_logs': {
        width: 700,
      },
      '&_innerModal': {
        '& .MuiBackdrop-root': {
          backgroundColor: alpha(theme.palette.gray.dark, 0.1),
        },
      },
      '&_reject': {
        width: '300px !important',
      },
    },
    '&_label': {
      color: theme.palette.gray.main,
    },
    '&_radioButton': {
      height: 20,
    },
    '&_radio': {},
    '&_goal': {
      rowGap: '8px',
      columnGap: '15px',
      '&-value': {
        width: 80,
      },

      '&-unit': {
        width: 325,
      },
    },
    '&-placeholder': {
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '24px !important',
      color: `${theme.palette.gray.dark} !important`,
    },
    '&_progress': {
      flexDirection: 'column !important',
    },
    '&_progressContainer': {
      height: '15px',
    },
    '&-linearBar': {
      width: '128px',
    },
    '&_remaining': {
      columnGap: '5px',
      '&-value': {
        width: '33%',
      },
      '&-text': {
        alignSelf: 'end',
        fontSize: '12px !important',
        lineHeight: '16px !important',
      },
    },
  },
  infoData: {
    gap: 10,
  },
  table: {
    minWidth: 274,
    '& .MuiTableCell-root': {
      padding: 8,
    },
    '& .MuiTableHead-root': {
      borderBottom: `1.5px solid ${theme.palette.gray.dark}`,
      '& .MuiTableCell-root': {
        color: theme.palette.primary.dark,
      },
    },
    '& .MuiChip-root': {
      height: 23,
      width: 103,
      borderRadius: 4,
      fontSize: 10,
      fontWeight: 700,
      padding: 5,
      color: theme.palette.secondary.contrastText,
      background: theme.palette.secondary.main,
      '& .MuiChip-label': {
        margin: 0,
      },
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&_gallery': {
      width: 245,
      height: 353,
    },
  },
  advance: {
    '&-textField': {
      maxWidth: 167,
      '& .MuiOutlinedInput-input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
        },
      },
    },
    '&-divider': {
      borderColor: `${theme.palette.secondary.light} !important`,
    },
    '&_grid': {
      '&_advance': {
        alignItems: 'flex-end',
        columnGap: '10px',
      },
      '&_evidence': {
        flexDirection: 'column !important',
      },
      '&_link': {
        justifyContent: 'center',
        columnGap: '5px',
      },
      '&-icon': {
        color: `${theme.palette.gray.contrastText}`,
        background: `${theme.palette.gray.dark}`,
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '64px',
      },
      '&_button': {
        columnGap: '20px',
      },
    },
    '&_head': {
      gap: 5,
      marginBottom: 20,
    },
    '&-button': {
      alignSelf: 'end',
    },
    '&-progressContainer': {
      columnGap: '5px',
    },
    '&-progressState': {
      flexGrow: 1,
      alignSelf: 'center',
    },
    '&_label': {
      rowGap: '5px',
    },
    '&_labelFile': {
      width: '100%',
    },
    '&-buttonFile': {
      width: '100%',
      height: 35,
      justifyContent: 'space-between !important',
      color: `${theme.palette.gray.dark} !important`,
      fontSize: 12,
      fontWeight: '500 !important',
      lineHeight: '16px !important',
      border: `1px solid ${theme.palette.gray.main} !important`,
      '& .MuiButton-endIcon': {
        color: `${theme.palette.primary.main} !important`,
      },
      '&:hover': {
        border: `2px solid ${theme.palette.gray.dark} !important`,
      },
    },
  },
  approve: {
    '&_modal': {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'center',
      padding: '40px 40px 20px',
      gap: '20px',
      width: '600px',
      overflowY: 'auto',
      background: theme.palette.background.default,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&_head': {
      gap: 5,
      marginBottom: 20,
      flexDirection: 'column !important',
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    '&_label': {
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      maxWidth: 300,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
    },
    '&-progressContainer': {
      columnGap: '5px',
      alignItems: 'center',
    },
    '&-progressState': {
      flexGrow: 1,
      alignSelf: 'center',
    },
    '&-progressUnit': {
      marginRight: '61px !important',
    },
    '&-progressLabelCheck': {
      letterSpacing: '0.15px',
    },
    '&_advanceControl': {
      justifyContent: 'space-between',
      '&-remaining': {
        display: 'flex',
        gap: '5px',
      },
      '&-controls': {
        display: 'flex',
        gap: '20px',
      },
    },
    '&_advanceList': {
      width: '100%',
      display: 'flex',
      '&_table': {
        gap: '20px',
        width: '320px',
        '&-head': {
          '&-cell': {
            border: 'none !important',
            padding: '0px 0px 10px 0px !important',
          },
          '&-hidden': {
            border: 'none !important',
            padding: '0px 0px 10px 0px !important',
            width: '10px',
          },
        },
        '&-body': {
          '&-row': {
            cursor: 'pointer',
          },
          '&-cell': {
            padding: '15px 0px 15px 0px !important',
            borderTop: `1px solid ${theme.palette.secondary.light}`,
            borderLeft: 'none !important',
            borderRight: 'none !important',
            borderBottom: 'none !important',
          },
          '&-hidden': {
            border: 'none !important',
            padding: '0px 0px 10px 0px !important',
            width: '10px',
          },
          '&-selected': {
            width: '0',
            height: '0',
            display: 'flex !important',
            borderTop: '15px solid transparent',
            borderLeft: '1px solid transparent',
            borderRight: `10px solid ${theme.palette.secondary.background}`,
            borderBottom: '15px solid transparent !important',
            padding: '0px 0px 0px 0px !important',
            marginTop: '10px',
          },
        },
      },
      '&_detail': {
        display: 'flex',
        flexDirection: 'column !important',
        padding: '10px',
        gap: '10px',
        width: '100%',
        backgroundColor: theme.palette.secondary.background,
      },
    },
  },
});

export default styles;
