import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import styles from '../../../pages/project-info/contractualInfo/styles';
import DateInput from '../../dateField';
import { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import CurrencyInput from '../../currencyField';
import { useDispatch, useSelector } from 'react-redux';
import { physicalProgressActions } from '../../../redux/actions/physical-progress-actions';
import { financialProgressActions } from '../../../redux/actions/financial-progress-actions';
import { projectUtil } from '../../../../src/utils/project.utils';
import { projectActions } from '../../../redux/actions/project-actions';

const AddProgressesModal = ({ open, classes, onClose, contract, selectedProgress }) => {
  const {
    financial_progress_mode: financialProgressMode,
    currency,
    id: contractId,
    physical_progress: lastPhysicalProgressValue,
    financial_progress: lastFinancialProgressValue,
    financial_progress_formatted: financialProgressFormatted,
    physical_progress_formatted: physicalProgressFormatted,
  } = contract || {};

  const { color, key: selectedKey } = selectedProgress || {};
  const isFinancialProgress = selectedKey === 'financialProgress';

  const progresses = useSelector(state => state[selectedKey].progresses);
  const project = useSelector(state => state.projects.project);
  const projectId = project.id;
  const lastProgress = progresses[0];
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [comment, setComment] = useState(null);
  const [value, setValue] = useState(null);
  const currentUser = useSelector(state => state.authentication.user);

  const progressValue = isFinancialProgress ? lastFinancialProgressValue : lastPhysicalProgressValue;

  const { effective_date: lastEffectiveDate } = lastProgress || {};

  const dispatch = useDispatch();

  const closeUI = (
    <Grid container justifyContent='space-between' marginBottom={1}>
      <Grid item marginLeft='auto'>
        <IconButton color='primary' aria-label='close_modal' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const getCreationParams = () => {
    const previousProgress = isFinancialProgress ? lastFinancialProgressValue : lastPhysicalProgressValue;

    const cleanCurrentValue = () => {
      if (isFinancialProgress && financialProgressMode === 'amount') {
        return Number(projectUtil.removeDecimalsFromAmount(value.split(' ')[1])) * 100;
      }
      return Number(value);
    };

    const currentValue = cleanCurrentValue() + previousProgress;

    const params = {
      comment,
      effective_date: effectiveDate.toISOString(),
      responsible_id: currentUser.id,
    };

    if (isFinancialProgress) {
      return { ...params, ...{ value: Number(currentValue) } };
    }

    return { ...params, ...{ progress: currentValue > 100 ? 100 : currentValue } };
  };

  const handleSubmit = () => {
    const params = getCreationParams();
    if (isFinancialProgress) {
      dispatch(financialProgressActions.createFinancialProgress(contractId, params));
    } else {
      dispatch(physicalProgressActions.createPhysicalProgress(contractId, params));
    }
    dispatch(projectActions.getProjectDetails(currentUser.client_id, projectId));
  };

  const handleClick = () => {
    handleSubmit();
    onClose();
  };

  const areInputsValid = () => {
    return !!value && !!effectiveDate;
  };
  const buttonsUI = (
    <Grid container marginTop={1}>
      <Grid item marginRight={1} marginLeft='auto'>
        <Button variant='contained' color='info' onClick={handleClick} disabled={!areInputsValid()}>
          <Typography variant='button'>
            <FormattedMessage id='labelState.registerProgress' defaultMessage='REGISTRAR AVANCE' />
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button variant='outlined' color='info' onClick={onClose}>
          <Typography variant='button'>
            <FormattedMessage id='projectInfo.product.cancel' defaultMessage='Cancelar' />
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );

  const titleUI = (
    <Grid container justifyContent='space-between' margin='20px 0px 10px'>
      <Grid item>
        <Typography variant='subtitle1' color='GrayText'>
          <FormattedMessage id={`mainContractStory.Edit${selectedKey}`} defaultMessage='Editar Avance Financiero' />
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='mainContractStory.lastModification' defaultMessage=' Ultima Modificacion: ' />
          <Typography component='span' variant='subtitle1'>
            {!!lastEffectiveDate && (
              <FormattedDate value={lastEffectiveDate} month='short' year='numeric' day='2-digit' />
            )}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );

  const dateInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <DateInput
        classes={classes}
        value={effectiveDate}
        onChange={setEffectiveDate}
        label={<FormattedMessage id='mainContractStory.progressDate' defaultMessage='Fecha del avance' />}
      />
    </Grid>
  );

  const dividerUI = (
    <Grid container>
      <Divider className={`${classes.divider}_modal`} />
    </Grid>
  );

  const textAreaUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <TextField
        label={<FormattedMessage id='projectInfo.approve.comments' defaultMessage='Comments' />}
        value={comment}
        onChange={event => setComment(event.target.value)}
        multiline
        placeholder='Agregar comentario'
        rows={5}
      />
    </Grid>
  );

  const percentageInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <TextField
        required
        type='number'
        size='small'
        label={<FormattedMessage id='mainContractStory.progressPercentage' defaultMessage='Porcentaje del avance' />}
        value={value}
        onChange={e => setValue(e.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position='end'>%</InputAdornment>,
          min: 0,
          maxLength: 12,
        }}
        onKeyPress={event => {
          if (event?.key === '-' || event?.key === '+') {
            event.preventDefault();
          }
        }}
      />
    </Grid>
  );

  const currencyInputUI = (
    <Grid container className={`${classes.modal}_input_container`}>
      <CurrencyInput
        label={<FormattedMessage id='mainContractStory.amount' defaultMessage='Monto' />}
        prefix={currency}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </Grid>
  );

  const inputsUI = (
    <>
      {dateInputUI}
      {financialProgressMode === 'amount' && isFinancialProgress ? currencyInputUI : percentageInputUI}
      {textAreaUI}
    </>
  );

  const progressBarUI = (
    <Grid container justifyContent='space-between' alignItems='baseline'>
      <Grid container width='85%'>
        <LinearProgress
          value={progressValue}
          variant='determinate'
          className={`${classes.progress}_linear-progress ${classes.progress}_linear-progress_color_${color}`}
        />
      </Grid>
      <Grid item>
        <Typography variant='subtitle1'>
          {isFinancialProgress ? financialProgressFormatted : physicalProgressFormatted}
        </Typography>
      </Grid>
    </Grid>
  );

  const modalContentUI = (
    <Grid container className={`${classes.modal}_body`} padding='20px 40px'>
      {closeUI}
      {titleUI}
      {progressBarUI}
      {dividerUI}
      {inputsUI}
      {buttonsUI}
    </Grid>
  );

  return (
    <Modal open={open} onClose={onClose} className={`${classes.modal}_container`}>
      {modalContentUI}
    </Modal>
  );
};

AddProgressesModal.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.object,
  contract: PropTypes.object,
  onClose: PropTypes.func,
  selectedProgress: PropTypes.object,
};

export default withStyles(styles)(AddProgressesModal);
