import { Chip, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import LinearProgress from '../../../../components/linearProgress';
import { projectUtil } from '../../../../utils/project.utils';
import { statuses } from './contract_statuses';

// Styles
import styles from '../../../../components/labelState/styles';

const MainContractInfo = ({ classes, mainContract }) => {
  const {
    status,
    financial_progress: financialProgress,
    physical_progress: physicalProgress,
    formatted_current_amount: formattedCurrentAmount,
    start_date: startDate,
    current_termination_date: currentTerminationDate,
  } = mainContract;
  const current_status = statuses[status] || {};
  const percent = projectUtil.calcExecutionPercentage(startDate, currentTerminationDate);

  return (
    <>
      <Chip
        className={`${classes.chip}${current_status.selector}`}
        label={<FormattedMessage id={current_status.id} defaultMessage={current_status.value} />}
      />
      <LinearProgress
        title={<FormattedMessage id='labelState.financialProgress' defaultMessage={'Financial Progress'} />}
        value={financialProgress}
      />
      <LinearProgress
        title={<FormattedMessage id='labelState.physicalProgress' defaultMessage='Avance Físico' />}
        value={physicalProgress}
      />
      <LinearProgress
        title={<FormattedMessage id='labelState.executionPeriod' defaultMessage='Periodo de Ejecucion' />}
        value={!!percent ? percent : 0}
      />
      <>
        <Grid className={`${classes.amount}__title`}>
          <FormattedMessage id='labelState.amountAgreement' defaultMessage='Monto Actual del Convenio' />
        </Grid>
        <Grid className={`${classes.amount}-value`}>{formattedCurrentAmount}</Grid>
      </>
    </>
  );
};

export default withStyles(styles)(MainContractInfo);
