import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const DateInput = ({ classes, value, onChange, label }) => {
  const intl = useIntl();
  const formatDate = intl.messages['projectInfo.product.dateFormat'];
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        className={classes.datePicker}
        inputFormat={formatDate}
        onChange={newValue => {
          onChange(newValue);
        }}
        onError={() => onChange(null)}
        renderInput={params => <TextField {...params} size='small' required />}
        components={{
          OpenPickerIcon: CalendarTodayIcon,
        }}
      />
    </LocalizationProvider>
  );
};

DateInput.propType = {
  classes: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default DateInput;
