export const statuses = {
  preparation: {
    id: 'labelState.toDo',
    selector: '_status-toDo',
    value: 'in_negotiation',
  },
  in_progress: {
    id: 'labelState.inProgress',
    selector: '_status-inProgress',
    value: 'in_progress',
  },
  finished: {
    id: 'labelState.complete',
    selector: '_status-complete',
    value: 'finished',
  },
  suspended: {
    id: 'labelState.suspended',
    selector: '_status-suspended',
    value: 'suspended',
  },
};
