const styles = theme => ({
  progress: {
    '&_label__container': {
      marginTop: 10,
      color: theme.palette.gray.dark,
      fontSize: 14,
      fontWeight: 700,

      '&--openable': {
        cursor: 'pointer',
      },

      '& .MuiGrid-item:first-of-type': {
        marginRight: 'auto',
        marginBottom: '.5em',
      },

      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    '&_linear-progress': {
      borderRadius: '0px !important',
      height: '4px !important',
      marginTop: 10,
      '&_color': {
        '&_other_dark': {
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.other.dark,
          },
        },
        '&_secondary_dark': {
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
        '&_primary_dark': {
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
    },
    '&_popper': {
      zIndex: 1,
    },
    '&_popper-box': {
      minWidth: 300,
      padding: '10px 12.5px',
      color: theme.palette.gray.dark,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: 4,
      '& .MuiChip-root': {
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
      },
      '&_color': {
        '&_other_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.other.dark,
          },
        },
        '&_secondary_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
        '&_primary_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
    },
  },
  button: {
    lineHeight: '24px !important',
    margin: '10px 5px 0 !important',
    padding: '10px !important',
    fontWeight: 500,
    fontSize: 14,
    textTransform: 'uppercase !important',
  },
});

export default styles;
