import API from "./api.service";

export class FilterService {
  static async getCountries(data = {}) {
    try {
      const response = await API().get(`clients/${data.clientId}/countries`);
      return {
        data: response.data,
        status: response.status
      };
    } catch (e) {
      console.error("e", e);
      return { status: e.status };
    }
  }

  static async getCfFilters(data = {}) {
    try {
      let path = `/clients/${data.clientId}/cf_definitions/project_filters`;
      path = (!!data.formularyId && `${path}?formulary_id=${data.formularyId}`) || path;
      path = !!data.orgGroupId
        ? (!!data.formularyId && `${path}&org_group_id=${data.orgGroupId}`) || `${path}?org_group_id=${data.orgGroupId}`
        : path;
      const response = await API().get(path);
      return {
        data: response.data,
        status: response.status
      };
    } catch (e) {
      console.error("e", e);
      return { status: e.status };
    }
  }

  static async getFilteredAdministrativeDivisions(data = {}) {
    try {
      let path = `countries/${data.countryId}/administrative_divisions?level=${data.level}`;
      path = !!data.selectedId ? `${path}&parent_id=${data.selectedId}` : path;
      const response = await API().get(path);
      return {
        data: response.data,
        status: response.status
      };
    } catch (e) {
      console.error("e", e);
      return { status: e.status };
    }
  }
}

export default FilterService;
