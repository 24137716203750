import { useState } from 'react';

export const CollapsibleRow = ({ ParentComponent, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ParentComponent {...{ open, setOpen }} />
      {open && children}
    </>
  );
};
