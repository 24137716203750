const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    paddingLeft: 10,
    paddingRight: 10,
    '&-link': {
      fontSize: 14,
      flexBasis: '100%',
      textDecorationLine: 'underline',
      color: theme.palette.primary.main,
    },

    '&-divider': {
      width: '100%',
      borderColor: `${theme.palette.secondary.light} !important`,
    },

    '&_table': {
      '&_body': {},
      '&_row': {
        '&_cell': {
          gap: 10,
          display: 'flex !important',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.secondary.background} !important`,
          fontWeight: `${theme.typography.fontWeightRegular} !important`,
          fontSize: '14px !important',
          color: `${theme.palette.gray.dark} !important`,
          '&_openMap': {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            color: theme.palette.statusLevel.TODO,
            textDecoration: 'underline',
          },
        },
        '&:last-child td, &:last-child th': {
          borderBottom: 'none !important',
        },
      },
    },

    '&_standarTable': {
      '&_row': {
        '&_cell': {
          gap: 10,
          display: 'flex !important',
          alignItems: 'center',
          border: 'none !important',
          fontWeight: `${theme.typography.fontWeightRegular} !important`,
          fontSize: '14px !important',
          color: `${theme.palette.gray.dark} !important`,
        },
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.gray.light,
        },
        '&:last-child td, &:last-child th': {
          borderBottom: `1px solid ${theme.palette.secondary.background} !important`,
        },
      },
    },

    '&_standarTable1': {
      '&_row': {
        '&_cell': {
          gap: 10,
          display: 'flex !important',
          alignItems: 'center',
          border: 'none !important',
          fontWeight: `${theme.typography.fontWeightRegular} !important`,
          fontSize: '14px !important',
          color: `${theme.palette.gray.dark} !important`,
        },
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.gray.light,
        },
        '&:first-child td, &:first-child th': {
          borderTop: `1px solid ${theme.palette.secondary.light} !important`,
        },
        '&:last-child td, &:last-child th': {
          borderBottom: `1px solid ${theme.palette.secondary.light} !important`,
        },
      },
    },

    '&_customTable': {
      '&_body': {},
      '&_row': {
        '&_cell': {
          gap: 10,
          display: 'flex !important',
          alignItems: 'center',
          border: 'none !important',
          fontWeight: `${theme.typography.fontWeightRegular} !important`,
          fontSize: '14px !important',
        },
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.gray.light,
        },
        '&:last-child td, &:last-child th': {
          borderBottom: `1px solid ${theme.palette.secondary.background} !important`,
        },
      },
    },

    '&_body': {
      '&_details': {
        '&_values': {
          padding: 10,
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          rowGap: 10,
          borderRadius: 4,
          width: '100%',

          '&-title': {
            width: '100%',
            paddingLeft: 10,
            marginBottom: 10,
            fontSize: '14px !important',
            color: `${theme.palette.gray.dark} !important`,
          },

          '&-name': {
            fontSize: '12px !important',
            lineHeight: '16px !important',
            color: `${theme.palette.gray.dark} !important`,
          },

          '&-value': {
            fontSize: '14px !important',
            lineHeight: '19px !important',
            color: `${theme.palette.text.primary} !important`,
          },
        },
      },
    },

    '&-ligthGreenChip': {
      fontSize: '12px !important',
      fontWeight: theme.typography.fontWeightRegular,
      height: '25px !important',
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },

    '&-greenChip': {
      fontSize: '12px !important',
      fontWeight: theme.typography.fontWeightRegular,
      height: '25px !important',
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },

    '&-darkGrayChip': {
      fontSize: '12px !important',
      fontWeight: theme.typography.fontWeightRegular,
      height: '25px !important',
      backgroundColor: `${theme.palette.gray.dark} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
  infoData: {
    gap: 20,
  },
  task: {
    '&_title': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      fontWeight: '500',
      marginBottom: 20,
    },
    '&-button': {
      backgroundColor: `${theme.palette.statusLevel.TODO} !important`,
    },
    '&-button1': {
      color: `${theme.palette.statusLevel.TODO} !important`,
    },
    '&-modal': {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'flex-start',
      padding: 20,
      gap: 20,
      width: 316,
      height: 660,
      background: theme.palette.background.default,
      borderRadius: 4,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&-textField': {
      width: 276,
      '& .Mui-focused': {
        color: `${theme.palette.text.primary} !important`,
        fontWeight: '600 !important',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.gray.dark,
        },
      },
    },
    '&_label': {
      fontSize: '12px !important',
      fontWeight: '600 !important',
      color: `${theme.palette.text.primary} !important`,
    },
    '&_radioButton': {
      height: 20,
    },
    '&_goal': {
      display: 'flex',
      justifyContent: 'space-between',
      '&-value': {
        width: '30%',
        '& .Mui-focused': {
          color: `${theme.palette.text.primary} !important`,
          fontWeight: '600 !important',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.gray.dark,
          },
        },
      },
      '&-unit': {
        width: '60%',
        '& .Mui-focused': {
          color: `${theme.palette.text.primary} !important`,
          fontWeight: '600 !important',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.gray.dark,
          },
        },
      },
    },
  },
  toggleGroup: {
    '&-button': {
      color: `${theme.palette.gray.dark} !important`,
      border: 'none !important',
      borderRadius: '0 !important',
      '&.Mui-selected': {
        backgroundColor: `${theme.palette.secondary.background} !important`,
        borderTop: `1px solid ${theme.palette.secondary.background} !important`,
        borderLeft: `1px solid ${theme.palette.secondary.background} !important`,
        borderRight: `1px solid ${theme.palette.secondary.background} !important`,
        borderBottom: `3px solid ${theme.palette.secondary.light} !important`,
      },
    },
  },
});

export default styles;
