/**
 *
 * Auth saga file
 * Any action made for an filters should go here
 *
 */
import { call, put, takeEvery, all } from "redux-saga/effects";
import { notify } from "../../components/toast-message";
import FilterService from "../../services/filter.service";
import { filterConstants } from "../constants/filter-constants";
 
 /**
  * Workers
  */
export function* getCountries(action) {
  try {
    const result = yield call(FilterService.getCountries, action.payload);
    if (!!result && result.status === 200) {
      yield put({
        type: filterConstants.GET_COUNTRIES_SUCCESS,
        payload: { data: result.data }
      });
    } else {
      notify('Something was wrong, please try again later', 'error');
      yield put({ type: filterConstants.GET_COUNTRIES_FAIL });
    }
  } catch (error) {
    notify('Something was wrong, please try again later', 'error');
    yield put({ type: filterConstants.GET_COUNTRIES_FAIL });
  }
}

export function* getCfFilters(action) {
  try {
    const result = yield call(FilterService.getCfFilters, action.payload);
    if (!!result && result.status === 200) {
      yield put({
        type: filterConstants.GET_CUSTOM_FIELD_FILTERS_SUCCESS,
        payload: { ...action.payload, data: result.data }
      });
    } else {
      notify('Something was wrong, please try again later', 'error');
      yield put({ type: filterConstants.GET_CUSTOM_FIELD_FILTERS_FAIL });
    }
  } catch (error) {
    notify('Something was wrong, please try again later', 'error');
    yield put({ type: filterConstants.GET_CUSTOM_FIELD_FILTERS_FAIL });
  }
}

export function* getAdminDivisions(action) {
  try {
    const result = yield call(FilterService.getFilteredAdministrativeDivisions, action.payload);
    if (!!result && result.status === 200) {
      yield put({
        type: filterConstants.GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS,
        payload: { ...action.payload, data: result.data }
      });
    } else {
      notify('Something was wrong, please try again later', 'error');
      yield put({ type: filterConstants.GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL });
    }
  } catch (error) {
    notify('Something was wrong, please try again later', 'error');
    yield put({ type: filterConstants.GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL });
  }
}
   
 /**
  * Watchers
  */
export function* getCountriesRequest() {
  yield takeEvery(filterConstants.GET_COUNTRIES, getCountries);
}

export function* getCfFiltersRequest() {
  yield takeEvery(filterConstants.GET_CUSTOM_FIELD_FILTERS, getCfFilters);
}

export function* getAdminDivisionsRequest() {
  yield takeEvery(filterConstants.GET_FILTERED_ADMINISTRATIVE_DIVISIONS, getAdminDivisions);
}
 
 /**
  * Export function
  */
function* filterSaga() {
  // Watchers request
  yield all([getCountriesRequest(), getCfFiltersRequest(), getAdminDivisionsRequest()]);
}
 
export default filterSaga;
