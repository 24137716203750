import { set } from 'lodash';
import { commonConstants } from '../constants/common-constants';
import { physicalProgressConstants } from '../constants/physical-progress-constants';

const physicalProgressInitialState = {
  progress: {},
  progresses: [],
  pageSize: 24,
  page: 1,
  total: undefined,
  totalPages: 0,
  loading: false,
  fail: false,
};

export default function physicalProgresses(state = physicalProgressInitialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case physicalProgressConstants.CREATE_PHYSICAL_PROGRESS:
    case physicalProgressConstants.GET_PHYSICAL_PROGRESSES:
      set(newState, 'loading', true);
      return newState;

    case physicalProgressConstants.CREATE_PHYSICAL_PROGRESS_FAIL:
    case physicalProgressConstants.GET_PHYSICAL_PROGRESSES_FAIL:
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;

    case physicalProgressConstants.CREATE_PHYSICAL_PROGRESS_SUCCESS:
      set(newState, 'progress', action.payload.data);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    case physicalProgressConstants.GET_PHYSICAL_PROGRESSES_SUCCESS:
      set(newState, 'progresses', action.payload.data.physical_progresses);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    case commonConstants.RESET:
      return physicalProgressInitialState;
    default:
      return state;
  }
}
