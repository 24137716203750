export const contractModificationConstants = {
  CREATE_CONTRACT_MODIFICATION: 'CREATE_CONTRACT_MODIFICATION',
  CREATE_CONTRACT_MODIFICATION_SUCCESS: 'CREATE_CONTRACT_MODIFICATION_SUCCESS',
  CREATE_CONTRACT_MODIFICATION_FAIL: 'CREATE_CONTRACT_MODIFICATION_FAIL',

  GET_CONTRACT_MODIFICATIONS: 'GET_CONTRACT_MODIFICATIONS',
  GET_CONTRACT_MODIFICATIONS_SUCCESS: 'GET_CONTRACT_MODIFICATIONS_SUCCESS',
  GET_CONTRACT_MODIFICATIONS_FAIL: 'GET_CONTRACT_MODIFICATIONS_FAIL',

  GET_CONTRACT_MODIFICATION_JUSTIFICATIONS: 'GET_CONTRACT_MODIFICATION_JUSTIFICATIONS',
  GET_CONTRACT_MODIFICATION_JUSTIFICATIONS_SUCCESS: 'GET_CONTRACT_MODIFICATION_JUSTIFICATIONS_SUCCESS',
  GET_CONTRACT_MODIFICATION_JUSTIFICATIONS_FAIL: 'GET_CONTRACT_MODIFICATION_JUSTIFICATIONS_FAIL',
};
