import { get } from 'lodash';
import { createTheme } from '@mui/material/styles';

/**
 * Colors palette definition
 * https://material-ui.com/customization/themes/
 */
const palette = {
  primary: {
    light: '#E5F2FB',
    main: '#4A90E2',
    dark: '#004D71',
    contrastText: '#FFFFFF',
    background: '#E5F2FB',
  },
  secondary: {
    light: '#67E389',
    main: '#43BC64',
    dark: '#32612D',
    contrastText: '#FFFFFF',
    background: '#E6F9DC',
  },
  error: {
    light: '#EF5350',
    main: '#F13434',
    dark: '#C62828',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#FF9800',
    main: '#F1AA34',
    dark: '#E65100',
    contrastText: '#FFFFFF',
  },
  gray: {
    light: '#F2F2F2', // Light blue
    main: 'rgba(109, 109, 109, 0.2)',
    dark: '#6D6D6D',
    contactGroup: '#DBDBDB',
    productGroup: '#E0E0E0',
    deliverableTask: '#D1D1D1',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#000000',
    secondary: '#6D6D6D',
    disabled: '#F2F2F2',
  },

  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF',
    transparent: 'rgba(0, 0, 0, 0)',
  },

  // status colors
  statusLevel: {
    TODO: '#4A90E2',
    IN_PROGRESS: '#F1AA34',
    PENDING: '#E29624',
    COMPLETE: '#43BC64',
    CANCEL: '#F13434',
  },
  other: {
    dark: '#5F3D07',
  },
};

/**
 * Typography definition
 * https://material-ui.com/style/typography/
 */
const typography = {
  fontFamily: ['Nunito'].join(','),
  fontSize: 12,
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 800,
  fontWeightBold: 900,

  h4: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '27px',
  },

  h5: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.17px',
  },

  h6: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
  },

  subtitle1: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },

  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
  },

  body1: {
    fontWeight: '700 !important',
    fontSize: '12px !important',
    lineHeight: '16px',
    letterSpacing: '0.15px',
  },

  body2: {
    fontWeight: '500 !important',
    fontSize: '12px !important',
    lineHeight: '14px',
    letterSpacing: '0.15px',
  },
};

const shape = {
  borderRadius: '4px',
};

const components = {
  MuiSelect: {
    styleOverrides: {
      iconOutlined: {
        fill: `${get(palette, 'gray.dark')}`,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      body2: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: `${get(palette, 'text.primary')}`,
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: '15px',
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        width: '100%',
        height: '4px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        lineHeight: '24px',
        color: `${get(palette, 'gray.dark')} !important`,
        letterSpacing: '0.15px',
        fontWeight: 700,
      },
      asterisk: { color: `${get(palette, 'error.main')}` },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '24px',
        '&.Mui-focused fieldset': {
          border: `2px solid ${get(palette, 'gray.dark')} !important`,
        },
        '&:hover fieldset': {
          border: `2px solid ${get(palette, 'gray.dark')} !important`,
        },
      },
      input: {
        '&::placeholder': {
          opacity: 1,
          color: `${get(palette, 'gray.dark')} !important`,
        },
      },
      notchedOutline: {
        border: `1px solid ${get(palette, 'gray.dark')} !important`,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        height: 42,
        '&.Mui-disabled': {
          color: `${get(palette, 'gray.contrastText')}`,
          backgroundColor: `${get(palette, 'gray.main')}`,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        color: `${get(palette, 'gray.dark')}`,
      },
      inputSizeSmall: {
        height: '23px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        fontSize: '12px !important',
      },
      sizeLarge: {
        fontSize: '16px !important',
      },
    },
    variants: [
      {
        props: { variant: 'default' },
        style: {
          color: `${get(palette, 'gray.dark')}`,
        },
      },
    ],
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        color: `${get(palette, 'gray.dark')}`,
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeLarge: {
        fontSize: '24px',
      },
      fontSizeMedium: {
        fontSize: '20px',
      },
    },
  },
};

const theme = createTheme({
  components,
  palette,
  shape,
  typography,
});

export default theme;
