import PropTypes from 'prop-types';
import { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import styles from './styles';
import ProgressHistoryModal from '../progressHistoryModal';
import ProgressBar from '../progressBar';
import AddProgressesModal from '../addProgressesModal';
import { isEmpty } from 'lodash';

const ContractProgressBars = ({ classes, contract, columns = false }) => {
  const {
    physical_progress: physicalProgress,
    financial_progress: financialProgress,
    financial_progress_formatted: financialProgressFormatted,
    physical_progress_formatted: physicalProgressFormatted,
    execution_progress_formatted: executionProgressFormatted,
    execution_progress: executionProgress,
    progresses_from_project_task_levels: progressesFromProjectTaskLevels
  } = contract || {};

  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [selectedProgress, setSelectedProgress] = useState(null);
  const [addProgressModalOpen, setAddProgressModalOpen] = useState(false);
  const hasContract = !isEmpty(contract);


  const progressData = [
    {
      key: 'financialProgress',
      hasPopper: hasContract,
      value: financialProgress,
      formattedValue: financialProgressFormatted,
      color: 'other_dark',
    },
    {
      key: 'physicalProgress',
      value: physicalProgress,
      formattedValue: physicalProgressFormatted,
      hasPopper: hasContract,
      color: 'secondary_dark',
    },
    {
      key: 'executionPeriod',
      value: executionProgress,
      formattedValue: executionProgressFormatted,
      hasPopper: false,
      color: 'primary_dark',
    },
  ];

  const handleClickHistory = progressType => {
    setSelectedProgress(progressType);
    setOpenHistoryModal(true);
  };

  const handleAddProgress = progressType => {
    setSelectedProgress(progressType);
    setAddProgressModalOpen(true);
  };

  const barsMapper = (
    <>
      {progressData.map(progressType => {
        const { key, value, formattedValue, hasPopper, color } = progressType;
        const handleClickHistoryWrapper = () => handleClickHistory(progressType);
        const handleAddProgressWrapper = () => handleAddProgress(progressType);

        return (
          <ProgressBar
            key={key}
            contractId={contract?.id}
            progressKey={key}
            value={value}
            formattedValue={formattedValue}
            hasPopper={progressesFromProjectTaskLevels ? false : hasPopper}
            color={color}
            onClickHistory={handleClickHistoryWrapper}
            onClickAddProgress={handleAddProgressWrapper}
            classes={classes}
          />
        );
      })}
    </>
  );

  return (
    <>
      {columns ? (
        <Grid container spacing={1} direction='column'>
          {barsMapper}
        </Grid>
      ) : (
        <Grid container spacing={15}>
          {barsMapper}
        </Grid>
      )}
      {openHistoryModal && (
        <ProgressHistoryModal
          open={openHistoryModal}
          contractId={contract.id}
          progressKey={selectedProgress.key}
          value={selectedProgress.value}
          color={selectedProgress.color}
          openAddProgressModal={() => handleAddProgress(selectedProgress)}
          onClose={() => setOpenHistoryModal(false)}
        />
      )}
      {!!selectedProgress && (
        <AddProgressesModal
          classes={classes}
          open={addProgressModalOpen}
          onClose={() => setAddProgressModalOpen(false)}
          contract={contract}
          selectedProgress={selectedProgress}
        />
      )}
    </>
  );
};

ContractProgressBars.propTypes = {
  classes: PropTypes.object,
  projectId: PropTypes.number,
  columns: PropTypes.bool,
};

export default withStyles(styles)(ContractProgressBars);
