import PropTypes from 'prop-types';
import { Grid, LinearProgress as LinearProgressAux } from '@mui/material';
import { withStyles } from '@mui/styles';

import styles from './styles';

const LinearProgress = ({ classes, title, value }) => {
  return (
    <Grid container className={`${classes.progressbar}`}>
      <Grid item className={`${classes.progressbar}__title`}>
        {title}
      </Grid>
      <Grid item className={`${classes.progressbar}__linear`}>
        <LinearProgressAux variant='determinate' value={value} />
        {`${Math.round(value)}%`}
      </Grid>
    </Grid>
  );
};

LinearProgress.propTypes = {
  /**
   * Title of the LinearProgress
   * Value between 0 and 100.
   */
  classes: PropTypes.object,
  title: PropTypes.object,
  value: PropTypes.number,
};

export default withStyles(styles)(LinearProgress);
