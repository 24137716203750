const styles = theme => ({
  root: {
    display: 'flex !important',
    flexWrap: 'wrap !important',
    flexDirection: 'row !important',
    gap: 10,
    padding: '10px 10px 0px 10px',
    '&__firtsLine': {
      gap: 10,
    },
  },
});

export default styles;
