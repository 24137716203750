const calcDeliveryTime = (value, total) => {
  return Math.round((value * 100) / total);
};

const calcExecutionPercentage = (initDate, endDate) => {
  let totalDaysDiff = null;
  let daysPassed = null;
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  const currentDate = `${yyyy}/${mm}/${dd} 00:00:00`;
  const startDate = Date.parse(initDate);

  if (today < startDate) {
    return 0;
  }
  endDate = Date.parse(endDate);
  const timeDiff = endDate - startDate;
  const dayPassedDiff = Date.parse(currentDate) - startDate;

  totalDaysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  daysPassed = Math.floor(dayPassedDiff / (1000 * 60 * 60 * 24));
  const percentageExpectade = calcDeliveryTime(daysPassed, totalDaysDiff);

  return percentageExpectade > 100 ? 100 : Math.max(percentageExpectade, 0);
};

const validateLastProjectDate = cfList => {
  const findCf = cfList.find(p => p.cf_definition_id === 561);
  return !!findCf && !!findCf.value ? findCf.value : cfList.find(p => p.cf_definition_id === 560).value;
};

/*
Input: string and number
Output: string truncated with a specific number of characters
Constraint: string.length > 0
*/
const textTruncate = (str, numberOfChars) => {
  if (!!numberOfChars && numberOfChars > 0) {
    return str.length > numberOfChars ? str.substring(0, numberOfChars - 4) + '... ' : str;
  } else {
    return str;
  }
};

/*
Input: string 
Output: string with the first letter in upper case
Constraint: string.length > 0
*/
const firstCharUpperCase = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/*
Input: Array of numbers, Object project
Output: New array with selected cf_definition_id
Constraint:
*/
const selectCfValues = (array, project) => {
  if (!!array && array.length > 0 && !!project && !!project.cf_values) {
    return project.cf_values.filter(value => array.includes(value.cf_definition_id));
  } else {
    return [];
  }
};

/*
Input: Array cfValues, new cfValue, cfValueId
Output: Same array with the new cfValue before cfValueId's index
Constraint:
*/
const insertBefore = (array, newcfValue, cfValueId) => {
  const index = array.findIndex(cfValue => cfValue.cf_definition_id === cfValueId);
  if (index !== -1) {
    array.splice(index, 0, newcfValue);
  }
};

/*
Input: Object project
Output: New array with the new cfValue before cfValueId's index
Constraint:
*/
const cfValuesState = project => {
  const array = selectCfValues([554, 555, 556, 559], project);
  const projectDates = selectCfValues([557, 560, 561], project);
  const newCfValue = {
    cf_definition_id: -1,
    definition_name: 'Execution Period',
    dates: projectDates,
  };
  insertBefore(array, newCfValue, 559);
  return array;
};

const numberToCurrency = (amount, currency = 'USD') => {
  const formatter = new Intl.NumberFormat(currency, { maximumSignificantDigits: 4 });

  return `${currency} ${formatter.format(amount)}`;
};

const addDaysToDate = (date, days) => {
  const currentDate = new Date(date);

  currentDate.setDate(currentDate.getDate() + Number(days));
  return currentDate;
};

const calculateExecutionPeriod = (spentDays, currentTermInDays, compensableDays) => {
  const percentage = (Number(spentDays) * 100) / (Number(currentTermInDays) + Number(compensableDays));
  return percentage > 100 ? 100 : Math.round(percentage);
};

const removeDecimalsFromAmount = amount => {
  if (typeof amount === 'number') {
    return amount;
  }

  return parseFloat(amount?.replace(/,/g, '')) || 0;
};

/*
Input: startDate and endDate
Output: the differences in days between input dates
Constraint:
*/
const getDaysDifferencesFromDates = (firstDate, secondDate) => {
  if (firstDate === 'Invalid Date' || secondDate === 'Invalid Date' || !firstDate || !secondDate) {
    return null;
  }

  const getDateTime = date => {
    const currentDate = date && new Date(date);
    return currentDate.getTime();
  };

  const differencesInMilliseconds = Math.abs(getDateTime(firstDate) - getDateTime(secondDate));
  const differencesInDays = differencesInMilliseconds / (1000 * 3600 * 24);

  return Math.floor(differencesInDays);
};

/*
Input: UserType
Output: Boolean
Constraint:
*/
const isAPrivilegeUser = userType => {
  const privilegeUsers = ['supervisor', 'admin', 'super_admin', 'facilitator'];
  return privilegeUsers.includes(userType);
};

/*
Input: String
Output: RGB color base on the first three letters.
Constraint:
*/
const getRandomColorByString = string => {
  const colorNum = string
    .toLowerCase()
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const num = Math.round(0x737878 * parseInt(colorNum));
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;

  return `rgb(${r}, ${g}, ${b}, 1)`;
};

/*
Input: Valid Date
Output: a string in simplified extended ISO format
Constraint:
*/
const convertToIsoString = date => {
  if (!!date) {
    return date;
  }

  return new Date(date).toISOString();
};

const renderAvatarInitials = user => {
  const { first_name: firstName, last_name: lastName, email } = user || {};

  if (!!lastName && !!firstName) {
    return `${firstName?.split('')[0]}${lastName?.split('')[0]}`;
  } else if (!!email) {
    const emailParts = email?.split('@');
    return emailParts[0][0] + emailParts[1][0];
  }
};

export const projectUtil = {
  calcDeliveryTime,
  calcExecutionPercentage,
  validateLastProjectDate,
  textTruncate,
  firstCharUpperCase,
  selectCfValues,
  insertBefore,
  cfValuesState,
  numberToCurrency,
  addDaysToDate,
  calculateExecutionPeriod,
  removeDecimalsFromAmount,
  getDaysDifferencesFromDates,
  isAPrivilegeUser,
  getRandomColorByString,
  convertToIsoString,
  renderAvatarInitials,
};
