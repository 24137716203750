import { set } from 'lodash';
import { commonConstants } from '../constants/common-constants';
import { contractorConstants } from '../constants/contractor-constants';

const contractorInitialState = {
  contractor: {},
  contractors: [],
  loading: false,
  fail: false,
};

export default function contractors(state = contractorInitialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case contractorConstants.CREATE_CONTRACTOR:
    case contractorConstants.GET_CONTRACTORS:
      set(newState, 'loading', true);
      return newState;

    case contractorConstants.CREATE_CONTRACTOR_FAIL:
    case contractorConstants.GET_CONTRACTORS_FAIL:
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;

    case contractorConstants.CREATE_CONTRACTOR_SUCCESS:
      set(newState, 'contractor', action.payload.data);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    case contractorConstants.GET_CONTRACTORS_SUCCESS:
      set(newState, 'contractors', action.payload.data);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    case commonConstants.RESET:
      return contractorInitialState;
    default:
      return state;
  }
}
