import { orgGroupConstants } from "../constants/org-group-constants";

/**
 * Request all organization group types
 */
function requestOrgTypes(clientId) {
  return {
    type: orgGroupConstants.GET_ORG_TYPES,
    payload: { clientId }
  };
}

/**
 * Request organization group
 */
function requestOrgGroup (clientId, typeId) {
  return {
    type: orgGroupConstants.GET_ORG_GROUP,
    payload: { clientId, typeId }
  };
};


export const orgGroupActions = {
  requestOrgTypes,
  requestOrgGroup
};
