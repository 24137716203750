import { useState, useEffect, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import LinearProgress from '../linearProgress';
import { projectUtil } from '../../utils/project.utils';
import { Chip, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';

import styles from './styles';

const LabelState = ({ classes, cfValue }) => {
  const [state, setState] = useState('');

  useEffect(() => {
    switch (cfValue.cf_definition_id) {
      case 554:
        switch (cfValue.value) {
          case 'Ejecución':
            setState(
              <Chip
                className={`${classes.chip}_status-inProgress`}
                label={<FormattedMessage id='labelState.inProgress' defaultMessage={cfValue.value} />}
              />
            );
            break;
          case 'Preparación':
            setState(
              <Chip
                className={`${classes.chip}_status-toDo`}
                label={<FormattedMessage id='labelState.toDo' defaultMessage={cfValue.value} />}
              />
            );
            break;
          case 'Concluída':
            setState(
              <Chip
                className={`${classes.chip}_status-complete`}
                label={<FormattedMessage id='labelState.complete' defaultMessage={cfValue.value} />}
              />
            );
            break;
          default:
            break;
        }
        break;
      case 555:
        setState(
          <LinearProgress
            title={<FormattedMessage id='labelState.financialProgress' defaultMessage={cfValue.definition_name} />}
            value={cfValue.value}
          />
        );
        break;
      case 556:
        setState(
          <LinearProgress
            title={<FormattedMessage id='labelState.physicalProgress' defaultMessage={cfValue.definition_name} />}
            value={cfValue.value}
          />
        );
        break;
      case -1:
        const percent = projectUtil.calcExecutionPercentage(
          cfValue.dates[0].value,
          projectUtil.validateLastProjectDate(cfValue.dates)
        );
        setState(
          <LinearProgress
            title={<FormattedMessage id='labelState.executionPeriod' defaultMessage={cfValue.definition_name} />}
            value={!!percent ? percent : 0}
          />
        );
        break;
      case 559:
        setState(
          <Fragment>
            <Grid className={`${classes.amount}__title`}>
              <FormattedMessage id='labelState.amount' defaultMessage={cfValue.definition_name} />
            </Grid>
            <Grid className={`${classes.amount}-value`}>{cfValue.humanize_value}</Grid>
          </Fragment>
        );
        break;
      default:
        break;
    }
  }, [
    cfValue.cf_definition_id,
    cfValue.value,
    cfValue.definition_name,
    cfValue.dates,
    cfValue.humanize_value,
    classes,
  ]);

  return !!state && state;
};

LabelState.propTypes = {
  classes: PropTypes.object,
  cfValue: PropTypes.object,
};

export default withStyles(styles)(LabelState);
