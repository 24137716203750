import React, { Fragment, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Footer from '../components/footer';
import Header from '../components/header';
import { useDispatch, useSelector } from 'react-redux';
import { orgGroupActions } from '../redux/actions/org-group-actions';

const styles = theme => (
	{
		root: {
			display: 'flex',
			flex: 1,
			flexDirection: 'column !important',
			overflow: 'auto',
		}
	}
);

const useStyles = makeStyles(styles);

const Layout = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.authentication.user);

	useEffect( () => {
		if (currentUser) {
			dispatch( orgGroupActions.requestOrgTypes(currentUser.client_id) );
		}
	}, [currentUser, dispatch]);

	return (
		<Fragment>
			<Header />
			<Grid className={classes.root} >
				<Outlet />
			</Grid>
			<Footer />
		</Fragment>
	);
};

export default Layout;
