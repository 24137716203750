// prettier-ignore
import { Grid} from '@mui/material';
import styles from './styles';
import { withStyles } from '@mui/styles';
import ColorLinearProgress from '../../../../components/colorLinearProgress';

const ProductProgressBar = ({ classes, product }) => {
  return (
    <Grid container className={`${classes.root}`}>
      <ColorLinearProgress
        variant='determinate'
        value={Math.round((product.goal_completed_to_date / (product.quantifiable ? product.goal : 1)) * 100)}
        status={product.status}
      />
    </Grid>
  );
};

export default withStyles(styles)(ProductProgressBar);
