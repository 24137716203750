import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { filterActions } from '../../redux/actions/filters-actions';
import { projectActions } from '../../redux/actions/project-actions';
import { orgGroupActions } from '../../redux/actions/org-group-actions';

import Dropdown from '../dropdown';
import Searchfield from '../searchfield';
import { hooksUtil } from '../../utils/hooks.util';
import { withStyles } from '@mui/styles';
import styles from './styles';

const PrincipalFilters = ({
  classes,
  currentUser,
  country,
  setCountry,
  orgGroupSelected,
  setOrgGroupSelected,
  getFilters,
  searchTerm,
  setSearchTerm,
  children,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const countries = useSelector(state => state.filters.countries);
  const loadingCountries = useSelector(state => state.filters.loadingCountries);
  const orgGroupTypes = useSelector(state => state.orgGroups.orgGroupTypes);
  const loadingOrgGroupTypes = useSelector(state => state.orgGroups.loading);
  const orgGroups = useSelector(state => state.orgGroups.orgGroups);

  const [orgGroupsByType, setOrgGroupsByType] = useState([]);
  const delaySearch = hooksUtil.useDelay(searchTerm, 700);

  useEffect(() => {
    if (currentUser) {
      dispatch(projectActions.getFilteredProjects(currentUser.client_id));
      dispatch(filterActions.requestCountries(currentUser.client_id));
      dispatch(orgGroupActions.requestOrgTypes(currentUser.client_id));
    }
  }, [currentUser]);

  useEffect(() => {
    if (!!country) {
      dispatch(filterActions.selectCountry(country.id));
      dispatch(projectActions.getFilteredProjects(currentUser.client_id, getFilters()));
    }
  }, [country]);

  useEffect(() => {
    if (!!orgGroups && !!orgGroupTypes && orgGroupTypes.length) {
      setOrgGroupsByType(orgGroups[orgGroupTypes[0].id]);
    }
  }, [orgGroupTypes, orgGroups]);

  useEffect(() => {
    if (delaySearch && currentUser?.client_id) {
      dispatch(projectActions.getFilteredProjects(currentUser.client_id, getFilters()));
    }
  }, [delaySearch]);

  const handelOnClickSearch = () => {
    dispatch(projectActions.getFilteredProjects(currentUser.client_id, getFilters()));
  };

  const handleOnChangeSearch = value => {
    setSearchTerm(value);
  };

  return (
    <Grid className={`${classes.root}`}>
      <Dropdown
        idName='dropdown.country'
        label={<FormattedMessage id='dropdown.country' defaultMessage={'Country'} />}
        listItems={countries}
        labelField={'name'}
        handleOnChange={setCountry}
        hookValue={country}
        loading={loadingCountries}
        minWidth={400}
      />
      <Dropdown
        idName='dropdown.initiative'
        label={<FormattedMessage id='dropdown.initiative' defaultMessage={'Initiative'} />}
        listItems={orgGroupsByType}
        labelField={'name'}
        handleOnChange={setOrgGroupSelected}
        hookValue={orgGroupSelected}
        loading={loadingOrgGroupTypes}
        minWidth={400}
      />
      <Searchfield
        label={intl.messages['dropdown.search'] || 'Search'}
        handleOnChange={handleOnChangeSearch}
        hookValue={searchTerm}
        handleOnClick={handelOnClickSearch}
      />
      {children}
    </Grid>
  );
};

PrincipalFilters.propType = {
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  country: PropTypes.object,
  setCountry: PropTypes.func,
  orgGroupSelected: PropTypes.object,
  setOrgGroupSelected: PropTypes.func,
  getFilters: PropTypes.func,
  searchTerm: PropTypes.object,
  setSearchTerm: PropTypes.func,
  children: PropTypes.any,
};

export default withStyles(styles)(PrincipalFilters);
