import API from './api.service';

export class PhysicalProgressService {
  static async createPhysicalProgress(data = {}) {
    try {
      const response = await API(false, false).post(`contracts/${data.contractId}/physical_progresses`, {
        ...data,
      });
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }

  static async getPhysicalProgresses(data = {}) {
    try {
      let url = `contracts/${data.contractId}/physical_progresses`;
      url = `${url}?page=${data.page || 1}&per_page=${data.pageSize || 20}`;

      const response = await API(false, false).get(url);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }
}

export default PhysicalProgressService;
