import { alpha } from '@mui/material';

const styles = theme => ({
  table: {
    border: 'none !important',
    marginBottom: '20px',
    color: `${theme.palette.gray.dark} !important`,
    '&_head': {
      backgroundColor: theme.palette.secondary.dark,
      '&_checker': {
        color: 'white !important',
        '&.Mui-checked': {
          color: 'white',
        },
      },
    },
    '&_description': {
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      maxWidth: 180,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
    },
    '&-cell': {
      color: `${theme.palette.secondary.contrastText} !important`,
      '&-header': {
        textAlign: 'center !important',
        backgroundColor: alpha(theme.palette.secondary.dark, 0.5),
        fontWeight: `${theme.typography.fontWeightBold} !important`,
      },
      '&-body': {
        fontSize: '12px !important',
        minWidth: 150,
        '&-actions': {
          gap: 10,
        },
        '&-sticky-left': {
          position: 'sticky',
          left: 0,
          minWidth: 180,
          zIndex: 5,
          backgroundColor: theme.palette.primary.contrastText,
        },
        '&-sticky-right': {
          position: 'sticky',
          right: 0,
          width: 90,
          zIndex: 5,
          backgroundColor: theme.palette.primary.contrastText,
        },
        '&-sticky-right-not-bg': {
          position: 'sticky',
          right: 0,
        },
        '&-sticky-left-not-bg': {
          position: 'sticky',
          left: 0,
          width: 120,
        },
      },
      '&-body2': {
        display: 'flex !important',
      },
      '&-sticky-left': {
        position: 'sticky',
        left: 0,
        width: 120,
        backgroundColor: theme.palette.secondary.dark,
        color: `${theme.palette.secondary.contrastText} !important`,
      },
      '&-sticky-right': {
        position: 'sticky',
        right: 0,
        backgroundColor: theme.palette.secondary.dark,
        color: `${theme.palette.secondary.contrastText} !important`,
      },
      '&-bottom_group': {
        textAlign: 'center !important',
        backgroundColor: alpha(theme.palette.secondary.dark, 0.5),
        color: theme.palette.secondary.contrastText,
        height: '27px !important',
      },
      '&-bottom_icon': {
        color: theme.palette.secondary.contrastText,
        padding: '0px !important',
      },
    },

    '&-chip': {
      backgroundColor: `${theme.palette.secondary.light} !important`,
      fontWeight: `${theme.typography.fontWeightBold} !important`,
      border: `1px solid ${theme.palette.gray.main} !important`,
      '&_item': {
        padding: '0px !important',
      },
      '&-to_do': {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        fontWeight: `${theme.typography.fontWeightBold} !important`,
        color: `${theme.palette.gray.contrastText} !important`,
      },
      '&-done': {
        backgroundColor: `${theme.palette.secondary.light} !important`,
        fontWeight: `${theme.typography.fontWeightBold} !important`,
        color: `${theme.palette.gray.contrastText} !important`,
      },
      '&-in_progress': {
        backgroundColor: `${theme.palette.primary.dark} !important`,
        fontWeight: `${theme.typography.fontWeightBold} !important`,
        color: `${theme.palette.gray.contrastText} !important`,
      },
    },

    '&-advance': {
      '&-chip': {
        '&.MuiButton-root': {
          borderRadius: 8,
          height: 29,
          color: `${theme.palette.gray.contrastText} !important`,
          backgroundColor: theme.palette.warning.main,
          fontWeight: theme.typography.fontWeightBold,
          textTransform: 'capitalize !important',
          paddingRight: '0px',
          border: `solid 1px ${theme.palette.gray.main}`,
          '& hr': {
            mx: 0.5,
          },
        },
        '&-to_do': {
          backgroundColor: `${theme.palette.secondary.main} !important`,
        },
        '&-done': {
          backgroundColor: `${theme.palette.secondary.light} !important`,
        },
        '&-not_done': {
          backgroundColor: `${theme.palette.error.dark} !important`,
        },
        '&-in_progress': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
        },
        '&-pending': {
          backgroundColor: `${theme.palette.statusLevel.PENDING} !important`,
        },
      },
    },

    '&-box': {
      fontSize: '12px !important',
      '&-planned': {
        color: `${theme.palette.gray.dark} !important`,
      },
    },

    '&-row': {
      '&-attachments': {
        display: 'flex',
        alignItems: 'center',
        '&-icon': {},
      },
      '&-product-group': {
        backgroundColor: theme.palette.gray.productGroup,
      },
      '&-result-group': {
        backgroundColor: theme.palette.background.paper,
      },
      '&-contract-group': {
        backgroundColor: theme.palette.gray.contactGroup,
      },
      '&-contract-deliverable-bg': {
        backgroundColor: `${theme.palette.gray.deliverableTask} !important`,
      },
    },
  },
  agreement: {
    '&_head': {
      backgroundColor: `${alpha(theme.palette.secondary.dark, 0.5)} !important`,
    },
    '&_head_bg_color': {
      backgroundColor: `${alpha(theme.palette.secondary.dark, 0.5)} !important`,
    },
    '&_head_bg': {
      backgroundColor: `${theme.palette.gray.contrastText}`,
      padding: '0px !important',
    },
    '&_box': {
      display: 'block',
      width: '100%',
      height: '66px',
    },
  },
  pagination: {
    marginTop: '10px',
    '& > ul': {
      justifyContent: 'end',
      '& > li > button': {
        borderRadius: 4,
        border: `1px solid ${theme.palette.statusLevel.TODO}`,
        fontWeight: 500,
        color: `${theme.palette.primary.dark} !important`,
        '&.Mui-selected': {
          backgroundColor: `${theme.palette.statusLevel.TODO} !important`,
          color: `${theme.palette.gray.contrastText} !important`,
        },
      },
      '& :last-child button, & :first-child button': {
        border: 'none',
      },
    },
  },
  progressbar: {
    alignItems: 'center',
    '&-description': {
      minWidth: 150,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
    },
    '&-task': {
      flexGrow: 5,
    },
    '&-collections': {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '&__title': {
      width: '0%',
    },
    '&__linear': {
      width: '80%',
    },

    '&-value': {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '20%',
    },

    '& .MuiLinearProgress-root': {
      height: 10,
      borderRadius: 5,
    },
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.gray.main,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.gray.contrastText,
    borderRadius: '2px',
    padding: '30px',
    boxShadow: 24,
    p: 4,
  },
});

export default styles;
