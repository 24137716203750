import PropTypes from 'prop-types';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

import styles from './styles';

const ColorLinearProgress = ({ classes, value, status }) => {
  return (
    <Grid container className={`${classes.root}__container_${status}`}>
      <Grid className={`${classes.root}__bar`}>
        <LinearProgress variant='determinate' value={value} className={`${classes.root}__linear_${status}`} />
      </Grid>
      <Typography variant='subtitle2'>{`${Math.round(value)}%`}</Typography>
    </Grid>
  );
};

ColorLinearProgress.propTypes = {
  classes: PropTypes.object,
  status: PropTypes.string,
  value: PropTypes.number,
};

export default withStyles(styles)(ColorLinearProgress);
