/**
 *
 * Index file for sagas files
 * Add new saga file here
 *
 */
import { all } from 'redux-saga/effects';
import authSaga from './auth-saga';
import contractModificationSaga from './contract-modification-saga';
import physicalProgressSaga from './physical-progress-saga';
import financialProgressSaga from './financial-progress-saga';
import contractSaga from './contract-saga';
import contractorSaga from './contractor-saga';
import currencySaga from './currency-saga';
import filterSaga from './filter-saga';
import orgGroupsSaga from './org-group-saga';
import projectSaga from './project-saga';
import taskSaga from './task-saga';

function* sagaFiles() {
  yield all([authSaga(), orgGroupsSaga(), filterSaga(), projectSaga(), taskSaga(), contractSaga(), contractorSaga(), contractModificationSaga(), physicalProgressSaga(), financialProgressSaga(), currencySaga()]);
}
export default sagaFiles;
