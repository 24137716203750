import API from "./api.service";

export class ProjectService {
  static async getPaginatedProjects(data = {}) {
    try {
      let url = `clients/${data.clientId}/projects?include=cf_values`;
      url = `${url}&page=${data.page || 1}&per_page=${data.pageSize || 24}`;
      
      if (!!data.leafGroups && !!data.leafGroups.length) {
        data.leafGroups.forEach( gId => url = `${url}&org_groups_ids[]=${gId}`);
      }
      if (!!data.searchTerm) {
        url = `${url}&query=${data.searchTerm}`;
      }
      if (!!data.projectType) {
        url = `${url}&project_type=${data.projectType}`;
      }
      if (!!data.administrativeDivisionsIds) {
        data.administrativeDivisionsIds.forEach( adId => url = `${url}&administrative_divisions_ids[]=${adId}`);
      }
      if (!!data.countryId) {
        url = `${url}&country_id=${data.countryId}`;
      }
      if(!!data.cfFilters){
        data.cfFilters.forEach(filter => {
          filter.forEach(option => {
            url = `${url}&cf_value_filters[][${option[0]}]=${option[1]}`;
          });
        });
      }

      const response = await API(false, false).get(url);

      return {
        data: response.data,
        status: response.status
      };
    } catch (e) {
      console.error("e", e);
      return { status: e.status };
    }
  }

  static async getProject(data = {}) {
    try {
      const response = await API(false, false).get(`clients/${data.clientId}/projects/${data.projectId}`);
      return {
        data: response.data,
        status: response.status
      };
    } catch (e) {
      console.error("e", e);
      return { status: e.status };
    }
  }
}

export default ProjectService;
