export const taskConstants = {
  SET_NEW_PROJECT_TASK: 'SET_NEW_PROJECT_TASK',
  SET_NEW_PROJECT_TASK_SUCCESS: 'SET_NEW_PROJECT_TASK_SUCCESS',
  SET_NEW_PROJECT_TASK_FAIL: 'SET_NEW_PROJECT_TASK_FAIL',

  GET_PROJECT_TASK_LIST: 'GET_PROJECT_TASK_LIST',
  GET_PROJECT_TASK_LIST_SUCCESS: 'GET_PROJECT_TASK_LIST_SUCCESS',
  GET_PROJECT_TASK_LIST_FAIL: 'GET_PROJECT_TASK_LIST_FAIL',

  GET_PROJECT_PARENT_TASK_LIST: 'GET_PROJECT_PARENT_TASK_LIST',
  GET_PROJECT_PARENT_TASK_LIST_SUCCESS: 'GET_PROJECT_PARENT_TASK_LIST_SUCCESS',
  GET_PROJECT_PARENT_TASK_LIST_FAIL: 'GET_PROJECT_PARENT_TASK_LIST_FAIL',
  
  UPDATE_PROJECT_TASK: 'UPDATE_PROJECT_TASK',
  UPDATE_PROJECT_TASK_SUCCESS: 'UPDATE_PROJECT_TASK_SUCCESS',
  UPDATE_PROJECT_TASK_FAIL: 'UPDATE_PROJECT_TASK_FAIL',

  UPDATE_PROJECT_TASK_STATUS: 'UPDATE_PROJECT_TASK_STATUS',
  UPDATE_PROJECT_TASK_STATUS_SUCCESS: 'UPDATE_PROJECT_TASK_STATUS_SUCCESS',
  UPDATE_PROJECT_TASK_STATUS_FAIL: 'UPDATE_PROJECT_TASK_STATUS_FAIL',

  SET_NEW_TASK_LOG: 'SET_NEW_TASK_LOG',
  SET_NEW_TASK_LOG_SUCCESS: 'SET_NEW_TASK_LOG_SUCCESS',
  SET_NEW_TASK_LOG_FAIL: 'SET_NEW_TASK_LOG_FAIL',

  GET_TASK_LOGS: 'GET_TASK_LOGS',
  GET_TASK_LOGS_SUCCESS: 'GET_TASK_LOGS_SUCCESS',
  GET_TASK_LOGS_FAIL: 'GET_TASK_LOGS_FAIL',

  GET_PRODUCT_STATUS_LOGS: 'GET_PRODUCT_STATUS_LOGS',
  GET_PRODUCT_STATUS_LOGS_SUCCESS: 'GET_PRODUCT_STATUS_LOGS_SUCCESS',
  GET_PRODUCT_STATUS_LOGS_FAIL: 'GET_PRODUCT_STATUS_LOGS_FAIL',

  SET_PRODUCT_STATUS_LOG: 'SET_PRODUCT_STATUS_LOG',
  SET_PRODUCT_STATUS_LOG_SUCCESS: 'SET_PRODUCT_STATUS_LOG_SUCCESS',
  SET_PRODUCT_STATUS_LOG_FAIL: 'SET_PRODUCT_STATUS_LOG_FAIL',

  GET_PROJECT_TASK_LEVELS: 'GET_PROJECT_TASK_LEVELS',
  GET_PROJECT_TASK_LEVELS_SUCCESS: 'GET_PROJECT_TASK_LEVELS_SUCCESS',
  GET_PROJECT_TASK_LEVELS_FAIL: 'GET_PROJECT_TASK_LEVELS_FAIL',

  CREATE_PROJECT_TASK_LEVEL: 'CREATE_PROJECT_TASK_LEVEL',
  CREATE_PROJECT_TASK_LEVEL_SUCCESS: 'CREATE_PROJECT_TASK_LEVEL_SUCCESS',
  CREATE_PROJECT_TASK_LEVEL_FAIL: 'CREATE_PROJECT_TASK_LEVEL_FAIL',

  DOWNLOAD_TASK_LOGS_EVIDENCES: 'DOWNLOAD_TASK_LOGS_EVIDENCES',
  DOWNLOAD_TASK_LOGS_EVIDENCES_SUCCESS: 'DOWNLOAD_TASK_LOGS_EVIDENCES_SUCCESS',
  DOWNLOAD_TASK_LOGS_EVIDENCES_FAIL: 'DOWNLOAD_TASK_LOGS_EVIDENCES_FAIL',
  
  UPDATE_TASK_WEIGHTS: 'UPDATE_TASK_WEIGHTS',
  UPDATE_TASK_WEIGHTS_SUCCESS: 'UPDATE_TASK_WEIGHTS_SUCCESS',
  UPDATE_TASK_WEIGHTS_FAIL: 'UPDATE_TASK_WEIGHTS_FAIL',
};
