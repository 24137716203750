const styles = theme => ({
  chip: {
    '&_status': {
      '&-inProgress': {
        color: `${theme.palette.primary.contrastText} !important`,
        backgroundColor: `${theme.palette.statusLevel.IN_PROGRESS} !important`,
        fontSize: '12px !important',
        maxWidth: '120px !important',
        minHeight: '20px !important',
      },
      '&-toDo': {
        color: `${theme.palette.primary.contrastText} !important`,
        backgroundColor: `${theme.palette.statusLevel.TODO} !important`,
        fontSize: '12px !important',
        maxWidth: '120px !important',
        minHeight: '20px !important',
      },
      '&-complete': {
        color: `${theme.palette.primary.contrastText} !important`,
        backgroundColor: `${theme.palette.statusLevel.COMPLETE} !important`,
        fontSize: '12px !important',
        maxWidth: '120px !important',
        minHeight: '20px !important',
      },
    },
  },
});

export default styles;
