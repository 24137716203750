import { useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../dropdown';

const CfFilters = ({ classes, cfFilters, setSelectedCfFilters, loading }) => {
  const [selectedFilters, setSelectedFilters] = useState({});

  const customOptionProps = filter => {
    return {
      options: filter.options,
      getOptionLabel: option => option,
    };
  };

  const handleOnChange = (value, cfFilterId) => {
    const selected = { ...selectedFilters };
    selected[cfFilterId] = value;
    setSelectedFilters(selected);
    setSelectedCfFilters(formatCfFilters(Object.entries(selected)));
  };

  const formatCfFilters = filters =>
    filters.map(f => [
      ['cf_definition_id', f[0]],
      ['value', f[1]],
    ]);

  return cfFilters.map((filter, index) => (
    <Dropdown
      key={`cfFilters_${index}`}
      idName={filter.description || filter.name}
      label={filter.description || filter.name}
      handleOnChange={value => {
        handleOnChange(value, filter.id);
      }}
      hookValue={selectedFilters[filter.id] || null}
      loading={loading}
      customOptionProps={customOptionProps(filter)}
      minWidth={400}
    />
  ));
};

CfFilters.propType = {
  classes: PropTypes.object,
  cfFilters: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CfFilters;
