export const filterConstants = {
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAIL: 'GET_COUNTRIES_FAIL',
  SELECT_COUNTRY: 'SELECT_COUNTRY',
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS: 'GET_FILTERED_ADMINISTRATIVE_DIVISIONS',
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS: 'GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS',
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL: 'GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL',
  GET_CUSTOM_FIELD_FILTERS: 'GET_CUSTOM_FIELD_FILTERS',
  GET_CUSTOM_FIELD_FILTERS_SUCCESS: 'GET_CUSTOM_FIELD_FILTERS_SUCCESS',
  GET_CUSTOM_FIELD_FILTERS_FAIL: 'GET_CUSTOM_FIELD_FILTERS_FAIL',
  CLEAR_FILTERS: 'CLEAR_FILTERS'
};
