import theme from './themes';

const apiConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
  env: process.env.REACT_APP_ENV_IMAGE,
  authPath: `auth`
};

/**
 * Google API key:
 */
const googleConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY
};

/**
 * Amazon S3 Config
 *
 */
const amazonS3Config = {
  bucketName: process.env.REACT_APP_AS3_BUCKET,
  region: process.env.REACT_APP_AS3_REGION,
  accessKeyId: process.env.REACT_APP_AS3_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AS3_ACCESS,
  s3Url: `${process.env.REACT_APP_AS3_URL}${process.env.REACT_APP_AS3_BUCKET}`
};

const siteConfig = {
  siteName: 'Captudata',
  siteIcon: 'ion-flash',
  siteFooter: 'Captudata ©2019, Inc',
  homePage: '/executive',
  prefix: 'captudata'
};

const language = 'spanish';

export { apiConfig, googleConfig, amazonS3Config, siteConfig, language, theme };
