import { alpha } from '@mui/material';

const styles = theme => ({
  root: {
    '&__bar': {
      flexGrow: '1',
    },
    '&__container': {
      '&_to_do': {
        alignItems: 'center',
        columnGap: '5px',
        color: theme.palette.primary.dark,
      },
      '&_in_progress': {
        alignItems: 'center',
        columnGap: '5px',
        color: theme.palette.warning.main,
      },
    },
    '&__linear': {
      '&_to_do': {
        backgroundColor: `${alpha(theme.palette.primary.dark, 0.5)} !important`,
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      '&_in_progress': {
        backgroundColor: `${alpha(theme.palette.warning.main, 0.5)} !important`,
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: theme.palette.warning.main,
        },
      },
    },
  },
});

export default styles;
