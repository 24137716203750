import { Grid, IconButton, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { withStyles } from '@mui/styles';
import styles from './styles';

const Searchfield = ({ classes, label, handleOnChange, hookValue, handleOnClick }) => {
  return (
    <TextField
      classes={classes.root}
      id={label}
      label={label}
      value={hookValue || ''}
      variant='outlined'
      size='small'
      onChange={event => {
        handleOnChange(event.target.value);
      }}
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleOnClick} aria-label='search icon' edge='end'>
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );
};

Searchfield.propType = {
  classes: PropTypes.object,
  label: PropTypes.string,
  handleOnChange: PropTypes.func,
  hookValue: PropTypes.any,
  handleOnClick: PropTypes.func,
};

// Redux connection
export default withStyles(styles)(Searchfield);
