import { alpha } from '@mui/material/styles';

const styles = theme => ({
  root: {
    width: '100% !important',
    background: 'white !important',
    border: 'none',
    boxShadow: `inset 0 -7px 0 0 ${theme.palette.primary.light} !important`
  },
  tooltip: {
    backgroundColor: theme.palette.gray.dark
  },
  menu: {
    '&__toolbar': {
      minHeight: 55
    },
    '&__tooltip': {
      color: theme.palette.secondary.main
    },

    '&__logo': {
      '&--client': {
        maxHeight: 38,
        marginRight: 32
      },
      '&--cap': {
        maxHeight: 55
      }
    },

    '&__menu': {
      alignSelf: 'flex-end',
      marginLeft: 'auto',
      marginBottom: 3,

      '&__item': {
        marginBottom: '4px !important',
        padding: '10px 24px',
        fontSize: 12,
        borderRadius: '0 !important',
        textTransform: 'none !important',
        height: '55px !important',
        '&:hover': {
          boxShadow: `0 7px 0 0 ${theme.palette.secondary.main}`
        }
      },
      '&__action': {
        marginBottom: 4,
        padding: '10px 24px',
        fontSize: 12,
        borderRadius: '0 !important',
        textTransform: 'none !important',
        height: '55px !important',
        boxShadow: `0 7px 0 0 ${theme.palette.primary.main}`,
        display: 'flex',
        '& .MuiInputLabel-root': {
          fontSize: 14,
          fontWeight: 700,
          color: theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          paddingRight: 5
        },
        '&_select': {
          '& .MuiSelect-select': {
            fontSize: 12,
            fontWeight: 500,
            color: theme.palette.primary.main,
            padding: 0,
            paddingRight: 24,
            '&:focus': {
              background: 'none'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
          }
        },
        '&:hover': {
          boxShadow: `0 7px 0 0 ${theme.palette.secondary.main}`
        }
      },
      '&__item_selected': {
        background: 'none',
        boxShadow: `0 7px 0 0 ${theme.palette.secondary.main}`
      }
    }
  },
  client_logo: {
    maxHeight: 38,
    marginRight: 32
  },
  cap_logo: {
    maxHeight: 55
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.contrastText, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }

});

export default styles;
