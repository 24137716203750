import API from './api.service';

export class CurrencyService {

  static async getCurrencies() {
    try {
      const response = await API(false, false).get(`currencies`);
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      console.error('e', e);
      return { status: e.status };
    }
  }
}

export default CurrencyService;
