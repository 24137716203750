import { Fragment, useState } from 'react';
import { AppBar, Button, IconButton, InputLabel, Menu, MenuItem, Select, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { withStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { authActions } from '../../redux/actions/auth-actions';
import styles from './styles';
import capLogo from '../../assets/images/logo.svg';
import { withI18NContext } from '../i18n/i18nWrapper';

const MenuButton = ({ children, classes, to, ...props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Button
      color='primary'
      className={`${classes.menu}__menu__item ${match ? ` ${classes.menu}__menu__item_selected` : ''}`}
      {...props}
    >
      {children}
    </Button>
  );
};

const Header = ({ classes, i18nContext, locale, ...props }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const currentUser = useSelector(state => state.authentication.user);
  const dispatch = useDispatch();

  /******* HANDLERS ********/
  const handleLogout = () => dispatch(authActions.logout());

  const handleMobileMenuChange = event => {
    setMobileMoreAnchorEl(event.currentTarget);
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setMobileMenuOpen(false);
  };

  const handleChangeLocale = event => {
    const newLocale = event.target.value;
    dispatch(authActions.changeLocale(currentUser.client_id, currentUser.id, newLocale));
  };

  /****** RENDERS ******/
  /** Desktop Menu */
  const renderMenu = () => (
    <Fragment>
      <Link to={'/projects'}>
        <MenuButton to={'/projects'} classes={classes}>
          <FormattedMessage id='menu.projects' defaultMessage='Proyectos' />
        </MenuButton>
      </Link>
    </Fragment>
  );

  /** Mobile Menu */
  const renderMobileMenu = () => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={mobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        <Link to={'/projects'}>
          <MenuButton to={'/projects'} classes={classes}>
            <FormattedMessage id='menu.projects' defaultMessage='Proyectos' />
          </MenuButton>
        </Link>
      </MenuItem>
    </Menu>
  );

  /** Language Selector */
  const renderLanguage = () => (
    <div className={`${classes.menu}__menu__action`}>
      <InputLabel>
        <FormattedMessage id='language' defaultMessage='Language' />:
      </InputLabel>
      <Select
        className={`${classes.menu}__menu__action_select`}
        value={i18nContext.locale}
        onChange={handleChangeLocale}
      >
        <MenuItem value='es'>Español</MenuItem>
        <MenuItem value='en'>English</MenuItem>
      </Select>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position='static' className={classes.root}>
        <Toolbar className={`${classes.root}__toolbar`}>
          {!!currentUser && !!currentUser.client_logo && (
            <img className={classes.client_logo} src={currentUser.client_logo} alt='Logo' />
          )}
          <img src={capLogo} alt='Logo' className={classes.cap_logo} />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {renderMenu()}
            {renderLanguage()}
            <Button color='primary' className={`${classes.menu}__menu__action`} onClick={handleLogout}>
              <FormattedMessage id='logout' defaultMessage='Salir' />
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton aria-haspopup='true' onClick={handleMobileMenuChange} color='primary'>
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {
        //renderMobileMenu()
      }
    </div>
  );
};

export default withI18NContext(withStyles(styles)(Header));
