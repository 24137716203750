import { set } from 'lodash';
import { commonConstants } from '../constants/common-constants';
import { financialProgressConstants } from '../constants/financial-progress-constants';

const financialProgressInitialState = {
  progress: {},
  progresses: [],
  pageSize: 24,
  page: 1,
  total: undefined,
  totalPages: 0,
  loading: false,
  fail: false,
};

export default function financialProgresses(state = financialProgressInitialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case financialProgressConstants.CREATE_FINANCIAL_PROGRESS:
    case financialProgressConstants.GET_FINANCIAL_PROGRESSES:
      set(newState, 'loading', true);
      return newState;

    case financialProgressConstants.CREATE_FINANCIAL_PROGRESS_FAIL:
    case financialProgressConstants.GET_FINANCIAL_PROGRESSES_FAIL:
      set(newState, 'loading', false);
      set(newState, 'fail', true);
      return newState;

    case financialProgressConstants.CREATE_FINANCIAL_PROGRESS_SUCCESS:
      set(newState, 'progress', action.payload.data);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    case financialProgressConstants.GET_FINANCIAL_PROGRESSES_SUCCESS:
      set(newState, 'progresses', action.payload.data.financial_progresses);
      set(newState, 'loading', false);
      set(newState, 'fail', false);
      return newState;

    case commonConstants.RESET:
      return financialProgressInitialState;
    default:
      return state;
  }
}
