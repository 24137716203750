const styles = theme => ({
  card: {
    padding: 10,
    minHeight: 500,
    borderRadius: '10px !important',

    '&_area': {
      height: '100%',
      display: 'flex !important',
      flexFlow: 'row wrap',
      alignContent: 'space-around',
    },

    '&_content': {
      padding: '0px !important',
      width: '100%',
      minHeight: '60%',
      display: 'flex',
      flexFlow: 'column wrap',
      justifyContent: 'space-around',
      fontSize: 12,
    },

    '&-media': {
      minWidth: 220,
      minHeight: 89,
      height: '40%',
      borderRadius: 10,
    },

    '&-subtitle': {
      fontWeight: 'bold',
    },

    '&-title': {
      maxHeight: 42,
      overflow: 'hidden',
    },

    '&-status': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 100,
      minHeight: 20,
      borderRadius: 40,
      color: theme.palette.primary.contrastText,
      fontSize: 12,
      lineHeight: '140%',
    },
  },

  amount: {
    '&__title': {
      fontWeight: 'bold',
    },
    '&-value': {
      fontSize: 14,
    },
  },
});

export default styles;
