import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import styles from './styles';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const ImageCarousel = ({ classes, images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const carouselItemsRef = useRef([]);

  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(0, images.length);

      setSelectedImageIndex(0);
      setSelectedImage(images[0]);
    }
  }, [images]);

  const handleSelectedImageChange = newIdx => {
    if (images && images.length > 0) {
      setSelectedImage(images[newIdx]);
      setSelectedImageIndex(newIdx);
      if (carouselItemsRef?.current[newIdx]) {
        carouselItemsRef?.current[newIdx]?.scrollIntoView({
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  };

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  return (
    <div className={`${classes.carousel}-container`}>
      <div className={`${classes.selected}-image`} style={{ backgroundImage: `url(${selectedImage?.url})` }} />
      <div className={classes.carousel}>
        <div className={`${classes.carousel}__images`}>
          {images &&
            images.map((image, idx) => (
              <div
                onClick={() => handleSelectedImageChange(idx)}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
                key={idx}
                className={`${classes.carousel}__image ${
                  selectedImageIndex === idx && `${classes.carousel}__image-selected`
                }`}
                ref={el => (carouselItemsRef.current[idx] = el)}
              />
            ))}
        </div>
        <IconButton
          size='small'
          className={`${classes.carousel}__button ${classes.carousel}__button-left`}
          onClick={handleLeftClick}
        >
          <ArrowBackIos fontSize='inherit' />
        </IconButton>
        <IconButton
          size='small'
          className={`${classes.carousel}__button ${classes.carousel}__button-right`}
          onClick={handleRightClick}
        >
          <ArrowForwardIos fontSize='inherit' />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(ImageCarousel);
