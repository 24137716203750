import { Avatar, Box, Chip, Grid, TableCell, TableRow, Typography } from '@mui/material';
import { FormattedDate, FormattedMessage, FormattedNumber, IntlProvider } from 'react-intl';
import { esFormatDate } from '../../../utils/format.util';
import TooltipZoom from '../../../components/tooltipZoom';
import ProductProgressBar from './productProgressBar';
import { projectUtil } from '../../../utils/project.utils';
import EditTaskModal from './modals/EditTaskModal';
import { StatusMenu } from './StatusMenu';
import AssignWeightsModal from './modals/AssignWeightsModal';
import ColorLinearProgress from '../../../components/colorLinearProgress';

const StyledDate = ({ dateValue }) => (
  <Typography variant='subtitle1' color='gray.dark'>
    {!!dateValue ? (
      <FormattedDate value={dateValue} year='numeric' month='short' day='2-digit'>
        {parts => esFormatDate(parts, '/')}
      </FormattedDate>
    ) : (
      '-'
    )}
  </Typography>
);

export const ProductRow = ({
  product,
  classes,
  index,
  currency,
  responsible,
  defaultTask,
  projectId,
  type,
  parentLevel,
  level,
  siblings = [],
}) => {
  const {
    name,
    description,
    id,
    planned_to_start_at,
    started_at,
    planned_to_complete_at,
    completed_at,
    budget,
    quantifiable,
    goal,
    goal_unit,
    goal_description,
    parent_id,
    contract_id,
    nested_positions: position,
    status,
    project_task_level_id: projectTaskLevelId,
  } = product;

  const canEditDeliverable = status === 'to_do' || status === 'in_progress';
  const isDeliverable = level.name === 'deliverable';
  const ResponsibleCell = ({ responsible }) => (
    <TableCellBody>
      {!responsible?.first_name ? (
        <TooltipZoom title={responsible.email}>
          <Avatar sx={{ bgcolor: projectUtil.getRandomColorByString(responsible.email) }}>
            {projectUtil.renderAvatarInitials(responsible)}
          </Avatar>
        </TooltipZoom>
      ) : (
        '-'
      )}
    </TableCellBody>
  );

  const TableCellBody = ({ children }) => <TableCell className={`${classes.table}-cell-body`}>{children}</TableCell>;

  const DateCell = ({ dateValue }) => (
    <TableCellBody classes={classes}>
      <StyledDate dateValue={dateValue} />
    </TableCellBody>
  );

  const TooltipCell = ({ description }) => (
    <TableCellBody classes={classes}>
      <TooltipZoom title={description ?? '-'} style={2}>
        <Typography variant='body1' color='gray.dark' className={`${classes.table}_description`}>
          {description ?? '-'}
        </Typography>
      </TooltipZoom>
    </TableCellBody>
  );

  const Budget = ({ currency, current, planned }) => {
    return (
      <Box sx={{ flexDirection: 'column' }} className={`${classes.table}-box`}>
        {
          <Box className={`${classes.table}-box-planned`}>
            <FormattedMessage id='projectInfo.product.current' defaultMessage='Current' />
            {': '}
            {current ? (
              <IntlProvider locale='en'>
                <FormattedNumber value={current} style='currency' currency={currency} />
              </IntlProvider>
            ) : (
              ''
            )}
          </Box>
        }
        {
          <Box className={`${classes.table}-box-planned`}>
            <FormattedMessage id='projectInfo.product.planned' defaultMessage='Planned' />
            {': '}
            {planned ? (
              <IntlProvider locale='en'>
                <FormattedNumber value={planned} style='currency' currency={currency} />
              </IntlProvider>
            ) : (
              ''
            )}
          </Box>
        }
      </Box>
    );
  };

  const advanceChip = (
    <Grid container>
      <Grid item marginLeft='auto'>
        <StatusMenu classes={classes} initialStatus={status} task={product} currency={currency} type={type} />
      </Grid>
    </Grid>
  );

  return (
    <TableRow
      key={`row_${index}_${id}`}
      className={`${classes.table}-row ${isDeliverable && `${classes.table}-row-contract-deliverable-bg`}`}
    >
      <TableCell
        component='td'
        scope='row'
        className={`${classes.table}-cell-body-sticky-left ${
          isDeliverable && `${classes.table}-row-contract-deliverable-bg`
        }`}
        align={!isDeliverable ? 'left' : 'center'}
      >
        <TooltipZoom title={description} style={2}>
          <Typography variant='subtitle1' color='gray.dark' textTransform='capitalize'>
            {`${type} ${position}`}
          </Typography>
        </TooltipZoom>
      </TableCell>
      <TooltipCell description={description} classes={classes} />
      <DateCell dateValue={planned_to_start_at} />
      <DateCell dateValue={started_at} />
      <DateCell dateValue={planned_to_complete_at} />
      <DateCell dateValue={completed_at} />
      <TableCellBody>
        <Budget currency={currency} planned={budget} />
      </TableCellBody>
      <ResponsibleCell responsible={responsible} />
      {isDeliverable && (
        <TableCell align='right'>
          <AssignWeightsModal
            type={type}
            items={siblings}
            label={`${type} ${position}`}
            product={product}
            parentId={projectTaskLevelId}
          />
        </TableCell>
      )}
      <TableCellBody>
        {!isDeliverable ? (
          <ColorLinearProgress variant='determinate' value={product.children_progress} status='to_do' />
        ) : (
          <>{advanceChip}</>
        )}
      </TableCellBody>
      <TableCell
        component='td'
        scope='row'
        className={`${classes.table}-cell-body-sticky-right ${
          isDeliverable && `${classes.table}-row-contract-deliverable-bg`
        }`}
      >
        <Grid container className={`${classes.table}-cell-body-actions`}>
          {isDeliverable && canEditDeliverable && (
            <Grid item width={120}>
              <EditTaskModal
                projectId={projectId}
                currency={currency}
                level={level}
                parentLevel={parentLevel}
                defaultTask={{
                  ...defaultTask,
                  id,
                  name,
                  description,
                  plannedStartDate: planned_to_start_at,
                  plannedCompleteDate: planned_to_complete_at,
                  budget,
                  quantifiable,
                  goal,
                  goalUnit: goal_unit,
                  goalDescription: goal_description,
                  parentId: parent_id,
                  contractId: contract_id,
                }}
              />
            </Grid>
          )}
          {!isDeliverable && (
            <EditTaskModal
              projectId={projectId}
              currency={currency}
              level={level}
              parentLevel={parentLevel}
              defaultTask={{
                ...defaultTask,
                id,
                name,
                description,
                plannedStartDate: planned_to_start_at,
                plannedCompleteDate: planned_to_complete_at,
                budget,
                quantifiable,
                goal,
                goalUnit: goal_unit,
                goalDescription: goal_description,
                parentId: parent_id,
                contractId: contract_id,
              }}
            />
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
};
