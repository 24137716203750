/**
 *
 * Auth saga file
 * Any action made for an projects should go here
 *
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import ContractService from '../../services/contract.services';
import { contractConstants } from '../constants/contract-constants';

/**
 * Workers
 */
export function* setProjectContract(action) {
  const result = yield call(ContractService.setContract, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractConstants.SET_NEW_PROJECT_CONTRACT_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractConstants.SET_NEW_PROJECT_CONTRACT_FAIL,
    });
  }
}

export function* getProjectContracts(action) {
  const result = yield call(ContractService.getContracts, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractConstants.GET_PROJECT_CONTRACT_LIST_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractConstants.GET_PROJECT_CONTRACT_LIST_FAIL,
    });
  }
}

export function* getProjectContract(action) {
  const result = yield call(ContractService.getContract, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractConstants.GET_PROJECT_CONTRACT_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractConstants.GET_PROJECT_CONTRACT_FAIL,
    });
  }
}

export function* updateProjectContract(action) {
  const result = yield call(ContractService.updateContract, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractConstants.UPDATE_PROJECT_CONTRACT_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractConstants.UPDATE_PROJECT_CONTRACT_FAIL,
    });
  }
}

export function* createProjectContract(action) {
  const result = yield call(ContractService.createContract, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: contractConstants.CREATE_PROJECT_CONTRACT_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractConstants.CREATE_PROJECT_CONTRACT_FAIL,
    });
  }
}

export function* updateProductContractsWeights(action) {
  const result = yield call(ContractService.updateContractsWeights, action.payload);
  if (!!result && result.status === 204) {
    yield put({
      type: contractConstants.UPDATE_CONTRACTS_WEIGHTS_SUCCESS,
      payload: { data: result.data },
    });
  } else {
    yield put({
      type: contractConstants.UPDATE_CONTRACTS_WEIGHTS_FAIL,
    });
  }
}

/**
 * Watchers
 */
export function* setContractRequest() {
  yield takeEvery(contractConstants.SET_NEW_PROJECT_CONTRACT, setProjectContract);
}

export function* getContractsRequest() {
  yield takeEvery(contractConstants.GET_PROJECT_CONTRACT_LIST, getProjectContracts);
}

export function* getContractRequest() {
  yield takeEvery(contractConstants.GET_PROJECT_CONTRACT, getProjectContract);
}

export function* updateContractRequest() {
  yield takeEvery(contractConstants.UPDATE_PROJECT_CONTRACT, updateProjectContract);
}

export function* createContractRequest() {
  yield takeEvery(contractConstants.CREATE_PROJECT_CONTRACT, createProjectContract);
}

export function* updateProductContractsWeightsRequest() {
  yield takeEvery(contractConstants.UPDATE_CONTRACTS_WEIGHTS, updateProductContractsWeights);
}

/**
 * Export function
 */
function* contractSaga() {
  // Watchers request
  yield all([
    setContractRequest(),
    getContractsRequest(),
    updateContractRequest(),
    getContractRequest(),
    createContractRequest(),
    updateProductContractsWeightsRequest(),
  ]);
}

export default contractSaga;
