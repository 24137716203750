import { contractorConstants } from '../constants/contractor-constants';

const createContractor = (clientId, contractor) => ({
  type: contractorConstants.CREATE_CONTRACTOR,
  payload: { clientId, ...contractor },
});

const getContractors = clientId => ({
  type: contractorConstants.GET_CONTRACTORS,
  payload: { clientId },
});

export const contractorActions = {
  createContractor,
  getContractors
};
