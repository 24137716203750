import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, ClickAwayListener, LinearProgress } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import ProgressBarPopper from '../progressBarPopper';

const ProgressBar = ({
  contractId,
  progressKey,
  value,
  formattedValue,
  hasPopper,
  color,
  onClickHistory,
  classes,
  onClickAddProgress,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleProgressClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid
        item
        xs
        onClick={handleProgressClick}
        className={`${classes.progress}_label__container ${
          hasPopper ? `${classes.progress}_label__container--openable` : ''
        }`}
      >
        <Grid container justifyContent='space-between' className={`${classes.progress}_label__container`}>
          <Grid item>
            <FormattedMessage id={`labelState.${progressKey}`} defaultMessage='Estado Avance' />{' '}
          </Grid>
          <Grid item>{formattedValue}</Grid>
          {hasPopper && <Grid item>{anchorEl ? <ExpandMore /> : <ExpandLess />}</Grid>}
        </Grid>
        <Grid container>
          <LinearProgress
            value={value}
            variant='determinate'
            className={`${classes.progress}_linear-progress ${classes.progress}_linear-progress_color_${color}`}
          />
        </Grid>
        {hasPopper && anchorEl && contractId && (
          <ProgressBarPopper
            contractId={contractId}
            anchorEl={anchorEl}
            progressKey={progressKey}
            color={color}
            classes={classes}
            onClickHistory={onClickHistory}
            onClickAddProgress={onClickAddProgress}
          />
        )}
      </Grid>
    </ClickAwayListener>
  );
};

ProgressBar.propTypes = {
  contractId: PropTypes.number,
  progressKey: PropTypes.string,
  value: PropTypes.number,
  hasPopper: PropTypes.bool,
  color: PropTypes.string,
  onClickHistory: PropTypes.func,
  onClickAddProgress: PropTypes.func,
  classes: PropTypes.object,
};

export default ProgressBar;
