const styles = theme => ({
  progress: {
    '&_row': {
      '& .MuiTableCell-root': {
        color: theme.palette.gray.dark,
      },

      '& .MuiChip-root': {
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
      },
    
      '&_color': {
        '&_other_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.other.dark
          }
        },
        '&_secondary_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.secondary.dark
          }
        },
        '&_primary_dark': {
          '& .MuiChip-root': {
            backgroundColor: theme.palette.primary.dark
          }
        }
      }
    },
  }
});

export default styles;
