import { contractModificationConstants } from '../constants/contract-modification-constants';

const createContractModification = (contractId, contractModification) => ({
  type: contractModificationConstants.CREATE_CONTRACT_MODIFICATION,
  payload: { contractId, ...contractModification },
});

const getContractModifications = contractId => ({
  type: contractModificationConstants.GET_CONTRACT_MODIFICATIONS,
  payload: { contractId },
});

const getContractModificationJustifications = clientId => ({
  type: contractModificationConstants.GET_CONTRACT_MODIFICATION_JUSTIFICATIONS,
  payload: { clientId },
});

export const contractModificationActions = {
  createContractModification,
  getContractModifications,
  getContractModificationJustifications,
};
