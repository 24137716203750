import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Products from '../products';
import { useEffect } from 'react';
import { taskActions } from '../../../redux/actions/task-actions';
import CreateTaskModal from '../products/modals/CreateTaskModal';

export const TaskLevel = ({ classes, level, parentLevel, project, currentUser }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(state => state.tasks.taskList);
  const task = useSelector(state => state.tasks.task);
  const taskLog = useSelector(state => state.tasks.taskLog);
  const { id: projectTaskLevelId, name: taskLevelName } = level;

  const defaultTask = {
    id: '',
    name: '',
    description: '',
    plannedStartDate: new Date(Date.now()).toISOString(),
    plannedCompleteDate: '',
    budget: '',
    quantifiable: false,
    goal: 0,
    goalUnit: '',
    goalDescription: '',
    responsible: currentUser?.id,
    parentId: null,
    contractId: null,
  };

  useEffect(() => {
    if (projectTaskLevelId) {
      dispatch(taskActions.getProjectTasks(projectTaskLevelId));
    }
  }, [level, task, taskLog]);

  return (
    <>
      <Grid className={`${classes.task}_title`}>
        <FormattedMessage id={`projectInfo.${taskLevelName?.toLowerCase()}s`} defaultMessage={taskLevelName} />
        <CreateTaskModal
          project={project}
          productsList={tasks}
          level={level}
          parentLevel={parentLevel}
          defaultTask={defaultTask}
        />
      </Grid>
      <Products
        project={project}
        products={tasks}
        type={taskLevelName}
        currency={project?.main_contract?.currency}
        responsible={{ email: currentUser?.email }}
        defaultTask={defaultTask}
        parentLevel={parentLevel}
        level={level}
      />
    </>
  );
};
